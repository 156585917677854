import { from } from "@apollo/client";
import React from "react";
import { useState } from "react";
import Loader from "react-js-loader";
import dateFormat, { masks } from "dateformat";

import Header2 from "../include/header2";
import LeftSideBar from "../include/LeftSideBar";
import Lfooter from "../Lfooter";
import {gql,useQuery} from "@apollo/client"
export default function Bets() {

  const [date, setDate] = useState({
    fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
    // fromDate: "2023-01-02",
    toDate: new Date().toISOString(),
    numberOfData: 5,
  })
    const [pageNumber, setPageNumber] = useState(0)
    let pageCountVal = []
  const [betStatus,setBetStatus] = useState("Open")
  const fetchBets = gql`query fetchBets{BetSlipDtls( order_by: {Date: desc},where: {Date: {_gte: "${date.fromDate}", _lte: "${date.toDate}"},Status: {_eq: "${betStatus}"}}, limit: ${date.numberOfData}, offset: ${date.numberOfData*pageNumber}) {Amount Date ID MatchName Odds Outcome Status UserId Team1 Team2}BetSlipDtls_aggregate(order_by: {Date: desc}, where: {Date: {_gte: "${date.fromDate}", _lte: "${date.toDate}"},Status: {_eq: "${betStatus}"}}) {aggregate {count}}}`
  const {error,loading,data} = useQuery(fetchBets)
  console.log(data ? data : error);
  if(loading) return  <img src="assets/img/logo/game.png"  className="loaderimg"/>
  const nextPage = () => {
    setPageNumber(pageNumber + 1)
    // alert(pageNumber)
  }
  const jumpOn = (e) => {
    console.log(e)
    setPageNumber(e)
  }
  const prevPage = () => {
    setPageNumber(pageNumber - 1)
  }
  for (let i = 0; i < data.BetSlipDtls_aggregate.aggregate.count / date.numberOfData; i++) {
    pageCountVal.push(i);
  }
  return (
    <div>
      {/* Header Section start */}
      <Header2 />
      <div className="container">
      <div className="row">
        <div className="col-2">
          <LeftSideBar />
        </div>

        <div className="col-10">
          {/* <br></br> */}
          <div class="body-middle10">
            {/* <!--Breadcumnd--> */}
      
            {/* <!--Breadcumnd--> */}

            {/* <!--profile-->  */}
            <div class="profile-section ">
              <div class="container p-0">
              
                <div class="tab-content " id="pro-wrap">
           
          
               
                 
                  <div class="">
                    <div class="row justify-content-between">
                    
                      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                        <div class="profile-left-wrap ">
                          <div class="form-box form-box-custom">
                            {/* <div class="form-head"></div> */}
                            <form action="#0">
                              <div className="row">
                                <div className="col-md-4">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2 custom-label6"
                                  >
                                    From
                                    <div class="form-grp">
                                      <input
                                        value={date.fromDate}
                                        onChange={(e) => { e.preventDefault(); setDate({ ...date, fromDate: e.target.value }) }}
                                        type="date"
                                      />
                                      <div class="left-icon">
                                      
                                      </div>
                                    </div>
                                  </label>

                                </div>
                                <div className="col-md-4">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2 custom-label6"
                                  >
                                    To
                                    <div class="form-grp">
                                      <input
                                        onChange={(e) => { e.preventDefault(); setDate({ ...date, toDate: e.target.value }) }}
                                        value={date.toDate}
                                        type="date"

                                      />
                                      <div class="left-icon">
                                       
                                      </div>
                                    </div>
                                  </label>

                                </div>
                                <div className="col-md-4" >
                                  <label className="mb-2 custom-label6">
                                    Bet Status
                                    <div class="form-grp" >
                                    <select class="form-control dropdownBtn2  mt-1 pl-1" value={betStatus} onChange={e => {e.preventDefault(); setBetStatus(e.target.value); setPageNumber(0);}} name="state" id="maxRows">
                                        <option>Open</option>
                                        <option>Settled</option>
                                      </select>
                                      <div class="right-lock">
                                       <i class="fa-solid fa-chevron-down"></i>
                                    </div> 
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                        <div class="profile-left-wrap  profile-left-wrap5">
                          <div class="description-table">
                            <table>
                              <tbody>
                                <tr>
                                  <th>S. No.</th>
                                  <th>Date</th>
                           
                                  <th>Amount</th>
                                  <th>OutCome</th>
                                  <th> On Team</th>
                                  <th> Bet Status </th>
                                  <th>Bets</th>
                                  {/* <th>Status</th> */}
                                 
                            
                                </tr>
                               
                                 
                                  
                               

                                {data.BetSlipDtls.length!=0 ?
                                data?.BetSlipDtls.map((bet,index)=>(
                                <tr class="tb2">
                                <td>
                                    <span class="text1">{index+1}</span>
                                  </td>
                                  <td>
                                    <span>{dateFormat(bet.Date," dd-mm-yyyy, h:MM TT")}</span>
                                  </td>
                                  <td class="text1"><span> {bet.Amount} </span> </td>
                                  <td>
                                    <span className="text1" > {bet.Outcome ? bet.Outcome : ""} </span>
                                  </td>
                                  <td>
                                    <span className="text1" > {bet.Team1=="undefined" ? "" :  bet.Team1} </span>
                                  </td>
                                  <td>
                                    <span className="text1" > {bet.Status ? bet.Status : ""} </span>
                                  </td>
                                  <td>
                                    <span class="text1"> {bet.MatchName} </span>
                                  </td>
                               
                                  {/* <td>
                                    <span class="text1"> {bet.Status===1? "Open" : "Closed"} </span>
                                  </td> */}
                                  
                                </tr>
                                ))
                                : <>
                                <tr> <td>
                                  <span class="text1"></span>
                                </td>
                                <td>
                                  <span class="text1"></span>
                                </td>
                                <td>
                                  <span class="text1"></span>
                                </td>
                                <td>
                                  <span class="text1"> No Records Found</span>
                                </td>
                                <td>
                                  <span class="text1"></span>
                                </td>
                                <td>
                                  <span class="text1"></span>
                                </td>
                                 <td>
                                  <span class="text1"></span>
                                </td></tr>
                             </>






}
                              </tbody>
                            </table>
                       
                          </div>
                          <div className="row mb-5">
                              <div className="col-md-4" >
                                <label
                                  for="exampleInputEmail1"
                                  className="mb-2 mt-3"
                                >Content Per Page

                                </label>
                                <select class="form-control dropdownBtn" value={date.numberOfData} onChange={e => {e.preventDefault(); setDate({ ...date, numberOfData: e.target.value}); }} name="state" id="maxRows">
                                  <option>5</option>
                                  <option>10</option>
                                  <option>15</option>
                                  <option>20</option>
                                  <option>50</option>
                                  <option>70</option>
                                  <option>100</option>
                                </select>
                              </div>
                              <div class='pagination-container col-md-8' >


                                <nav>
                                  <ul class="pagination">

                                    <li data-page="prev" className="btnSpacing">
                                      <button class="paginationBtn" disabled={pageNumber <= 0} onClick={e => { e.preventDefault(); prevPage(); }} ><span> Prev </span></button>
                                    </li>
                                    <li data-page="prev" className="btnSpacing">
                                      {pageCountVal.map((ele) => (
                                        <button 
                                        class={`paginationBtn ${pageNumber===ele ? "pagination-active" : ""}`}

                                         key={ele} value={ele + 1} onClick={(e) => { e.preventDefault(); jumpOn(ele); }} ><span> {ele + 1} </span></button>
                                      ))}
                                    </li>
                                    <li data-page="next" className="btnSpacing" id="prev">
                                      <button class="paginationBtn" disabled={pageNumber >= pageCountVal.length - 1} onClick={e => { e.preventDefault(); nextPage(); }} ><span> Next</span></button>
                                    </li>
                                  </ul>
                                </nav>
                              </div>

                            </div>
                        </div>
                      </div>
                    </div>
                  </div>

                
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
       
    </div>
  );
}
