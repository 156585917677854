import React from "react";
import { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Header from "../include/header";
import LeftSideBar from "../include/LeftSideBar";
import Header2 from "../include/header2";
import Lfooter from "../Lfooter";
import jwt_decode from "jwt-decode";
import utr from "../Assets/Img/UTR.png"
import Loader from "react-js-loader";
import SweetAlert2 from "./Sweet-Alert";
export default function Deposite_now() {
  const [bankAccordian, setBankAccordian] = useState("show");
  const [upiAccordian, setUpiAccordian] = useState("");
  const [alerts, setAlerts] = useState("");
  const [color, setColor] = useState("red");
  var accessToken = jwt_decode(localStorage.getItem("accessToken"));
  var decoded = jwt_decode(localStorage.getItem("accessToken"));
  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const fireAlert = () => {
    Swal.fire({
      title: alerts,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      icon: "warning",
    });
  };

  const navigate = useNavigate();
  const [loginCredentials, setLoginCredentials] = useState({
    user: "Sayan1",
    pwd: "12345",
  });
  const [updateRadioBank,setUpdateRadioBank] = useState("selecotr-item")
  const [bank, setBank] = useState("bankshow active");
  const [bankbtn, setBankbtn] = useState("btnact");
  const [upibtn, setUpibtn] = useState("btnact1");
  const [upi, setUpi] = useState("d-none");
  const [updateRadioUPI,setUpdateRadioUPI] = useState("selecotr-item-inactive")
  const [bankright, setBankright] = useState("bankshow1 active1");
  const [bankbtnright, setBankbtnright] = useState("btnact");
  const [upibtnright, setUpibtnright] = useState("btnact1right");
  const [upiright, setUpiright] = useState("d-none");
  const [checkbox, setCheckbox] = useState(false);
  const [depositeUPI, setDepositeUPI] = useState({
    Amount: 0,
    ProviderName: "",
    UPIId: "",
    TransactionId: "",
    UserName: "",
    radio: "",
  });
  const [depositeBank, setDepositeBank] = useState({
    IfscCode: "",
    AcntNum: "",
    UTRNum: "",
    BankName: "",
    Amount: 0,
    UserName: "",
    radio: "",
  });

  const fetchAdminAcnts = gql`
    {
      AdminUPIAcnts(where: { Status: { _eq: 1 } }) {
        Date
        ID
        ProviderName
        UPIId
        Status
      }
      AdminBankAcnts(where: { Status: { _eq: 1 } }) {
        AcntNum
        BankName
        Date
        IFSCCode
        ID
        Status
      }
    }
  `;
  let { data, error, loading } = useQuery(fetchAdminAcnts);
  // let {
  //   loading: autofillLoading,
  //   error: autofillError,
  //   data: autofillData,
  // } = useQuery(autofillQuery);
  // console.log(autofillData ? autofillData : autofillError);
  // useEffect(()=>{

  // },[checkbox])
  console.log(data ? data : error);
  if (loading)
    return (
     <img src="assets/img/logo/game.png"  className="loaderimg"/>
    );
  const handleChange = (event) => {
    if (event.target.value === "upi") {
      setUpi("d-block");
      setBank("d-none");
      setUpibtn("btnact");
      setBankbtn("btnact1");
      setUpiAccordian("show");
      setBankAccordian("d-none");
      setUpdateRadioBank("selecotr-item-inactive");
      setUpdateRadioUPI("selecotr-item");
    } else {
      setUpi("d-none");
      setBank("d-block");
      setBankbtn("btnact");
      setUpibtn("btnact1");
      setUpiAccordian("d-none");
      setBankAccordian("show");
      setUpdateRadioBank("selecotr-item");
      setUpdateRadioUPI("selecotr-item-inactive")
    }
  };
  const handleBankCheck = (e) => {
    setDepositeBank({ ...depositeBank, BankName: e.BankName });
  };
  const HandleDeposit = async (e) => {
    try {
      e.preventDefault();
      console.log(depositeBank);

      if (depositeBank.radio === "bank") {
        const responseBank = await axios.post(
          `${process.env.REACT_APP_BaseUrl}/deposite`,
          depositeBank,
          {
            mode: "cors",
            headers: headers,
          }
        );
        if (responseBank.status === 200) {
          Swal.fire({
            title: "Your deposit will be added shortly in your wallet.  ",
            confirmButtonText: "OK",
            icon: "success",
          }).then(function (isConfirm) {
            if (isConfirm) {
              window.location.reload(true);
              // navigate("/#/Transaction");
            }
          });
          // alert("Your deposit will be added shortly in your wallet.");

          // setInterval(() => {
          //   setAlerts("hiii")
          //   fireAlert();
          // }, 5000);

          // fireAlert();

          //
        } else {
          Swal.fire({
            title: "Duplicate entry",
            confirmButtonText: "OK",
            icon: "success",
          });
          // alert(responseBank);
        }
      } else if (depositeUPI.radio === "upi") {
        const responseUPI = await axios.post(
          `${process.env.REACT_APP_BaseUrl}/deposite`,
          depositeUPI,
          {
            mode: "cors",
            headers: headers,
          }
        );
        if (responseUPI.status === 200) {
          Swal.fire({
            title: "Your deposit will be added shortly in your wallet.  ",
            confirmButtonText: "OK",
            icon: "success",
          }).then(function (isConfirm) {
            if (isConfirm) {
              window.location.reload(true);

              // navigate("/#/Transaction");
            }
          });
        } else {
          Swal.fire({
            title: responseUPI.data,
            confirmButtonText: "OK",
            icon: "error",
          }).then(function (isConfirm) {
            if (isConfirm) {
              window.location.reload(true);
              // navigate("/#/Transaction");
            }
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {/* Header Section start */}
      {/* <SweetAlert2 name ="Your deposit will be added shortly in your wallet."/> */}
      <Header2 />
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-2">
            <LeftSideBar />
          </div>

          <div className="col-md-10 col-12">
            <br></br>
            <div class="body-middle">
              {/* <!--Breadcumnd--> */}

              {/* <!--Breadcumnd--> */}

              {/* <!--profile-->  */}
              <div class="profile-section pb-60">
                <div class="container p-0">
                  <div class="tab-content pt-20" id="pro-wrap">
                    <div class="">
                      <div class="row">
                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                          <div class="container">
                            <div class="selector">
                              <div class={updateRadioBank}>
                                <input
                                  type="radio"
                                  checked={bank === "bank"}
                                  value="bank"
                                  id="radio1"
                                  name="selector"
                                  onClick={handleChange}
                                />
                                <label
                                  for="radio1"
                                  class={`selector-item_label ${bankbtn}`}
                                >
                                  {" "}
                                  <i
                                    className="fa fa-bank"
                                    style={{
                                      "font-size": "16px",
                                      "padding-right": "10px",
                                    }}
                                  ></i>{" "}
                                  Banks
                                  {/* Bank */}
                                </label>
                              </div>
                              <div class={updateRadioUPI}>
                                <input
                                  checked={bank === "upi"}
                                  type="radio"
                                  id="radio2"
                                  value="upi"
                                  onClick={handleChange}
                                />

                                <label
                                  for="radio2"
                                  class={`selector-item_label ${upibtn}`}
                                >
                                  <i
                                    className="fa fa-bank"
                                    style={{
                                      "font-size": "16px",
                                      "padding-right": "10px",
                                    }}
                                  ></i>{" "}
                                  UPI ID
                                </label>
                              </div>
                            </div>
                          </div>
                          <br></br>
                          <div className={`profile-left-wrap ${bank}`}>
                            <div class="form-box form-box-custom">
                              <div class="form-head"></div>
                              <form action="#0" onSubmit={HandleDeposit}>
                                <div className="row">
                                  <div className="col-md-6 col-12 mb-3">
                                    <label
                                      for="exampleInputEmail1"
                                      className="mb-2"
                                    >
                                      Payee Bank Name
                                    </label>
                                    <div class="form-grp">
                                      <input
                                        type="text"
                                        required
                                        placeholder=""
                                        value={
                                          depositeBank.BankName != ""
                                            ? depositeBank.BankName
                                            : ""
                                        }
                                        onChange={(e) => {
                                          e.preventDefault();
                                          setDepositeBank({
                                            ...depositeBank,
                                            BankName: e.target.value,

                                          });
                                        }}
                                      />

                                      <div class="left-icon">
                                        <i
                                          className="fa fa-bank"
                                          style={{
                                            "font-size": "16px",
                                            "padding-right": "10px",
                                          }}
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-12 mb-3">
                                    <label
                                      for="exampleInputEmail1"
                                      className="mb-2"
                                    >
                                      Payee Account Number
                                    </label>
                                    <div class="form-grp">
                                      <input
                                        type="text"
                                        required
                                        placeholder=""
                                        value={
                                          depositeBank.AcntNum != ""
                                            ? depositeBank.AcntNum
                                            : ""
                                        }
                                        onChange={(e) => {
                                          e.preventDefault();
                                          setDepositeBank({
                                            ...depositeBank,
                                            AcntNum: e.target.value,
                                          });
                                        }}
                                      />

                                      <div class="left-icon">
                                        <i
                                          className="fa fa-bank"
                                          style={{
                                            "font-size": "16px",
                                            "padding-right": "10px",
                                          }}
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-12 mb-3">
                                    <label
                                      for="exampleInputEmail1"
                                      className="mb-2"
                                    >
                                      Payee Bank IFSC Code
                                    </label>
                                    <div class="form-grp">
                                      <input
                                        type="text"
                                        required
                                        placeholder=""
                                        value={
                                          depositeBank.IfscCode != ""
                                            ? depositeBank.IfscCode
                                            : ""
                                        }
                                        onChange={(e) => {
                                          e.preventDefault();
                                          setDepositeBank({
                                            ...depositeBank,
                                            IfscCode: e.target.value,
                                          });
                                        }}
                                      />

                                      <div class="left-icon">
                                        <i
                                          className="fa fa-bank"
                                          style={{
                                            "font-size": "16px",
                                            "padding-right": "10px",
                                          }}
                                        ></i>
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div className="col-md-12 mb-3">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2"
                                  >
                                    My Name
                                  </label>
                                  <div class="form-grp">
                                    <input
                                      type="text"
                                      placeholder=""
                                      value={depositeBank.UserName!=""? depositeBank.UserName: "" }
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setDepositeBank({
                                          ...depositeBank,
                                          UserName: e.target.value,
                                        });
                                      }}
                                    />

                                    <div class="left-icon">
                                      <i
                                        class="fas fa-user"
                                        style={{
                                          "font-size": "16px",
                                          marginRight: "10px",
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                </div> */}
                                <div className="col-md-6 col-12 mb-3">
                                    <label
                                      for="exampleInputEmail1"
                                      className="mb-2"
                                    >
                                     UTR*
                                    </label>
                                    <div class="form-grp">
                                      <input
                                        type="text"
                                        required
                                        maxLength={12}
                                        onChange={(e) => {
                                          e.preventDefault();
                                          setDepositeBank({
                                            ...depositeBank,
                                            UTRNum: e.target.value,
                                          });
                                        }}
                                        value={depositeBank.UTRNum}
                                        placeholder="12 Digit UTR Number"
                                      />
                                      <div class="left-icon">
                                        <img src={utr} className="utrSize"
                                         />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 col-12 mb-3">
                                    <label
                                      for="exampleInputEmail1"
                                      className="mb-2"
                                    >
                                      Amount
                                    </label>
                                    <div class="form-grp">
                                      <input
                                        type="number"
                                        required
                                        value={
                                          depositeBank.Amount === 0
                                            ? ""
                                            : depositeBank.Amount
                                        }
                                        placeholder=""
                                        onChange={(e) => {
                                          e.preventDefault();
                                          setDepositeBank({
                                            ...depositeBank,
                                            Amount: Number(e.target.value) ,
                                            radio: "bank",
                                            UserName: accessToken.name,
                                          });
                                        }}
                                      />

                                      <div class="left-icon">
                                        <i
                                          class="fa fa-inr"
                                          style={{
                                            "font-size": "16px",
                                            marginRight: "10px",
                                          }}
                                        ></i>
                                      </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-md-4 my-2">
                                        <div class="form-grp form-grp1">
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" class="btn btn-secondary" onClick={(e) => {
                                              e.preventDefault();
                                              setDepositeBank({
                                                ...depositeBank,
                                                Amount: depositeBank.Amount + Number(e.target.value),
                                              });
                                            }} value={200} >+</button>
                                        <button type="button" class="btn btn-secondary" onClick={(e) => {
                                              e.preventDefault();
                                              setDepositeBank({
                                                ...depositeBank,
                                                Amount: depositeBank.Amount + Number(e.target.value),
                                              });
                                            }} value={200} >200</button>
                                        <button type="button" class="btn btn-secondary" onClick={(e) => {
                                              e.preventDefault();
                                              setDepositeBank({
                                                ...depositeBank,
                                                Amount: depositeBank.Amount===0 || depositeBank.Amount<0 ? 0 : depositeBank.Amount - Number(e.target.value),
                                              });
                                            }} value={200}>-</button>
                                      </div>


                                          
                                       
                                        </div>
                                      </div>
                                      <div className="col-md-4 my-2">
                                        <div class="form-grp form-grp1">
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" class="btn btn-secondary" onClick={(e) => {
                                              e.preventDefault();
                                              setDepositeBank({
                                                ...depositeBank,
                                                Amount: depositeBank.Amount + Number(e.target.value),
                                              });
                                            }} value={1000} >+</button>
                                        <button type="button" class="btn btn-secondary" onClick={(e) => {
                                              e.preventDefault();
                                              setDepositeBank({
                                                ...depositeBank,
                                                Amount: depositeBank.Amount + Number(e.target.value),
                                              });
                                            }} value={1000} >1000</button>
                                        <button type="button" class="btn btn-secondary" onClick={(e) => {
                                              e.preventDefault();
                                              setDepositeBank({
                                                ...depositeBank,
                                                Amount: depositeBank.Amount===0 || depositeBank.Amount<0 ? 0 : depositeBank.Amount - Number(e.target.value),
                                              });
                                            }} value={1000}>-</button>
                                      </div>


                                          
                                       
                                        </div>
                                      </div>



                                      <div className="col-md-4 my-2">
                                        <div class="form-grp form-grp1">
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" class="btn btn-secondary" onClick={(e) => {
                                              e.preventDefault();
                                              setDepositeBank({
                                                ...depositeBank,
                                                Amount: depositeBank.Amount + Number(e.target.value),
                                              });
                                            }} value={2000} >+</button>
                                        <button type="button" class="btn btn-secondary" onClick={(e) => {
                                              e.preventDefault();
                                              setDepositeBank({
                                                ...depositeBank,
                                                Amount: depositeBank.Amount + Number(e.target.value),
                                              });
                                            }} value={2000} >2000</button>
                                        <button type="button" class="btn btn-secondary" onClick={(e) => {
                                              e.preventDefault();
                                              setDepositeBank({
                                                ...depositeBank,
                                                Amount: depositeBank.Amount===0 || depositeBank.Amount<0 ? 0 : depositeBank.Amount - Number(e.target.value),
                                              });
                                            }} value={2000}>-</button>
                                      </div>


                                          
                                       
                                        </div>
                                      </div>
                                      <div className="col-md-4 my-2">
                                        <div class="form-grp form-grp1">
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" class="btn btn-secondary" onClick={(e) => {
                                              e.preventDefault();
                                              setDepositeBank({
                                                ...depositeBank,
                                                Amount: depositeBank.Amount + Number(e.target.value),
                                              });
                                            }} value={5000} >+</button>
                                        <button type="button" class="btn btn-secondary" onClick={(e) => {
                                              e.preventDefault();
                                              setDepositeBank({
                                                ...depositeBank,
                                                Amount: depositeBank.Amount + Number(e.target.value),
                                              });
                                            }} value={5000} >5000</button>
                                        <button type="button" class="btn btn-secondary" onClick={(e) => {
                                              e.preventDefault();
                                              setDepositeBank({
                                                ...depositeBank,
                                                Amount: depositeBank.Amount===0 || depositeBank.Amount<0 ? 0 : depositeBank.Amount - Number(e.target.value),
                                              });
                                            }} value={5000}>-</button>
                                      </div>


                                          
                                       
                                        </div>
                                      </div>
                                      <div className="col-md-4 my-2">
                                        <div class="form-grp form-grp1">
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" class="btn btn-secondary" onClick={(e) => {
                                              e.preventDefault();
                                              setDepositeBank({
                                                ...depositeBank,
                                                Amount: depositeBank.Amount + Number(e.target.value),
                                              });
                                            }} value={10000} >+</button>
                                        <button type="button" class="btn btn-secondary" onClick={(e) => {
                                              e.preventDefault();
                                              setDepositeBank({
                                                ...depositeBank,
                                                Amount: depositeBank.Amount + Number(e.target.value),
                                              });
                                            }} value={10000} >10000</button>
                                        <button type="button" class="btn btn-secondary" onClick={(e) => {
                                              e.preventDefault();
                                              setDepositeBank({
                                                ...depositeBank,
                                                Amount: depositeBank.Amount===0 || depositeBank.Amount<0 ? 0 : depositeBank.Amount - Number(e.target.value),
                                              });
                                            }} value={10000}>-</button>
                                      </div>


                                          
                                       
                                        </div>
                                      </div>
                                      <div className="col-md-4 my-2">
                                        <div class="form-grp form-grp1">
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" class="btn btn-secondary" onClick={(e) => {
                                              e.preventDefault();
                                              setDepositeBank({
                                                ...depositeBank,
                                                Amount: depositeBank.Amount + Number(e.target.value),
                                              });
                                            }} value={20000} >+</button>
                                        <button type="button" class="btn btn-secondary" onClick={(e) => {
                                              e.preventDefault();
                                              setDepositeBank({
                                                ...depositeBank,
                                                Amount: depositeBank.Amount + Number(e.target.value),
                                              });
                                            }} value={20000} >20000</button>
                                        <button type="button" class="btn btn-secondary" onClick={(e) => {
                                              e.preventDefault();
                                              setDepositeBank({
                                                ...depositeBank,
                                                Amount: depositeBank.Amount===0 || depositeBank.Amount<0 ? 0 : depositeBank.Amount - Number(e.target.value),
                                              });
                                            }} value={20000}>-</button>
                                      </div>


                                          
                                       
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  

                                  {/* <div class="form-check form-check-custom  mt-4">
                                  <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                  <label class="form-check-label" for="exampleCheck1">I have read and agree with <a href="#"> the terms of payment and withdrawal policy.</a></label>
                                </div> */}

                                  <div class="form-grp form-grp1 mt-4">
                                    <button type="submit" class="cmn--btn">
                                      <span>Proceed</span>
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div class={`profile-left-wrap ${upi}`}>
                            <div class="form-box form-box-custom">
                              <div class="form-head"></div>
                              <form action="#0" onSubmit={HandleDeposit}>
                                <div className="row">
                                  <div className="col-md-6 mb-3">
                                    {/* <input type="hidden"
                                    onChange={(e) => setDepositeUPI({ ...depositeUPI, radio: e.target.value })}
                                    value={depositeUPI.radio} /> */}
                                    <label
                                      for="exampleInputEmail1"
                                      className="mb-2"
                                    >
                                      Payee Provider Name
                                    </label>
                                    <div class="form-grp">
                                      <input
                                        type="text"
                                        required
                                        placeholder="  Google Pay"
                                        onChange={(e) => {
                                          e.preventDefault();
                                          setDepositeUPI({
                                            ...depositeUPI,
                                            ProviderName: e.target.value,
                                          });
                                        }}
                                        value={depositeUPI.ProviderName}
                                      />
                                      <div class="left-icon">
                                        <i
                                          className="fa-brands fa-google-pay"
                                          style={{
                                            "font-size": "16px",
                                            marginRight: "10px",
                                          }}
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="col-md-6">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2"
                                  >
                                    My Name
                                  </label>
                                  <div class="form-grp">
                                    <input
                                      type="text"
                                      placeholder=" Jhon Doe"
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setDepositeUPI({
                                          ...depositeUPI,
                                          UserName: e.target.value,
                                        });
                                      }}
                                      value={depositeUPI.UserName}
                                    />
                                    <div class="left-icon">
                                      <i
                                        class="fas fa-user"
                                        style={{
                                          "font-size": "16px",
                                          marginRight: "10px",
                                        }}
                                      ></i>
                                    </div>
                                    {/* <div class="right-lock">
                                       <i class="fa-solid fa-lock"></i>
                                    </div> 
                                  </div>
                                </div> */}
                                  <div className="col-md-6 mb-3">
                                    <label
                                      for="exampleInputEmail1"
                                      className="mb-2"
                                    >
                                      Payee UPI ID
                                    </label>
                                    <div class="form-grp">
                                      <input
                                        type="text"
                                        required
                                        onChange={(e) => {
                                          e.preventDefault();
                                          setDepositeUPI({
                                            ...depositeUPI,
                                            UPIId: e.target.value,
                                          });
                                        }}
                                        value={depositeUPI.UPIId}
                                        placeholder="12345678"
                                      />
                                      <div class="left-icon">
                                        <i
                                          class="fa solid fa-book"
                                          style={{
                                            marginRight: "10px",
                                            "font-size": "16px",
                                          }}
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 ">
                                    <label
                                      for="exampleInputEmail1"
                                      className="mb-2"
                                    >
                                      Amount
                                    </label>
                                    <div class="form-grp">
                                      <input
                                        type="number"
                                        required
                                        onChange={(e) => {
                                          e.preventDefault();
                                          setDepositeUPI({
                                            ...depositeUPI,
                                            Amount:Number(e.target.value),
                                            radio: "upi",
                                            UserName: accessToken.name,

                                          });
                                        }}
                                        value={depositeUPI.Amount===0 ? "" : depositeUPI.Amount}
                                        placeholder="10,000"
                                      />
                                      <div class="left-icon">
                                        <i class="fa fa-inr"></i>
                                      </div>
                                      {/* <div class="right-lock">
                                       <i class="fa-solid fa-lock"></i>
                                    </div> */}
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <label
                                      for="exampleInputEmail1"
                                      className="mb-2"
                                    >
                                      Transaction ID
                                    </label>
                                    <div class="form-grp ">
                                      <input
                                        type="text"
                                        required
                                        placeholder="45Dt56GH"
                                        onChange={(e) => {
                                          e.preventDefault();
                                          setDepositeUPI({
                                            ...depositeUPI,
                                            TransactionId: e.target.value,
                                          });
                                        }}
                                        value={depositeUPI.TransactionId}
                                      />
                                      <div class="left-icon">
                                        <i class="fa solid fa-book"></i>
                                      </div>
                                      {/* <div class="right-lock">
                                       <i class="fa-solid fa-lock"></i>
                                    </div> */}
                                      {/* <a href="#0" class="repeat">
                                       <i class="fas fa-repeat"></i>
                                    </a> */}
                                    </div>
                                  </div>

                                  <div class="form-grp mt-4">
                                    <button type="submit" class="cmn--btn">
                                      <span>Submit</span>
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                            {/* <div class="change-picture-box">
                              <div class="head">
                                 <span>Change profile picture</span>
                              </div>
                              <div class="change-box">
                                 <div class="down">
                                    <img src="assets/img/table/details/picture.png" alt="img"/>
                                 </div>
                                 <a href="#0">
                                    Choose file to upload
                                 </a>
                                 <p>Supported Files: JPG, JPEG, PNG, BMP PDF, TIE TIFF with a max size of 5 ME</p>
                              </div>
                           </div> */}
                          </div>
                        </div>

                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                          <div class="profile-left-wrap profile-left-wrap-custom">
                            {/* <div class="accounts-box accounts-box-custom1 "> */}
                            {/* <div class="form-grp">
                              <button
                                type="submit"
                                class="cmn--btn"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalCustom"
                              >
                                <span>
                                  <i class="fa fa-plus" aria-hidden="true"></i>{" "}
                                  Add Account
                                </span>
                              </button>
                            </div> */}

                            {/* <div class="form-grp">
                              <div class="selector">
                                <div class="selecotr-item">
                                  <input
                                    type="radio"
                                    id="radio1"
                                    name="selector"
                                    class="selector-item_radio"
                                    checked
                                  />
                                  <label
                                    for="radio1"
                                    class="selector-item_label"
                                  >
                                    Bank
                                  </label>
                                </div>
                                <div class="selecotr-item">
                                  <input
                                    type="radio"
                                    id="radio2"
                                    name="selector"
                                    class="selector-item_radio"
                                  />
                                  <label
                                    for="radio2"
                                    class="selector-item_label"
                                  >
                                    UPI Id
                                  </label>
                                </div>
                                <button
                                  type="submit"
                                  class="cmn--btn"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModalCustom"
                                 >
                                  <span>
                                    <i
                                      class="fa fa-plus"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Add Account
                                  </span>
                                </button>
                              </div>
                            </div> */}
                            {/* </div> */}

                            <div class="">
                              {/* <div className='row'>
                              <div className='col-md-6 '>
                              <input type="radio" id="male" name="gender" className='col-custom-radio'/>
                              <label for="male">Male</label>
                              </div>

                              <div className='col-md-6 '>
                              <input type="radio" id="female" name="gender" className='col-custom-radio'/>
                              <label for="female">Female</label>
                              </div>
                              </div> */}
                              {/* <table class="table table-responsive ">
                              <thead className="text-white text-center">
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Bank Name</th>
                                  <th scope="col">Account No.</th>
                                  <th scope="col">IDFC Code</th>
                                  <th scope="col">Branch Name</th>
                                  <th scope="col">Account Name</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody className="text-white text-center">
                                <tr>
                                  <td>1</td>
                                  <td>HDFC</td>
                                  <td>222222334</td>
                                  <td>HD00867</td>
                                  <td>Vijay Nager</td>
                                  <td>Vijay</td>
                                  <td>
                                    {" "}
                                    <div class="form-grp">
                                      <button type="submit" class="cmn--btn">
                                        <span>
                                          <i
                                            class="fa fa-trash"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>2</td>
                                  <td>ICICI</td>
                                  <td>3233231</td>
                                  <td>HD00867</td>
                                  <td>Vijay Nager</td>
                                  <td>Vijay </td>
                                  <td>
                                    <div class="form-grp">
                                      <button type="submit" class="cmn--btn">
                                        <span>
                                          <i
                                            class="fa fa-trash"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>3</td>
                                  <td>SBI</td>
                                  <td>21115564</td>
                                  <td>HD00867</td>
                                  <td>Vijay Nager</td>
                                  <td>Vijay </td>
                                  <td>
                                    <div class="form-grp">
                                      <button type="submit" class="cmn--btn">
                                        <span>
                                          <i
                                            class="fa fa-trash"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table> */}

                              <div class="countries-tab">
                                <div class="accordion" id="countries">
                                  <div class="accordion-item accordion-item55">
                                    <div class="match-fixing">
                                      <div
                                        class="accordion-header"
                                        id="countriestab1"
                                      >
                                        {" "}
                                        {/* UPI accordian Section starts */}
                                        <div className={upi}>
                                          <button
                                            style={{ marginTop: "17px" }}
                                            class="accordion-button collapsed accordian77"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#upiID1"
                                            aria-expanded="false"
                                            aria-controls="upiID1"
                                          >
                                            {" "}
                                            <span>
                                              <h6>
                                                {" "}
                                                <i
                                                  className="fa fa-bank"
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                ></i>{" "}
                                                Upi
                                              </h6>
                                            </span>
                                          </button>
                                        </div>
                                        <div className={bank}>
                                          <button
                                            style={{ marginTop: "17px" }}
                                            class="accordion-button collapsed accordian77"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#bankID1"
                                            aria-expanded="false"
                                            aria-controls="bankID1"
                                          >
                                            {" "}
                                            <span>
                                              <h6>
                                                {" "}
                                                <i
                                                  className="fa fa-bank"
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                ></i>{" "}
                                                Bank
                                              </h6>
                                            </span>
                                          </button>
                                        </div>
                                        <div
                                          id="upiID1"
                                          class={`accordion-collapse collapse collapse-custom ${upiAccordian}`}
                                          // data-bs-parent="#upiID1"
                                        >
                                          <table class="table scoretable ">
                                            <thead>
                                              <tr>
                                                <th scope="col" className="col-th-name">
                                                   Provider Name
                                                </th>
                                                <th scope="col" className="col-th-name">
                                                   UPI Address
                                                </th>
                                                <th className="col-th-name">Action</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {data?.AdminUPIAcnts.map(
                                                (acnt, index) => (
                                                  <tr key={index}>
                                                    <td>
                                                      {" "}
                                                      {acnt.ProviderName}{" "}
                                                    </td>
                                                    <td> {acnt.UPIId} </td>
                                                    <td>
                                                      <CheckboxUPI
                                                        key={`checkUpi${index}`}
                                                        depositeUPI={
                                                          depositeUPI
                                                        }
                                                        setDepositeUPI={
                                                          setDepositeUPI
                                                        }
                                                        ProviderName={
                                                          acnt.ProviderName
                                                        }
                                                        UPIId={acnt.UPIId}
                                                      />
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                        {/* UPI accordian Section ends */}
                                        {/* Bank accordian Section starts */}
                                        <div
                                          id="bankID1"
                                          class={`accordion-collapse collapse ${bankAccordian}`}
                                          // data-bs-parent="#bankID1"
                                        >
                                          {" "}
                                          <div class="accordion-body">
                                            <table class="table scoretable">
                                              <thead>
                                                <tr>
                                                  <th scope="col" className="col-th-name">
                                                    Bank Name{" "}
                                                  </th>
                                                  <th scope="col" className="col-th-name">
                                                    Account No.
                                                  </th>
                                                  <th scope="col" className="col-th-name">
                                                    IFSC Code
                                                  </th>

                                                  <th scope="col" className="col-th-name">
                                                    Action
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {data?.AdminBankAcnts.map(
                                                  (acnt, index) => (
                                                    <tr key={index}>
                                                      <td> {acnt.BankName} </td>
                                                      <td> {acnt.AcntNum} </td>
                                                      <td> {acnt.IFSCCode} </td>
                                                      <td>
                                                        {" "}
                                                        {/* <input
                                                        key={`check${index}`}
                                                        onChange={(e) => {
                                                          // e.preventDefault();
                                                          setCheckbox(
                                                            e.target.checked
                                                          );
                                                          setDepositeBank({
                                                            ...depositeBank,
                                                            BankName:
                                                              acnt.BankName,
                                                            AcntNum:
                                                              acnt.AcntNum,
                                                            IfscCode:
                                                              acnt.IFSCCode,
                                                          });
                                                          console.log(e.target.checked);
                                                        }}
                                                        class="form-check-input"
                                                        checked={checkbox}
                                                        id="flexCheckDefault22"
                                                      />{" "}
                                                      <p>{checkbox ? "checked" : "unchecked"}</p> */}
                                                        <Checkbox
                                                          key={`check${index}`}
                                                          depositeBank={
                                                            depositeBank
                                                          }
                                                          BankName={
                                                            acnt.BankName
                                                          }
                                                          IfscCode={
                                                            acnt.IFSCCode
                                                          }
                                                          Acntnum={acnt.AcntNum}
                                                          setDepositeBank={
                                                            setDepositeBank
                                                          }
                                                        />
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                        {/* Bank accordian Section ends */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       
    </div>
  );
}

export const Checkbox = ({
  depositeBank,
  BankName,
  IfscCode,
  Acntnum,
  setDepositeBank,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <div>
      <input
        type="checkbox"
        id="checkbox"
        checked={isChecked}
        onChange={(e) => {
          setIsChecked(e.target.checked);
          setDepositeBank({
            ...depositeBank,
            BankName: BankName,
            AcntNum: Acntnum,
            IfscCode: IfscCode,
          });
        }}
      />
    </div>
  );
};
export const CheckboxUPI = ({
  depositeUPI,
  setDepositeUPI,
  ProviderName,
  UPIId,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <div>
      <input
        type="checkbox"
        id="checkbox"
        checked={isChecked}
        onChange={(e) => {
          setIsChecked(e.target.checked);
          setDepositeUPI({
            ...depositeUPI,
            ProviderName: ProviderName,
            UPIId: UPIId,
          });
        }}
      />
    </div>
  );
};
