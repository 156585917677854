import React from "react";
import { useState,useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import axios from "axios";

import Swal from 'sweetalert2'

import LeftSideBar from "../include/LeftSideBar";
import Header2 from "../include/header2";
import Lfooter from "../Lfooter";
export default function User_Profile() {
  const fetchWalletBalQuery = gql`
  {
    userDetails {
      userName
      Phone
      id
    }
  }
`;

function submitPassword(){
  if(passwordDetails.confirmPassword == ""|| passwordDetails.newPassword == ""|| passwordDetails.password == ""){
    alert("Please enter all the details")
  }else{
    if(passwordDetails.confirmPassword != passwordDetails.newPassword){
      alert("Confirm Password Not Matching")
    }else{
      //make a axios call to change the password from the backend
     axios.post(`${process.env.REACT_APP_BaseUrl}/resetPassword`, passwordDetails,{headers:{
        "Authorization":`Bearer ${localStorage.getItem("accessToken")}`
      }}).then((data) => {
        console.log(data)
        console.log(data.data)
        if(data.data.error == false){
          Swal.fire({
            title: `${data.data.message}`,
            confirmButtonText: "OK",
            icon: 'success'
          }
          )
        }else{
          Swal.fire({
            title: `${data.data.message}`,
            confirmButtonText: "OK",
            icon: 'error'
          })
        }
      })
      // const accessToken = response?.data?.accessToken;
      
  }
}
  
  
}
let { loading, error, data } = useQuery(fetchWalletBalQuery);
  var [userDetails,setUserDetails] = useState({
    "userName":"",
    "phone":"",
    "userId":""
  })

  var [passwordDetails,setPasswordDetails] = useState({
    "password":"",
    "newPassword":"",
    "confirmPassword":""
  })

console.log(data ? data : error);
  useEffect(() => {
    if(loading == false && data){
      if(data?.userDetails?.length > 0){
      data = data.userDetails[0]
      setUserDetails({
        "userName":data.userName,
        "phone":data.Phone,
        "userId":data.id
      })
    }
    }
    
    
  }, [data,loading]);
  // setUserDetails(data)

  return (
    
    <div>
      {/* Header Section start */}
      <Header2 />
      <div className="container">
      <div className="row">
        <div className="col-md-2 col-12">
          <LeftSideBar />
        </div>

        <div className="col-md-10 col-12">
          <div className="divider"></div>
          {/*         
          <br></br>   
          <br></br>       */}
          <br></br>
        
          <div class="body-middle1">
            {/* <!--Breadcumnd--> */}

            {/* <!--Breadcumnd--> */}

            {/* <!--profile-->  */}
            <div class="profile-section pb-60">
              <div class="container p-0">
                <div class="row">
                  <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-6">
                    <div class="profile-left-wrap">
                      <div class="form-box form-box-custom">
                        <div class="form-head">
                          {/* <span>Details</span> */}
                          <h5>User Details </h5>
                        </div>
                        <form action="#0">
                          <label for="exampleInputEmail1" className="mb-2">
                            User Name
                          </label>
                          <div class="form-grp">
                            <input type="text" placeholder={userDetails.userName} />
                            <div class="left-icon">
                              <i class="fas fa-user"></i>
                            </div>
                          </div>
                          
                          <label for="exampleInputEmail1" className="mb-2">
                            Mobile
                          </label>
                          <div class="form-grp">
                            <input type="number" placeholder={userDetails.phone} />
                            <div class="left-icon">
                              <i class="fas fa-phone"></i>
                            </div>
                          </div>
                          <div class="form-grp">
                            {/* <button type="submit" class="cmn--btn">
                            </button> */}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6">
                    <div class="profile-left-wrap">
                      <div class="form-box form-box-custom">
                        <div class="form-head">
                          <h5>Change Password</h5>
                        </div>
                        <form action="#0">
                          <label for="exampleInputEmail1" className="mb-2">
                           Current Password
                          </label>
                          <div class="form-grp">
                            <input type="password" placeholder="*****"
                            onChange={(e) => {
                              e.preventDefault();
                              setPasswordDetails({
                                ...passwordDetails,
                                password: e.target.value,
                              });
                            }}
                            />
                            <div class="left-icon">
                              <i class="fas fa-lock"></i>
                            </div>
                          
                          </div>
                          <label for="exampleInputEmail1" className="mb-2">
                            New Password
                          </label>
                          <div class="form-grp">
                            <input
                              type="password"
                              placeholder="
                                    *****"
                                    onChange={(e) => {
                                      e.preventDefault();
                                      setPasswordDetails({
                                        ...passwordDetails,
                                        newPassword: e.target.value,
                                      });
                                    }}
                            />
                            <div class="left-icon">
                              <i class="fas fa-lock"></i>
                            </div>
                          
                          </div>
                          <label for="exampleInputEmail1" className="mb-2">
                            Confirm Password
                          </label>
                          <div class="form-grp ">
                            <input type="password" placeholder="*****" 
                            onChange={(e) => {
                              e.preventDefault();
                              setPasswordDetails({
                                ...passwordDetails,
                                confirmPassword: e.target.value,
                              });
                            }}
                            />
                            <div class="left-icon">
                              <i class="fas fa-lock"></i>
                            </div>
                            {/* <div class="right-lock">
                              <i class="fa-solid fa-lock"></i>
                            </div> */}
                            {/* <a href="#0" class="repeat">
                                       <i class="fas fa-repeat"></i>
                                    </a> */}
                          </div>

                          <div class="form-grp">
                            <button type="submit" class="cmn--btn" onClick={(e) =>{e.preventDefault(); submitPassword();}}>
                              <span>Change Password</span>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
       
    </div>
  );
}