import React from "react";
import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import Header from "../include/header";
import Loader from "react-js-loader";
import jwt_decode from "jwt-decode";


import LeftSideBar from "../include/LeftSideBar";
import Header2 from "../include/header2";
import Lfooter from "../Lfooter";
export default function Wallet() {
  var decoded = jwt_decode(localStorage.getItem("accessToken"));

  const fetchWalletBalQuery = gql`
    {
      Wallet {
        UserName
        Amount
        Points
      }
    }
  `;
  let { loading, error, data } = useQuery(fetchWalletBalQuery);
  const points = data?.Wallet[0]?.Amount * data?.Wallet[0]?.Points;
  console.log(points);
  if (loading) return  <img src="assets/img/logo/game.png"  className="loaderimg"/>;

  return (
    <div>
      {/* Header Section start */}
      <Header2 />
      <div className="container">
      <div className="row">
       
        <div className="col-2">
          <LeftSideBar />
        </div>

        <div className="col-10">
          <br></br>
          <div class="body-middle">
            {/* <!--Breadcumnd--> */}
            {/* <div class="breadcumnd-banner">
              <div class="container">
                <div class="breadcumd-content">
                  <h1>Wallet</h1>
                  <ul class="bread-tag">
                    <li>
                      <a >Home</a>
                    </li>
                    <li>
                      <i class="fas fa-arrow-right"></i>
                    </li>
                    <li>Wallet</li>
                  </ul>
                </div>
              </div>
            </div> */}
            {/* <!--Breadcumnd--> */}

            {/* <!--profile-->  */}
            <div class="profile-section pb-60">
              <div class="container p-0">
                <div class="tab-content pt-20" id="pro-wrap">
                  <div class="">
                    <div class="row justify-content-between">
                      <h3 class="wallet-title">Wallet</h3>
                      <div class="wallet-tab-wrap">
                        <ul class="nav">
                          <li class="nav-item">
                            <Link
                              class="nav-link link-secondary active"
                              id="wallet-tab"
                              to="/deposite_now"
                            >
                              <span>Deposit</span>
                            </Link>
                          </li>
                        </ul>
                        <div class="gift-card">
                          <a  class="left-cart">
                            {/* <span>
                              <img
                                src="assets/img/table/details/gitft.png"
                                alt="img"
                              />
                            </span> */}
                            <h6> {data?.Wallet[0]?.Amount * data?.Wallet[0]?.Points} </h6>{" "}
                          </a>
                          <Link to="/Withdraw" class="cmn--btn">
                            <span>Claim</span>
                          </Link>
                        </div>
                      </div>
                      <div class="tab-content" id="wall-wrap">
                        <div
                          class="tab-pane fade show active"
                          id="wallet1"
                          role="tabpanel"
                        >
                          <div class="currency-wrap">
                            <h5 class="currency-title">Bank Deposit</h5>
                            <div class="row mb-4 g-3">
                              <div class="col-lg-4">
                                <div class="bitcoin-item">
                                  <a  class="icon-wrap">
                                    <span class="icon">
                                      <img
                                        src="assets/img/table/details/b1.png"
                                        alt="icon"
                                      />
                                    </span>
                                    <span>Ecopayz</span>
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-4">
                                <div class="bitcoin-item">
                                  <a  class="icon-wrap">
                                    <span class="icon">
                                      <img
                                        src="assets/img/table/details/b2.png"
                                        alt="icon"
                                      />
                                    </span>
                                    <span>
                                      Cards through PayDo wallet top-up
                                    </span>
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-4">
                                <div class="bitcoin-item select-algeria">
                                  <a  class="icon-wrap">
                                    <span class="icon">
                                      <img
                                        src="assets/img/table/details/algeria.png"
                                        alt="icon"
                                      />
                                    </span>
                                  </a>
                                  <select name="algeria" id="algeri-select">
                                    <option value="1">Algeria</option>
                                    <option value="1">England</option>
                                    <option value="1">Croatia</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <h5 class="currency-title">Gift Cards</h5>
                            <div class="row g-3">
                              <div class="col-lg-4">
                                <div class="bitcoin-item">
                                  <a h class="icon-wrap">
                                    <span class="icon">
                                      <img
                                        src="assets/img/table/details/master.png"
                                        alt="icon"
                                      />
                                    </span>
                                    <span>MasterCard</span>
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-4">
                                <div class="bitcoin-item">
                                  <a  class="icon-wrap">
                                    <span class="icon">
                                      <img
                                        src="assets/img/table/details/visa.png"
                                        alt="icon"
                                      />
                                    </span>
                                    <span>Visa</span>
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-4">
                                <div class="bitcoin-item">
                                  <a  class="icon-wrap">
                                    <span class="icon">
                                      <img
                                        src="assets/img/table/details/paypal.png"
                                        alt="icon"
                                      />
                                    </span>
                                    <span>Paypal</span>
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-4">
                                <div class="bitcoin-item">
                                  <a  class="icon-wrap">
                                    <span class="icon">
                                      <img
                                        src="assets/img/table/details/paysafe.png"
                                        alt="icon"
                                      />
                                    </span>
                                    <span>Paysafecard</span>
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-4">
                                <div class="bitcoin-item">
                                  <a  class="icon-wrap">
                                    <span class="icon">
                                      <img
                                        src="assets/img/table/details/gplay.png"
                                        alt="icon"
                                      />
                                    </span>
                                    <span>Google Pay</span>
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-4">
                                <div class="bitcoin-item">
                                  <a  class="icon-wrap">
                                    <span class="icon">
                                      <img
                                        src="assets/img/table/details/trustly.png"
                                        alt="icon"
                                      />
                                    </span>
                                    <span>Trustly</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="wallet2" role="tabpanel">
                          <div class="currency-wrap">
                            <h5 class="currency-title">Crypto Currencies</h5>
                            <div class="row mb-4 g-3">
                              <div class="col-lg-6">
                                <div class="bitcoin-item">
                                  <a  class="icon-wrap">
                                    <span class="icon">
                                      <img
                                        src="assets/img/table/details/bitcoin.png"
                                        alt="icon"
                                      />
                                    </span>
                                    <span>Bitcoin</span>
                                  </a>
                                  <a >
                                    <span> 1 BTC = $19565.46</span>
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="bitcoin-item">
                                  <a  class="icon-wrap">
                                    <span class="icon">
                                      <img
                                        src="assets/img/table/details/ethereum.png"
                                        alt="icon"
                                      />
                                    </span>
                                    <span>Ethereum</span>
                                  </a>
                                  <a >
                                    <span> 1 ETH = $1343.21</span>
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="bitcoin-item">
                                  <a  class="icon-wrap">
                                    <span class="icon">
                                      <img
                                        src="assets/img/table/details/thether.png"
                                        alt="icon"
                                      />
                                    </span>
                                    <span>TETHER</span>
                                  </a>
                                  <a >
                                    <span> 1 USDT = $1.00</span>
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="bitcoin-item">
                                  <a  class="icon-wrap">
                                    <span class="icon">
                                      <img
                                        src="assets/img/table/details/litecoin.png"
                                        alt="icon"
                                      />
                                    </span>
                                    <span>Litecoin</span>
                                  </a>
                                  <a >
                                    <span> 1 LTC = $53.76</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="wallet3" role="tabpanel">
                          <div class="description-table">
                            <table>
                              <tbody>
                                <tr>
                                  <th>Amount</th>
                                  <th>Date</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                                <tr class="tb1">
                                  <td>
                                    <span class="text1">-$620</span>
                                  </td>
                                  <td>
                                    04:02 PM
                                    <span>10 Mar 2022</span>
                                  </td>
                                  <td>In Progress</td>
                                  <td>
                                    <span class="text1">Withdraw</span>
                                  </td>
                                </tr>
                                <tr class="tb2">
                                  <td>
                                    <span class="text1">-$420</span>
                                  </td>
                                  <td>
                                    05:02 PM
                                    <span>10 Mar 2022</span>
                                  </td>
                                  <td>Completed</td>
                                  <td>
                                    <span class="text1">Deposit</span>
                                  </td>
                                </tr>
                                <tr class="tb1">
                                  <td>
                                    <span class="text1">-$220</span>
                                  </td>
                                  <td>
                                    11:2 PM
                                    <span>10 Mar 2022</span>
                                  </td>
                                  <td>Pending</td>
                                  <td>
                                    <span class="text1">Withdraw</span>
                                  </td>
                                </tr>
                                <tr class="tb2">
                                  <td>
                                    <span class="text1">-$320</span>
                                  </td>
                                  <td>
                                    09:45 PM
                                    <span>10 Mar 2022</span>
                                  </td>
                                  <td>Completed</td>
                                  <td>
                                    <span class="text1">Deposit</span>
                                  </td>
                                </tr>
                                <tr class="tb1">
                                  <td>
                                    <span class="text1">-$920</span>
                                  </td>
                                  <td>
                                    07:20 PM
                                    <span>10 Mar 2022</span>
                                  </td>
                                  <td>Pending</td>
                                  <td>
                                    <span class="text1">Withdraw</span>
                                  </td>
                                </tr>
                                <tr class="tb1">
                                  <td>
                                    <span class="text1">-$920</span>
                                  </td>
                                  <td>
                                    03:53 PM
                                    <span>10 Mar 2022</span>
                                  </td>
                                  <td>Cancelled</td>
                                  <td>
                                    <span class="text1">Withdraw</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
       
    </div>
  );
}
