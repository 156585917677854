import React, { useState, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Loader from "react-js-loader";
import { ToastContainer, toast } from "react-toastify";
import ticket from "../Assets/Img/ticket.svg";
import walleticon from "../Assets/Img/walletnew.svg";
import coinsimg from "../Assets/Img/coinsimage.svg";
import { gql, useQuery } from "@apollo/client";

export default function Header2() {
  var accessToken = localStorage.getItem("accessToken")
  const [walletBal, setWalletBal] = useState(0);
  var decoded = jwt_decode(localStorage.getItem("accessToken"));

  const fetchWalletBalQuery = gql`
    {
      Wallet {
        UserName
        Amount
        Points
      }
    }
  `;

  let { loading, error, data } = useQuery(fetchWalletBalQuery);
    console.log(data ? data : error)

  useEffect(() => {
    if (!loading && data) {
      setWalletBal(data.Wallet[0].Amount);
      toast.success(`Welcome Back ${decoded.name}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [loading, data]);

  function handleLogout() {
    console.log("Handle Logout");
   
    sessionStorage.clear();
    localStorage.clear();
    // let element =  document.querySelector(".modal-backdrop")
    // element.classList.remove("modal-backdrop");
    // window.location.reload(true);

    window.location.href = "/"
  }

  if (loading)
    return <img src="assets/img/logo/game.png" className="loaderimg" />;

  return (
    <>
      <header class="header-section header-hidden">
        <div class="header-wrapper">
          <div class="menu-logo-adjust d-flex align-items-center">
            <div class="logo-menu ">
              <Link to="/" class="logo">
                <img src="assets/img/logo/Big_logo.png" alt="logo" />
              </Link>
              <Link to="/" class="dark-logo">
                <img src="assets/img/logo/Big_logo.png" alt="logo" />{" "}
              </Link>
            </div>
            <div class="header-bar">
              <span></span>
              <span></span>
              <span></span>
            </div>
            {/* <div class="ticker-wrap">
              <div class="ticker">
                <div class="ticker__item">In publishing and graphic design</div>
              </div>
            </div> */}
          </div>
          <div class="right-menu-reature">
            {/* <div class="input-box">
              <i class="uil uil-search"></i>
              <input type="text" placeholder="Search here..." />
              <button class="button">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
            </div> */}
            <Link to="/Bets">
              <div class="blance-items blance-items-custom">
                <div class="left-blance">
                  {/* <i class="fa fa-user" aria-hidden="true" ></i> */}
                  <span class="text-blabce text-blabce-custom">
                    <img src={ticket} />
                    &nbsp;&nbsp;My Bets
                  </span>
                </div>
                <span class="blance"></span>
              </div>
            </Link>
            <Link to="/wallet">
              <div class="blance-items">
                <div class="left-blance left-blance-custom">
                  {/* <span>
                    <img
                      src="assets/img/header/right-icon/wallet.svg"
                      alt="icon"
                    />
                  </span> */}
                  <span class="text-blabce ">
                    <img className="coin-custom" src={coinsimg} /> {walletBal}{" "}
                  </span>
                  &nbsp;{" "}
                  <span>
                    <img className="coin-custom1" src={walleticon} alt="icon" />
                  </span>
                </div>
                <span class="blance"></span>
              </div>
            </Link>

            <div className="profilebox">
              <h5 className="desktop-view">{decoded.name} </h5>
              <span>
                {" "}
                <div class="language">
                  <div class="nice-select" tabindex="0">
                    <span class="current"> </span>
                    <ul class="list">
                      <li data-value="3" class="option">
                        <Link to="/User_Profile">
                          <span>
                            <i
                              class="fas fa-user"
                              style={{
                                "font-size": "20px",
                                "margin-right": "3px",
                              }}
                            ></i>{" "}
                            Profile
                          </span>
                        </Link>
                      </li>
                      <li data-value="3" class="option">
                        <Link to="/Deposite_now">
                          <span>
                            <i
                              class="fa-solid fa-sack-dollar"
                              style={{
                                "font-size": "20px",
                                "margin-right": "3px",
                              }}
                            ></i>{" "}
                            Deposit
                          </span>
                        </Link>
                      </li>
                      <li data-value="3" class="option">
                        <Link to="/Withdraw">
                          <span>
                            <i
                              class="fa fa-bank"
                              style={{
                                "font-size": "12px",
                                "margin-right": "3px",
                              }}
                            ></i>{" "}
                            Withdraw
                          </span>
                        </Link>
                      </li>

                      {/* <li data-value="3" class="option">
                        <Link  to="/Wallet">
                          <span>
                          <i
                            class="fa-solid fa-wallet"
                            style={{
                              "font-size": "20px",
                              "margin-right": "3px",
                            }}
                          ></i>{" "}
                            Wallet 
                          </span>
                        </Link>
                      </li> */}

                      <li data-value="3" class="option">
                        <Link to="/Transaction">
                          <span>
                            <i
                              class="fa-solid fa-wallet"
                              style={{
                                "font-size": "12px",
                                "margin-right": "3px",
                              }}
                            ></i>{" "}
                            Transaction
                          </span>
                        </Link>
                      </li>
                      <li data-value="3" class="option">
                        <Link to="/PL">
                          <span>
                            <i
                              class="fa fa-money"
                              style={{
                                "font-size": "12px",
                                "margin-right": "3px",
                              }}
                              aria-hidden="true"
                            ></i>{" "}
                            &nbsp;Profit & Loss
                          </span>
                        </Link>
                      </li>
                      <li data-value="3" class="option">
                        <Link to="/Bets">
                          <span>
                            <i
                              class="fa fa-user"
                              style={{
                                "font-size": "12px",
                                "margin-right": "3px",
                              }}
                              aria-hidden="true"
                            ></i>
                            &nbsp; My Bets
                          </span>
                        </Link>
                      </li>
                      <li data-value="3" class="option">
                        <Link to="/Setting">
                          <span>
                            <i
                              class="fas fa-gear "
                              style={{
                                "font-size": "20px",
                                "margin-right": "3px",
                              }}
                            ></i>{" "}
                            Setting
                          </span>
                        </Link>
                      </li>
                      <li data-value="3" class="option">
                        <Link
                          class=" link-secondary"
                          onClick={() => handleLogout()}
                          to="#"
                        >
                          <span>
                            <i
                              class="fas fa-sign-out"
                              style={{
                                "font-size": "20px",
                                "margin-right": "3px",
                              }}
                            ></i>{" "}
                            Logout
                          </span>
                        </Link>
                      </li>
                    </ul>
                    <div class="glo-icon">
                      <div className="profilebox">
                        <span className="desktop-view">
                          {decoded.phone_no}
                          <i class="fa-solid fa-chevron-down"></i>
                        </span>
                        <span className="mobile-view">
                          <i class="fa-solid fa-chevron-down"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </span>

              <ul class="dropdown-menu">
                <li>
                  <Link href="#">Profile</Link>
                </li>
                <li>
                  <Link href="#">Logout</Link>
                </li>
              </ul>
            </div>

            <div class="signup-area">
              <img
                class="img-circle"
                src="https://edunet.vercel.app/images/avatar/1.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </header>

      <ToastContainer />

      <div className="col-12 col-margin-auto">
        <div class="banner-feature">
          <div class="container-fluid">
            <div class="banner-feature-wrapper">
              <a href="live.html" class="banner-feature-items">
                <span class="banner-feauter-thumb">
                  <img
                    src="assets/img/banner-freature/live.svg"
                    alt="feature"
                  />
                </span>
                <span class="banner-feature-contentt">Live</span>
              </a>
              <a href="#0" class="banner-feature-items">
                <span class="banner-feauter-thumb">
                  <img
                    src="assets/img/banner-freature/parcents.svg"
                    alt="feature"
                  />
                </span>
                <span class="banner-feature-contentt">Margin</span>
              </a>
              <Link to="/football" class="banner-feature-items">
                <span class="banner-feauter-thumb">
                  <img
                    src="assets/img/banner-freature/football.svg"
                    alt="feature"
                  />
                </span>
                <span class="banner-feature-contentt">Football</span>
              </Link>
              <Link to="/tennis" class="banner-feature-items">
                <span class="banner-feauter-thumb">
                  <img
                    src="assets/img/banner-freature/tennis.svg"
                    alt="feature"
                  />
                </span>
                <span class="banner-feature-contentt">Tennis</span>
              </Link>
              <Link to="/tennis" class="banner-feature-items">
                <span class="banner-feauter-thumb">
                  <img
                    src="assets/img/banner-freature/table.svg"
                    alt="feature"
                  />
                </span>
                <span class="banner-feature-contentt">Tennis</span>
              </Link>
              <Link to="/football" class="banner-feature-items">
                <span class="banner-feauter-thumb">
                  <img
                    src="assets/img/banner-freature/basketball.svg"
                    alt="feature"
                  />
                </span>
                <span class="banner-feature-contentt">Basketball</span>
              </Link>
              <a href="rugby.html" class="banner-feature-items">
                <span class="banner-feauter-thumb">
                  <img
                    src="assets/img/banner-freature/esport.svg"
                    alt="feature"
                  />
                </span>
                <span class="banner-feature-contentt">eSports</span>
              </a>
              <a href="profile.html" class="banner-feature-items">
                <span class="banner-feauter-thumb">
                  <img
                    src="assets/img/banner-freature/promotion.svg"
                    alt="feature"
                  />
                </span>
                <span class="banner-feature-contentt">Promotions</span>
              </a>
              <Link to="/cricket" class="banner-feature-items">
                <span class="banner-feauter-thumb">
                  <img
                    src="assets/img/banner-freature/cricket.svg"
                    alt="feature"
                  />
                </span>
                <span class="banner-feature-contentt">Cricket</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
