import React from "react";
import { useState, useEffect } from "react";
import { gql, useQuery,useMutation } from "@apollo/client";
import LeftSideBar from "../include/LeftSideBar";
import Header2 from "../include/header2";
import Lfooter from "../Lfooter";
import Loader from "react-js-loader";

export default function Setting() {
  
  const [isLoading, setIsLoading] = useState(false);
  const [fetchStakesButtonVal, setFetchStakesButtonVal] = useState({
    val1: 0,
    val2: 0,
    val3: 0,
    val4: 0,
    val5: 0,
    val6: 0,
  });
  const [updateStakesButtonVal, setUpdateStakesButtonVal] = useState({
    val1: 0,
    val2: 0,
    val3: 0,
    val4: 0,
    val5: 0,
    val6: 0,
  });
  const fetchStakesButtonValQuery = gql`
    query FetchStakesButton {
      StakesButton {
        Date
        UserId
        Value1
        Value2
        Value3
        Value4
        Value5
        Value6
      }
    }
  `;
  const updateStakesValueMutation = gql`mutation UpdateStakesButton {
    update_StakesButton(where: {},_set: {Value1: ${updateStakesButtonVal.val1}, Value2: ${updateStakesButtonVal.val2}, Value3: ${updateStakesButtonVal.val3}, Value4: ${updateStakesButtonVal.val4}, Value5: ${updateStakesButtonVal.val5}, Value6: ${updateStakesButtonVal.val6}}) {
      returning {
        Value1
        Value2
        Value3
        Value4
        Value5
        Value6
      }
    }
  }`
  let { data, loading, error } = useQuery(fetchStakesButtonValQuery);
  let [updateStakes,{data:updateStakesData,loading:updateStakesLoading,error:updateStakesError}] = useMutation(updateStakesValueMutation)
  useEffect(() => {
    if (!loading && data) {
      setFetchStakesButtonVal({
        ...fetchStakesButtonVal,
        val1: data.StakesButton[0].Value1 ? data.StakesButton[0].Value1 : 0,
        val2: data.StakesButton[0].Value2 ? data.StakesButton[0].Value2 : 0,
        val3: data.StakesButton[0].Value3 ? data.StakesButton[0].Value3 : 0,
        val4: data.StakesButton[0].Value4 ? data.StakesButton[0].Value4 : 0,
        val5: data.StakesButton[0].Value5 ? data.StakesButton[0].Value5 : 0,
        val6: data.StakesButton[0].Value6 ? data.StakesButton[0].Value6 : 0,
      });
      setUpdateStakesButtonVal({
        ...updateStakesButtonVal,
        val1: data.StakesButton[0].Value1 ? data.StakesButton[0].Value1 : 0,
        val2: data.StakesButton[0].Value2 ? data.StakesButton[0].Value2 : 0,
        val3: data.StakesButton[0].Value3 ? data.StakesButton[0].Value3 : 0,
        val4: data.StakesButton[0].Value4 ? data.StakesButton[0].Value4 : 0,
        val5: data.StakesButton[0].Value5 ? data.StakesButton[0].Value5 : 0,
        val6: data.StakesButton[0].Value6 ? data.StakesButton[0].Value6 : 0,
      });
    }
  }, [data, loading]);

  useEffect(()=>{

  },[isLoading,updateStakesLoading])

  if (loading || isLoading || updateStakesLoading) {
    return (
     <img src="assets/img/logo/game.png"  className="loaderimg"/>
    );
  }

  return (
    <div>
      {/* Header Section start */}
      <Header2 />
      <div className="row">
        <div className="col-12 col-md-2">
          <LeftSideBar />
        </div>

        <div className="col-12 col-md-10">
          <br></br>
          <div class="body-middle">
            {/* <!--Breadcumnd--> */}

            {/* <!--Breadcumnd--> */}

            {/* <!--profile-->  */}
            <div class="profile-section pb-60">
              <div class="container p-0">
                <div
                  class="tab-content                                                                                 "
                  id="pro-wrap"
                >
                  <div class="container">
                    <div class="row">
                      <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
   
                        <div class="profile-left-wrap form-box ">
                          <div className="form-box-setting ">
                          <div class="accounts-box accounts-box-custom ">
                          <p className="edit-one"> Default Stakes</p>
                           <br></br>
                            <div className="row">
                              <div className="col-md-4 col-6 my-2">
                                <div class="form-grp ">
                                  <button type="submit" class="cmn--btn cmn--btn1 cmn--btn55">
                                    <span class="v-btn__content">
                                      <div class="text-center"> {fetchStakesButtonVal.val1} </div>{" "}
                                      <div
                                        class="text-center"
                                        style={{ "font-size": "8px" }}
                                      >
                                        Active Stake
                                      </div>
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div className="col-md-4 col-6 my-2">
                                <div class="form-grp">
                                  <button type="submit" class="cmn--btn cmn--btn1 cmn--btn55">
                                    <span class="v-btn__content">
                                      <div class="text-center">{fetchStakesButtonVal.val2}</div>{" "}
                                      <div
                                        class="text-center"
                                        style={{ "font-size": "8px" }}
                                      >
                                        Active Stake
                                      </div>
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div className="col-md-4 col-6 my-2">
                                <div class="form-grp">
                                  <button type="submit" class="cmn--btn cmn--btn1 cmn--btn55">
                                    <span class="v-btn__content">
                                      <div class="text-center">{fetchStakesButtonVal.val3}</div>{" "}
                                      <div
                                        class="text-center"
                                        style={{ "font-size": "8px" }}
                                      >
                                        Active Stake
                                      </div>
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div className="col-md-4 col-6 my-2">
                                <div class="form-grp">
                                  <button type="submit" class="cmn--btn cmn--btn1 cmn--btn55">
                                    <span class="v-btn__content">
                                      <div class="text-center">{fetchStakesButtonVal.val4}</div>{" "}
                                      <div
                                        class="text-center"
                                        style={{ "font-size": "8px" }}
                                      >
                                        Active Stake
                                      </div>
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div className="col-md-4 col-6 my-2">
                                <div class="form-grp">
                                  <button type="submit" class="cmn--btn cmn--btn1 cmn--btn55">
                                    <span class="v-btn__content">
                                      <div class="text-center">{fetchStakesButtonVal.val5}</div>{" "}
                                      <div
                                        class="text-center"
                                        style={{ "font-size": "8px" }}
                                      >
                                        Active Stake
                                      </div>
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div className="col-md-4 col-6 my-2">
                                <div class="form-grp">
                                  <button type="submit" class="cmn--btn cmn--btn1 cmn--btn55">
                                    <span class="v-btn__content">
                                      <div class="text-center">{fetchStakesButtonVal.val6}</div>{" "}
                                      <div
                                        class="text-center"
                                        style={{ "font-size": "8px" }}
                                      >
                                        Active Stake
                                      </div>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            {/* <div className="row">
                              <div className="col-md-6 mt-3">
                                <a href="#0" class="btn--two btn-two-custom">
                                  <span>Edit</span>
                                </a>
                              </div>
                              <div className="col-md-6 mt-3">
                                <a href="#0" class="btn--two btn-two-custom">
                                  <span>Save</span>
                                </a>
                              </div>
                            </div> */}
                          </div>
                        </div>
                        </div>
                      </div>
                      <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        

                        <div class="profile-left-wrap ">
                       
                          <div class="form-box form-box-custom ">
                          <p className="edit-one">Edit Stakes</p>
                        <br></br>
                          <form action="#0" 
                          onSubmit={e=>{e.preventDefault();setIsLoading(true);updateStakes();setIsLoading(false)}}
                          >
                              <div className="row">
                                <div className="col-md-4 mt-4">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2"
                                  >
                                   First Stake
                                  </label>
                                  <div class="form-grp">
                                    <input
                                      onChange={(e) => {
                                        e.preventDefault();
                                       setUpdateStakesButtonVal({...updateStakesButtonVal,val1:e.target.value})
                                      }}
                                      type="text"
                                      
                                      
                                    />
                                   
                                  </div>
                                </div>
                                <div className="col-md-4 mt-4">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2"
                                  >
                                    Second Stake
                                  </label>
                                  <div class="form-grp">
                                    <input
                                      type="text"
                                      
                                      onChange={(e) => {
                                        e.preventDefault();
                                       setUpdateStakesButtonVal({...updateStakesButtonVal,val2:e.target.value})
                                      }}
                                      
                                    />
                                   
                                  </div>
                                </div>
                                <div className="col-md-4 mt-4">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2"
                                  >
                                    Third Stake
                                  </label>
                                  <div class="form-grp">
                                    <input
                                      type="number"
                                      
                                      onChange={(e) => {
                                        e.preventDefault();
                                       setUpdateStakesButtonVal({...updateStakesButtonVal,val3:e.target.value})
                                      }}
                                      
                                    />
                                   
                                  </div>
                                </div>

                                <div className="col-md-4 mt-4">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2"
                                  >
                                    Fourth Stake
                                  </label>
                                  <div class="form-grp ">
                                    <input
                                    onChange={(e) => {
                                      e.preventDefault();
                                     setUpdateStakesButtonVal({...updateStakesButtonVal,val1:e.target.value})
                                    }}
                                      type="text"
                                      
                                    />
                                   
                                  </div>
                                </div>
                                <div className="col-md-4 mt-4">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2"
                                  >
                                    Fifth Stake
                                  </label>
                                  <div class="form-grp ">
                                    <input
                                    
                                    onChange={(e) => {
                                      e.preventDefault();
                                     setUpdateStakesButtonVal({...updateStakesButtonVal,val5:e.target.value})
                                    }}
                                      type="text"
                                      
                                    />
                                   
                                  </div>
                                </div>
                                <div className="col-md-4 mt-4">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2"
                                  >
                                    Sixth Stake
                                  </label>
                                  <div class="form-grp ">
                                    <input
                                    
                                    onChange={(e) => {
                                      e.preventDefault();
                                     setUpdateStakesButtonVal({...updateStakesButtonVal,val6:e.target.value})
                                    }}
                                      type="text"
                                      
                                    />
                                   
                                  </div>
                                </div>
                                <div class="form-grp mt-4">
                                  <button type="submit" class="cmn--btn">
                                    <span>Submit</span>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

       
    </div>
  );
}
