import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Betslip from "./Betslip";
import Loader from "react-js-loader";
import Casino1 from "../Assets/Img/Casino1.png";
import Casino2 from "../Assets/Img/Casino2.png";

import horse from "./horse.png";
import Header from "../include/header";
import Header2 from "../include/header2";
import LeftSideBar from "../include/LeftSideBar";
import { Carousel } from "react-responsive-carousel";
import BottomNavigation from "reactjs-bottom-navigation";
import "reactjs-bottom-navigation/dist/index.css";
import {
  gql,
  useQuery,
  useMutation,
  useLazyQuery,
  useSubscription,
} from "@apollo/client";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "animate.css";
import Lfooter from "../Lfooter";
import Accordian from "./Common Accordian/Accordian";
const Tennis = () => {
  // const imgurl = "https://backend-bb.katsamsoft.com";
  const navigate = useNavigate();

  let count = 0;
  let count2 = 0;
  let [fixtureId, setFixtureId] = useState("");
  const [matchNumber, setMatchNumber] = useState("#MATCH1");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [matchDtls, setMatchDtls] = useState({
    Team1: "",
    Team2: "",
    Outcome: "",
    MatchName: "",
    oddVal: 0,
    Status: 1,
    fixtureId: "",
  });
  // var matchDtls = {
  //   Team1:"",
  //   Outcome:"",
  //   MatchName:"",
  //   oddVal:""
  // }
  let [insiderActivity, setInsiderActivity] = useState("d-none");
  let [tennisActivity, setTennisActivity] = useState("active");

  const [col, setCol] = useState("col-md-10 col-12");
  const [dsiplay, setDisplay] = useState("col-3 d-none ");
  const bottomNavItems = [
    {
      icon: <i class="icon-home"></i>,
      activeIcon: <i class="icon-home"></i>,
      route: "/",
    },
    {
      icon: <i class="icon-cricket"></i>,
      activeIcon: <i class="icon-cricket"></i>,
      route: "/cricket",
    },
    {
      icon: <i class="icon-futsal"></i>,
      activeIcon: <i class="icon-futsal"></i>,
      route: "/football",
    },
    {
      icon: <i class="icon-etennis"></i>,
      activeIcon: <i class="icon-etennis"></i>,
      route: "/tennis",
    },
    {
      icon: <i class="icon-etennis"></i>,
      activeIcon: <i class="icon-etennis"></i>,
      route: "/tennis",
    },
  ];
  const queryStatus1 = gql`
    {
      Fixture(
        order_by: { StartDate: desc }
        where: { SportId: { _eq: "54094" }
         Status: { _eq: 1 } 
         GameActivity: { _eq: true }}
        limit: 10
      ) {
        FixtureId
        LastUpdate
        LeagueId
        LocId
        SportId
        StartDate
        Status
        GameActivity
        GetParticipants {
          Name
          Position
          FixtureId
          Id
        }
        GetProvider {
          FixtureId
          Id
          LastUpdate
          MarketId
          Name
          GetBetsApi(
            order_by: { LastUpdate: desc }
            where: { Name: { _in: ["1", "2"] } }
          ) {
            Back1Price
            Back1PriceVolume
            Back2Price
            Back2PriceVolume
            Id
            Index
            LastUpdate
            Lay1Price
            Lay1PriceVolume
            Lay2Price
            Lay2PriceVolume
            LayPrice
            LayPriceVolume
            Name
            Price
            PriceVolume
            ProviderId
            StartPrice
            Status
          }
        }
      }
    }
  `;
  const fetchTennisSliderQuery = gql`
    query fetchImage {
      SliderImages(where: { GameType: { _eq: "Tennis" } }) {
        ImageName
        URL
      }
    }
  `;
  const queryStatus2 = gql`
    {
      Fixture(
        order_by: { StartDate: desc }
        where: { SportId: { _eq: "54094" }
         Status: { _eq: 2 } 
         GameActivity: { _eq: true } }
        limit: 10
      ) {
        FixtureId
        LastUpdate
        LeagueId
        LocId
        SportId
        StartDate
        Status
        GameActivity
        GetParticipants {
          Name
          Position
          FixtureId
          Id
        }
        GetProvider {
          FixtureId
          Id
          LastUpdate
          MarketId
          Name
          GetBetsApi(
            order_by: { LastUpdate: desc }
            where: { Name: { _in: ["1", "2"] } }
          ) {
            Back1Price
            Back1PriceVolume
            Back2Price
            Back2PriceVolume
            Id
            Index
            LastUpdate
            Lay1Price
            Lay1PriceVolume
            Lay2Price
            Lay2PriceVolume
            LayPrice
            LayPriceVolume
            Name
            Price
            PriceVolume
            ProviderId
            StartPrice
            Status
          }
        }
      }
    }
  `;

  let { data, error, loading } = useQuery(queryStatus1);

  let {
    data: dataStatus2,
    error: errorStatus2,
    loading: loadingStatus2,
  } = useQuery(queryStatus2);

  console.log(data ? data : error);

  console.log(dataStatus2 ? dataStatus2 : errorStatus2);

  let {
    data: fetchTennisSliderData,
    error: fetchTennisSliderError,
    loading: fetchTennisSliderLoading,
  } = useQuery(fetchTennisSliderQuery);

  const main_Tennis1 = `${process.env.REACT_APP_BaseUrl}${fetchTennisSliderData?.SliderImages[0].URL}`;
  const main_Tennis2 = `${process.env.REACT_APP_BaseUrl}${fetchTennisSliderData?.SliderImages[1].URL}`;
  // const betSlipIntro = (MatchName,ID,Outcome,oddVal,Status,Activity)=>{
  //   console.log(ID);
  // }
  const betSlipIntro = async (prop) => {
    if ((prop.Activity = "active")) {
      // matchDtls.Team = prop.Team
      // matchDtls.MatchName = prop.MatchName
      // matchDtls.ID = prop.ID
      // matchDtls.Outcome = prop.Outcome
      // matchDtls.oddVal = prop.back1Name1
      await setMatchDtls({
        ...matchDtls,
        Team1: prop.Team,
        MatchName: prop.MatchName,
        ID: prop.ID,
        Outcome: prop.Outcome,
        oddVal: Number(prop.oddVal),
        Status: prop.Status,
        fixtureId: prop.FixtureId,
      });
      myFunction();
    }
    console.log(prop);
  };
  const myFunction = () => {
    setCol("col-md-7 col-12");
    setDisplay(
      "col-md-3 col- 12 d-show animate__animated animate__fadeInRight mt-5 pt-5"
    );
  };
  useEffect(() => {}, [matchDtls]);

  if (loading || loadingStatus2 || fetchTennisSliderLoading)
    return <img src="assets/img/logo/game.png" className="loaderimg" />;

  return (
    <>
      {/* Header Section start */}
      <Header2 />
      {/* {isLoggedIn ? <Header2 /> : <Header />} */}
      <div className="container">
        <div className="row">
          <div className="col-md-2 col-12">
            <LeftSideBar />
          </div>

          <div className={col}>
            <div className="divider"></div>

            <br></br>

            {/* <div className={tennisActivity}>
              <div class="breadcumnd-banner">
                <div className="row">
                  <div className="col-md-6 col-12">
                    <div className="gaptop">
                      <h5>
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/5987/5987898.png"
                          className="tableIcon"
                          alt="icon"
                        />
                        Live Matches
                      </h5>
                    </div>
                    <OwlCarousel
                      className="owl-theme"
                      items={2}
                      autoplayTimeout={1000}
                      margin={8}
                      autoplay={1000}
                      navigation={true}
                      nav={true}
                      dots={false}
                    >
                      <div class="item">
                        <img src={main_Tennis1} className="img-fluid" />
                      </div>
                      <div class="item">
                        <img src={main_Tennis2} className="img-fluid" />
                      </div>
                    </OwlCarousel>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="gaptop">
                      <h5>
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/5987/5987898.png"
                          className="tableIcon"
                          alt="icon"
                        />
                        Upcoming Matches
                      </h5>
                    </div>
                    <OwlCarousel
                      className="owl-theme"
                      items={2}
                      margin={8}
                      autoplay={3000}
                      navigation={true}
                      nav={true}
                      dots={false}
                    >
                      <div class="item">
                        <img src={main_Tennis1} className="img-fluid" />
                      </div>
                      <div class="item">
                        <img src={main_Tennis2} className="img-fluid" />
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <img src={Casino1} className="img-fluid" />
                </div>
                <div className="col-6">
                  <img src={Casino2} className="img-fluid" />
                </div>
              </div>
            </div> */}

            {/* Ending of game header */}
            <div class="countries-tab pb-custom-60">
              <div
                className={`${
                  data.Fixture.length == 0 ? "d-none" : "show"
                } gaptop`}
              >
                <h5>
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/5987/5987898.png"
                    className="tableIcon"
                    alt="icon"
                  />
                  Live Matches
                </h5>
              </div>
              <div class="accordion" id="countries">
                <div class="accordion-item">
                  {data.Fixture.length != 0 ||
                  dataStatus2.Fixture.length != 0 ? (
                    <div class="accordion-header" id="countriestab1">
                      {data?.Fixture.map((teams, index) =>
                        teams.GetProvider.length === 0 ? (
                          ""
                        ) : teams.GameActivity ? (
                          <Accordian
                            data={teams}
                            matchName={"Tennis"}
                            betSlipIntro={betSlipIntro}
                            index={index}
                            status={"Live"}
                            key={index}
                          />
                        ) : (
                          ""
                        )
                      )}
                      <div
                        className={`${
                          dataStatus2.Fixture.length == 0 ? "d-none" : "show"
                        } gaptop`}
                      >
                        <h5>
                          <img
                            src="https://cdn-icons-png.flaticon.com/512/5987/5987898.png"
                            className="tableIcon"
                            alt="icon"
                          />
                          Upcoming Matches
                        </h5>
                      </div>
                      {dataStatus2?.Fixture.map((teams, index) =>
                        teams.GetProvider.length === 0 ? (
                          ""
                        ) : teams.GameActivity ? (
                          <Accordian
                            data={teams}
                            matchName={"Tennis"}
                            betSlipIntro={betSlipIntro}
                            index={index}
                            status={"Upcoming"}
                            key={index}
                          />
                        ) : (
                          ""
                        )
                      )}
                    </div>
                  ) : (
                    <div className="text-center">No Recors Found</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={`${dsiplay} boxCenter`}>
            <Betslip outcome={matchDtls} />
          </div>
        </div>

         
      </div>

      <BottomNavigation
        items={bottomNavItems}
        defaultSelected={3}
        onItemClick={(item) => navigate(`${item.route}`)}
      />
    </>
  );
};

export default Tennis;
