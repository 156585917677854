import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import ChangeHighlight from "react-change-highlight";
import dateFormat, { masks } from "dateformat";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import {
  gql,
  useQuery,
  useMutation,
  useLazyQuery,
  useSubscription,
} from "@apollo/client";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import question_icon from "../../Assets/Img/question_icon.png";
import Betslip from "../Betslip";
import "animate.css";


const Accordian = (data) => {
  let [teams, setTeams] = useState(data.data);
  console.log(data);

  var index = data.index;
  var status = data.status;
  let count = 0;
  let count2 = 0;
  var matchName = data.matchName;
  const [accordianColor,setAccordianColor] = useState("accordianColour")

  let [accordianStatus, setAccordianStatus] = useState("Close");
  let [backAndLay, setBackAndLay] = useState({
    back1Name1: data.data.GetProvider[0].GetBetsApi[0]?.Back1Price
      ? data.data.GetProvider[0].GetBetsApi[0].Back1Price
      : 0,
    back2Name1: data.data.GetProvider[0].GetBetsApi[0]?.Back2Price
      ? data.data.GetProvider[0].GetBetsApi[0].Back2Price
      : 0,
    lay1Name1: data.data.GetProvider[0].GetBetsApi[0]?.Lay1Price
      ? data.data.GetProvider[0].GetBetsApi[0].Lay1Price
      : 0,
    lay2Name1: data.data.GetProvider[0].GetBetsApi[0]?.Lay2Price
      ? data.data.GetProvider[0].GetBetsApi[0].Lay2Price
      : 0,

    back1Name2: data.data.GetProvider[0].GetBetsApi[0]?.Back1Price
      ? data.data.GetProvider[0].GetBetsApi[0].Back1Price
      : 0,
    back2Name2: data.data.GetProvider[0].GetBetsApi[0]?.Back2Price
      ? data.data.GetProvider[0].GetBetsApi[0].Back2Price
      : 0,
    lay1Name2: data.data.GetProvider[0].GetBetsApi[0]?.Lay1Price
      ? data.data.GetProvider[0].GetBetsApi[0].Lay1Price
      : 0,
    lay2Name2: data.data.GetProvider[0].GetBetsApi[0]?.Lay2Price
      ? data.data.GetProvider[0].GetBetsApi[0].Lay2Price
      : 0,
  });

  const [fixtureId, setFixtureId] = useState("");
  const back1Name1Ref = React.useRef();
  const back1Name2Ref = React.useRef();
  const [matchDtls, setMatchDtls] = useState({
    Team: "",
    ID: 0,
    Outcome: "",
    MatchName: "",
    oddVal: 0,
    Status: 1,
    fixtureId: data.data.FixtureId,
  });
  let [insiderActivity, setInsiderActivity] = useState("d-none");
  let [cricketActivity, setCricketActivity] = useState("active");
  const [accordianSize1, setAccordianSize1] = useState("col-md-3");
  const [accordianSize3, setAccordianSize3] = useState("col-md-6");
const [bgWhiteAccordianCustom,setBgWhiteAccordianCustom] = useState("")
  const [accordianSize2, setAccordianSize2] = useState("col-md-6");
  const [col, setCol] = useState("col-md-12 col-12");
  const [dsiplay, setDisplay] = useState("col-3 d-none ");
  const fetchBetsQuery = gql`
  subscription fetchBets {
    BetsApi(where:  {FixtureId: {_eq : "${data.data.FixtureId}"},Status: { _eq: 1 }, Name: {_in: ["1","2"]}}, order_by: {LastUpdate: desc}, limit: 1) {
      Back1Price
      Back1PriceVolume
      Back2Price
      Back2PriceVolume
      Id
      Index
      LastUpdate
      Lay1Price
      Lay1PriceVolume
      Lay2Price
      Lay2PriceVolume
      LayPrice
      LayPriceVolume
      Name
      Price
      PriceVolume
      ProviderId
      StartPrice
      Status
    }
  }
  `;
  let {
    data: betsData,
    error: betsError,
    loading: betsLoading,
  } = useSubscription(fetchBetsQuery);
  // let [bets, setBets] = useState(betsData ? betsData.BetsApi : "");
   console.log(betsData ? betsData : betsError);

  const AccordianFunc = (fixId) => {
    if (accordianStatus === "Close") {
      setAccordianStatus("Open");
      setFixtureId(fixId);
    } else if (accordianStatus === "Open") {
      setAccordianStatus("Close");
    }
  };

  //   if(betsData != undefined){
  //     if(betsData.BetsApi.length != 0){
  //         var newBetsApi = [...teams.GetProvider[0].GetBetsApi]
  //         var betsUpdate = newBetsApi.find(a => a.Name == betsData.BetsApi[0].Name)
  //         betsUpdate = betsData.BetsApi[0]
  //         setTeams(newBetsApi)
  //     const newTeams = teams.GetProvider[0].GetBetsApi.map(function(item,i){
  //         console.log(item,)
  //         var newBetsApi = [...teams.GetProvider[0].GetBetsApi]
  //         if(item.Name == betsData.BetsApi[0].Name){
  //             var newTeams = teams
  //             newTeams.GetProvider[0].GetBetsApi[i] = betsData.BetsApi[0]
  //            return {
  //             ...teams,

  //            }
  //         }
  //     })

  //     setTeams(newTeams)
  // }

  //   }

  useEffect(() => {
    if (betsData == undefined) {
      data.data.GetProvider[0].GetBetsApi.map((bets) => {
        if (bets.Name == 1) {
          setBackAndLay({
            ...backAndLay,
            back1Name1: bets.Back1Price,
            back2Name1: bets.Back2Price,
            lay1Name1: bets.Lay1Price,
            lay2Name1: bets.Lay2Price,
          });
        } else if (bets.Name == 2) {
          setBackAndLay({
            ...backAndLay,
            back1Name2: bets.Back1Price,
            back2Name2: bets.Back2Price,
            lay1Name2: bets.Lay1Price,
            lay2Name2: bets.Lay2Price,
          });
        }
      });
    }

    if (!betsLoading && betsData) {
       //console.log("Inside the if condition.");
      if (betsData.BetsApi[0]?.Name == 1) {
        if (betsData.BetsApi[0].Back1Price != backAndLay.back1Name1) {
          setBackAndLay({
            ...backAndLay,
            back1Name1: betsData.BetsApi[0].Back1Price,
          });
          fireEvent(fixtureId + "back1Name1");
        }
        if (betsData.BetsApi[0].Back2Price != backAndLay.back2Name1) {
          setBackAndLay({
            ...backAndLay,
            back2Name1: betsData.BetsApi[0].Back2Price,
          });
          fireEvent(fixtureId + "back2Name1");
        }
        if (betsData.BetsApi[0].Lay1Price != backAndLay.lay1Name1) {
          setBackAndLay({
            ...backAndLay,
            lay1Name1: betsData.BetsApi[0].Lay1Price,
          });
          fireEvent(fixtureId + "lay1Name1");
        }
        if (betsData.BetsApi[0].Lay2Price != backAndLay.lay2Name1) {
          setBackAndLay({
            ...backAndLay,
            lay2Name1: betsData.BetsApi[0].Lay2Price,
          });
          fireEvent(fixtureId + "lay2Name1");
        }
        // setBackAndLay({ ...backAndLay,
        //   back1Name1: betsData.BetsApi[0].Back1Price,
        //   back2Name1: betsData.BetsApi[0].Back2Price,
        //   lay1Name1: betsData.BetsApi[0].Lay1Price,
        //   lay2Name1: betsData.BetsApi[0].Lay2Price,

        // });
        // fireEvent(fixtureId + "back1Name1");

        // fireEvent(fixtureId + "back2Name1");

        // fireEvent(fixtureId + "lay1Name1");

        // fireEvent(fixtureId + "lay2Name1");
      } else if (betsData.BetsApi[0]?.Name == 2) {
        // setBackAndLay({
        //   ...backAndLay,
        //   back1Name2: betsData.BetsApi[0].Back1Price,
        //   back2Name2: betsData.BetsApi[0].Back2Price,
        //   lay1Name2: betsData.BetsApi[0].Lay1Price,
        //   lay2Name2: betsData.BetsApi[0].Lay2Price,
        // });
        // fireEvent(fixtureId + "back1Name2");
        // fireEvent(fixtureId + "back2Name2");
        // fireEvent(fixtureId + "lay1Name2");
        // fireEvent(fixtureId + "lay2Name2");

        if (betsData.BetsApi[0].Back1Price != backAndLay.back2Name1) {
          setBackAndLay({
            ...backAndLay,
            back1Name2: betsData.BetsApi[0].Back1Price,
          });
          fireEvent(fixtureId + "back1Name2");
        }
        if (betsData.BetsApi[0].Back2Price != backAndLay.back2Name2) {
          setBackAndLay({
            ...backAndLay,
            back2Name2: betsData.BetsApi[0].Back2Price,
          });
          fireEvent(fixtureId + "back2Name2");
        }
        if (betsData.BetsApi[0].Lay1Price != backAndLay.lay1Name2) {
          setBackAndLay({
            ...backAndLay,
            lay1Name2: betsData.BetsApi[0].Lay1Price,
          });
          fireEvent(fixtureId + "lay1Name2");
        }
        if (betsData.BetsApi[0].Lay2Price != backAndLay.lay2Name2) {
          setBackAndLay({
            ...backAndLay,
            lay2Name2: betsData.BetsApi[0].Lay2Price,
          });
          fireEvent(fixtureId + "lay2Name2");
        }

        // if (betsData.BetsApi[0].Back1Price != backAndLay.Back1Name2) {
        //   setBackAndLay({ ...backAndLay, back1Name2: betsData.BetsApi[0].Back1Price });
        //   fireEvent(fixtureId + "back1Name2");
        // }
        // if (betsData.BetsApi[0].Back2Price != backAndLay.Back2Name2) {
        //   setBackAndLay({ ...backAndLay, back2Name2: betsData.BetsApi[0].Back2Price });
        //   fireEvent(fixtureId + "back2Name2");
        // }
        // if (betsData.BetsApi[0].Lay1Price != backAndLay.Lay1Name2) {
        //   setBackAndLay({ ...backAndLay, lay1Name2: betsData.BetsApi[0].Lay1Price });
        //   fireEvent(fixtureId + "lay1Name2");
        // }
        // if (betsData.BetsApi[0].Lay2Price != backAndLay.Lay2Name2) {
        //   setBackAndLay({ ...backAndLay, lay2Name2: betsData.BetsApi[0].Lay2Price });
        //   fireEvent(fixtureId + "lay2Name2");
        // }
         //console.log(back1Name2Ref.current);
      }
       //console.log(backAndLay);
    }
  }, [betsData,betsLoading]);

  function fireEvent(elementId) {
    var link = document.getElementById(elementId).parentNode;
    link.classList.add("highlighter");
    setTimeout(function () {
      link.classList.remove("highlighter");
    }, 1000);
  }

  const myFunction = () => {
    setCol("col-md-8 col-12 mt-4 ");
    setDisplay("col-md-4 col-12 d-show animate__animated animate__fadeInRight");
  };

  return (
    <div className="row">
      <div className={col}>
        <div class="accordion" id="accordionPanelsStayOpenExample">
          <div class="accordion-item accordiannew">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
              <button
                class={`accordion-button collapsed  ${accordianStatus=="Open" ? accordianColor : ""}`}
                // data-bs-target={"#MATCH" + index}
                data-bs-target={`${
                  data.status == "Live" ? "#MatchLive" : "#MatchUpcoming"
                }${index}`}
                type="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseTwo"
                onClick={(e) => {
                  e.preventDefault();
                  AccordianFunc(teams.FixtureId);
                }}
              >
                <span>
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/1099/1099680.png"
                    width={20}
                  /></span>
                  <span>
                  {/* &nbsp; India vs Pakistan */}
                  {teams.GetParticipants[0].Name} vs{" "}
                  {teams.GetParticipants[1].Name}
                </span>
                <span
                  className={
                    status === "Live" ? "text-success" : "text-warning"
                  }
                >
                  {status}
                </span>
                <span class="accordian-date"><img src="/static/media/calender.868869cf25c4871101b631861b6d0ffb.svg"/> &nbsp;  {dateFormat(teams.Date," dd-mm-yyyy, h:MM TT")}</span>
              </button>
            </h2>
            <div
              // id={"MATCH" + index}
              id={`${
                data.status == "Live" ? "MatchLive" : "MatchUpcoming"
              }${index}`}
              class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingTwo"
            >
              <div class="accordion-body">
                <table class="table">
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col" className="col"></th>
                      <th scope="col" className="col-custom7">
                        Back
                       {/* <span data-toggle="tooltip" title="Back"><img className="question-icon" src={question_icon} /></span>  */}
                       <a id="not-clickable"><img className="question-icon" src={question_icon} /></a>
<Tooltip anchorSelect="#not-clickable"   place="right">
  <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying </p>
</Tooltip>
                      </th>
                      <th scope="col" className="col-custom7">
                        Lay
                       {/* <span data-toggle="tooltip" title="Back"><img className="question-icon" src={question_icon} /></span>  */}
 <a id="not-clickable"><img className="question-icon" src={question_icon} /></a>
<Tooltip anchorSelect="#not-clickable"   place="right">
  <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying </p>
</Tooltip>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="col-md-6">
                        <div class=" body-items1">
                          <NavLink
                            // activeClassName="active "
                            className={"activeTab"}
                            to={
                              matchName === "Cricket"
                                ? "/inside"
                                : matchName === "Football"
                                ? "/footballInside"
                                : matchName === "Tennis"
                                ? "/tennisInside"
                                : ""
                            }
                            // index={index}
                            state={{ teams: teams, index: index }}
                            // state="Testing data passing"
                            // to="/inside"
                            // onClick={(e) => {
                            //   e.preventDefault();
                            //   setCricketActivity("d-none");
                            //   setInsiderActivity("active");
                            // }}
                          >
                            {/* <img
                              src="https://icons.iconarchive.com/icons/custom-icon-design/flag-2/256/India-Flag-icon.png"
                              className="tableIcon"
                              alt="icon"
                            /> */}
                            <span>{teams.GetParticipants[0].Name}</span>
                          </NavLink>
                        </div>
                      </td>
                      <td className="col-md-3 ">
                        <div className={`row bgwhiteaccordian ${bgWhiteAccordianCustom} `}>
                          {teams.GetProvider[0].GetBetsApi.map((bets) => {
                            count2 = 0;
                            if (bets.Name === "1" && count === 0) {
                              count = 1;
                              return (
                                <>
                                  <div className="col-md-6 custom-betflip">
                                    <Link
                                      class="item body-items2 backColor"
                                      ref={back1Name1Ref}
                                    >
                                      <button
                                        className="collapsed btn-custom22"
                                        id={fixtureId + "back1Name1"}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setBgWhiteAccordianCustom("bgwhiteaccordian-betslip")
                                          data.betSlipIntro({
                                            Team: teams.GetParticipants[0].Name,
                                            MatchName: matchName,
                                            ID: Number(bets.Id),
                                            Outcome: "Back",
                                            oddVal: backAndLay.back1Name1,
                                            Status: teams.Status,
                                            FixtureId: data.data.FixtureId,
                                            Activity: "active",
                                            betType:"back1Name1"
                                          });
                                        }}
                                        disabled={
                                          backAndLay.back1Name1 == "undefined"
                                            ? true
                                            : false
                                        }
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                      >
                                        {backAndLay.back1Name1 == "undefined"
                                          ? "--"
                                          : backAndLay.back1Name1}
                                      </button>
                                    </Link>
                                  </div>

                                  <div className="col-md-6 d-none d-sm-block custom-betflip">
                                    <Link
                                      class="item body-items2 backColor"
                                      id="back2Name1"
                                    >
                                      <button
                                        className="collapsed btn-custom22"
                                        id={fixtureId + "back2Name1"}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setBgWhiteAccordianCustom("bgwhiteaccordian-betslip")

                                          data.betSlipIntro({
                                            Team: teams.GetParticipants[0].Name,
                                            MatchName: matchName,

                                            ID: Number(bets.Id),
                                            Outcome: "Back",
                                            oddVal: backAndLay.back2Name1,
                                            Status: teams.Status,
                                            FixtureId: data.data.FixtureId,
                                            Activity: "active",
                                            betType:"back2Name1"
                                          });
                                        }}
                                        disabled={
                                          backAndLay.back2Name1 == "undefined"
                                            ? true
                                            : false
                                        }
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                      >
                                        {backAndLay.back2Name1 == "undefined"
                                          ? "--"
                                          : backAndLay.back2Name1}
                                      </button>
                                    </Link>
                                  </div>
                                </>
                              );
                            }
                          })}
                        </div>
                      </td>

                      <td className="col-md-3 ">
                        <div className={`row bgwhiteaccordian ${bgWhiteAccordianCustom} `}>
                          {teams.GetProvider[0].GetBetsApi.map((bets) => {
                            count = 0;
                            if (bets.Name === "1" && count2 === 0) {
                              count2 = 1;
                              return (
                                <>
                                  <div className="col-md-6 custom-betflip">
                                    <Link
                                      class="item body-items2 layColor"
                                      id="lay1Name1"
                                    >
                                      <button
                                        className="collapsed btn-custom22"
                                        id={fixtureId + "lay1Name1"}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setBgWhiteAccordianCustom("bgwhiteaccordian-betslip")

                                          data.betSlipIntro({
                                            Team: teams.GetParticipants[0].Name,
                                            MatchName: matchName,

                                            ID: Number(bets.Id),
                                            Outcome: "Lay",
                                            oddVal: backAndLay.lay1Name1,
                                            Status: teams.Status,
                                            FixtureId: data.data.FixtureId,
                                            Activity: "active",
                                            betType:"lay1Name1"
                                          });
                                        }}
                                        disabled={
                                          backAndLay.lay1Name1 == "undefined"
                                            ? true
                                            : false
                                        }
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                      >
                                        {backAndLay.lay1Name1 == "undefined"
                                          ? "--"
                                          : backAndLay.lay1Name1}
                                      </button>
                                    </Link>
                                  </div>

                                  <div className="col-md-6 custom-betflip">
                                    <Link
                                      class="item body-items2 d-none d-sm-block layColor"
                                      id="lay2Name1"
                                    >
                                      <button
                                        className="collapsed btn-custom22"
                                        id={fixtureId + "lay2Name1"}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setBgWhiteAccordianCustom("bgwhiteaccordian-betslip")

                                          data.betSlipIntro({
                                            Team: teams.GetParticipants[0].Name,
                                            MatchName: matchName,

                                            ID: Number(bets.Id),
                                            Outcome: "Lay",
                                            oddVal: backAndLay.lay2Name1,
                                            Status: teams.Status,
                                            FixtureId: data.data.FixtureId,
                                            Activity: "active",
                                            betType:"lay2Name1"
                                          });
                                        }}
                                        disabled={
                                          backAndLay.lay2Name1 == "undefined"
                                            ? true
                                            : false
                                        }
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                      >
                                        {backAndLay.lay2Name1 == "undefined"
                                          ? "--"
                                          : backAndLay.lay2Name1}
                                      </button>
                                    </Link>
                                  </div>
                                </>
                              );
                            }
                          })}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td className="col-md-6">
                        <div class="body-items1">
                          <NavLink
                            // onClick={(e) => {
                            //   e.preventDefault();
                            //   setCricketActivity("d-none");
                            //   setInsiderActivity("active");
                            // }}
                            class="item"
                            to={
                              matchName === "Cricket"
                                ? "/inside"
                                : matchName === "Football"
                                ? "/footballInside"
                                : matchName === "Tennis"
                                ? "/tennisInside"
                                : ""
                            }
                            key={index}
                            state={{ teams: teams, index: index }}
                          >
                            {/* <img
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEUpZvlJjoEu6BGVowMRWvOvsusQ3o8ENj7Op9MQFSTbcwRIQ6pwy5K7qIJZYd-fSuJXI&usqp=CAU"
                              className="tableIcon"
                              alt="icon"
                            /> */}
                            <span>{teams.GetParticipants[1].Name}</span>
                          </NavLink>
                        </div>
                      </td>
                      <td className="col-md-3 ">
                        <div className={`row bgwhiteaccordian ${bgWhiteAccordianCustom} `}>
                          {teams.GetProvider[0].GetBetsApi.map((bets) => {
                            count2 = 0;
                            if (bets.Name === "1" && count === 0) {
                              count = 1;
                              return (
                                <>
                                  <div
                                    className="col-md-6 custom-betflip"
                                    id="back1Name2"
                                    ref={back1Name2Ref}
                                  >
                                    <Link class="item body-items2 backColor">
                                      <button
                                        className="collapsed btn-custom22"
                                        id={fixtureId + "back1Name2"}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setAccordianSize1("col-md-4");
                                          setAccordianSize2("col-md-6");
                                          setBgWhiteAccordianCustom("bgwhiteaccordian-betslip")

                                          data.betSlipIntro({
                                            Team: teams.GetParticipants[1].Name,
                                            MatchName: matchName,

                                            ID: Number(bets.Id),
                                            Outcome: "Back",
                                            oddVal: backAndLay.back1Name2,
                                            Status: teams.Status,
                                            FixtureId: data.data.FixtureId,
                                            Activity: "active",
                                            betType:"back1Name2"
                                          });
                                        }}
                                        disabled={
                                          backAndLay.back1Name2 == "undefined"
                                            ? true
                                            : false
                                        }
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                      >
                                        {backAndLay.back1Name2 == "undefined"
                                          ? "--"
                                          : backAndLay.back1Name2}
                                      </button>
                                    </Link>
                                  </div>

                                  <div className="col-md-6 custom-betflip">
                                    <Link
                                      class="item body-items2 d-none d-sm-block backColor"
                                      id="back2Name2"
                                    >
                                      <button
                                        className="collapsed btn-custom22"
                                        id={fixtureId + "back2Name2"}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setAccordianSize1("col-md-4");
                                          setAccordianSize2("col-md-6");
                                          setBgWhiteAccordianCustom("bgwhiteaccordian-betslip")

                                          data.betSlipIntro({
                                            Team: teams.GetParticipants[1].Name,
                                            MatchName: matchName,

                                            ID: Number(bets.Id),
                                            Outcome: "Back",
                                            oddVal: backAndLay.back2Name2,
                                            Status: teams.Status,
                                            FixtureId: data.data.FixtureId,
                                            Activity: "active",
                                            betType:"back2Name2"
                                          });
                                        }}
                                        disabled={
                                          backAndLay.back2Name2 == "undefined"
                                            ? true
                                            : false
                                        }
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                      >
                                        {backAndLay.back2Name2 == "undefined"
                                          ? "--"
                                          : backAndLay.back2Name2}
                                      </button>
                                    </Link>
                                  </div>
                                </>
                              );
                            }
                          })}
                        </div>
                      </td>
                      <td className="col-md-3 ">
                        <div className={`row bgwhiteaccordian ${bgWhiteAccordianCustom} `}>
                          {teams.GetProvider[0].GetBetsApi.map((bets) => {
                            count = 0;
                            if (bets.Name === "1" && count2 === 0) {
                              count2 = 1;
                              return (
                                <>
                                  <div className="col-md-6 custom-betflip">
                                    <Link
                                      class="item body-items2 layColor"
                                      id="lay1Name2"
                                    >
                                      <button
                                        className="collapsed btn-custom22"
                                        id={fixtureId + "lay1Name2"}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setBgWhiteAccordianCustom("bgwhiteaccordian-betslip")

                                          data.betSlipIntro({
                                            Team: teams.GetParticipants[1].Name,
                                            MatchName: matchName,

                                            ID: Number(bets.Id),
                                            Outcome: "Lay",
                                            oddVal: backAndLay.lay1Name2,
                                            Status: teams.Status,
                                            FixtureId: data.data.FixtureId,
                                            Activity: "active",
                                            betType:"lay1Name2"
                                          });
                                        }}
                                        disabled={
                                          backAndLay.lay1Name2 == "undefined"
                                            ? true
                                            : false
                                        }
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                      >
                                        {backAndLay.lay1Name2 == "undefined"
                                          ? "--"
                                          : backAndLay.lay1Name2}
                                      </button>
                                    </Link>
                                  </div>

                                  <div className="col-md-6 custom-betflip">
                                    <Link
                                      class="item body-items2 d-none d-sm-block layColor"
                                      id="lay2Name2"
                                    >
                                      <button
                                        className="collapsed btn-custom22"
                                        id={fixtureId + "lay2Name2"}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setAccordianSize1("col-md-2");
                                          setAccordianSize2("col-md-6");
                                          setBgWhiteAccordianCustom("bgwhiteaccordian-betslip")

                                          data.betSlipIntro({
                                            Team: teams.GetParticipants[1].Name,
                                            MatchName: matchName,

                                            ID: Number(bets.Id),
                                            Outcome: "Lay",
                                            oddVal: backAndLay.lay2Name2,
                                            Status: teams.Status,
                                            FixtureId: data.data.FixtureId,
                                            Activity: "active",
                                            betType:"lay2Name2"
                                          });
                                        }}
                                        disabled={
                                          backAndLay.lay2Name2 == "undefined"
                                            ? true
                                            : false
                                        }
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                      >
                                        {backAndLay.lay2Name2 == "undefined"
                                          ? "--"
                                          : backAndLay.lay2Name2}
                                      </button>
                                    </Link>
                                  </div>
                                </>
                              );
                            }
                          })}
                        </div>
                      </td>
                    </tr>
                    {/* <tr>
                                  <td>
                                    <div class="body-items1">
                                      <Link to="/inside" class="item">
                                        <img
                                          src="https://cdn-icons-png.flaticon.com/512/263/263405.png"
                                          className="tableIcon"
                                          alt="icon"
                                        />
                                        <span>Draw</span>
                                      </Link>
                                    </div>
                                  </td>
                                  <td className="col-md-3">
                                    <div className="row">
                                      <div className="col-md-3 offset-md-2">
                                        <Link class="item body-items2">
                                          <span
                                            onClick={(e) => {
                                              e.preventDefault();
                                             tro();
                                            }}
                                            class="collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                          >
                                            50
                                          </span>
                                        </Link>
                                      </div>

                                      <div className="col-md-3">
                                        <Link class="item body-items2 d-none d-sm-block">
                                          <span
                                            onClick={(e) => {
                                              e.preventDefault();
                                             tro();
                                            }}
                                            class="collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                          >
                                            50
                                          </span>
                                        </Link>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="col-md-3">
                                    <div className="row">
                                      <div className="col-md-3 offset-md-2">
                                        <Link class="item body-items2">
                                          <span
                                            onClick={(e) => {
                                              e.preventDefault();
                                             tro();
                                            }}
                                            class="collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                          >
                                            50
                                          </span>
                                        </Link>
                                      </div>

                                      <div className="col-md-3">
                                        <Link class="item body-items2 d-none d-sm-block">
                                          <span
                                            onClick={(e) => {
                                              e.preventDefault();
                                             tro();
                                            }}
                                            class="collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                          >
                                            50
                                          </span>
                                        </Link>
                                      </div>
                                    </div>
                                  </td>
                                </tr> */}
                  </tbody>
                </table>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={dsiplay}>
        <Betslip outcome={matchDtls} />
      </div> */}
    </div>
  );
};

export default Accordian;
