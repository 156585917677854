import React from "react";
import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import Header2 from "../include/header2";
import LeftSideBar from "../include/LeftSideBar";
import Lfooter from "../Lfooter";
import Loader from "react-js-loader";
import dateFormat, { masks } from "dateformat";

export default function Profile_Loss() {
  const [transactionType, setTransactionType] = useState("Win");
  const [date, setDate] = useState({
    fromDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    ).toISOString(),
    // fromDate: "2023-01-02",
    toDate: new Date().toISOString(),
    numberOfData: 5,
  });
  const fetchSettledBetsQuery = gql`
  query FetchSettledBets {
    BetSlipDtls(order_by: {Date: desc},where: {Date: {_gte: "${date.fromDate}", _lte: "${date.toDate}"},Status: {_eq: "Settled"}, Result: {_eq: 1}}) {
      Odds
      Outcome
      ProfitLiability
      Amount
      BetsId
      Date
      Result
    }
  }
  `;
  const fetchSettledBetsLossQuery = gql`
  query FetchSettledBets {
    BetSlipDtls(order_by: {Date: desc},where: {Date: {_gte: "${date.fromDate}", _lte: "${date.toDate}"}, Status: {_eq: "Settled"}, Result: {_eq: 0}}) {
      Odds
      Outcome
      ProfitLiability
      Amount
      BetsId
      Date
      Result
    }
  }
  `;
  let {
    data: fetchDataLossData,
    loading: fetchDataLossLoading,
    error: fetchDataLossError,
  } = useQuery(fetchSettledBetsLossQuery);
  let { data, loading, error } = useQuery(fetchSettledBetsQuery);
  console.log(data ? data : error);
  if (loading || fetchDataLossLoading)
    return <img src="assets/img/logo/game.png" className="loaderimg" />;
  return (
    <div>
      {/* Header Section start */}
      <Header2 />
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-2">
            <LeftSideBar />
          </div>

          <div className="col-md-10 col-12">
            {/* <br></br>
          <br></br> */}

            <div class="body-middle10">
              {/* <!--Breadcumnd--> */}

              {/* <!--Breadcumnd--> */}

              {/* <!--profile-->  */}
              <div class="profile-section ">
                <div class="container p-0">
                  <div class="tab-content " id="pro-wrap">
                    {" "}
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                      <div class="profile-left-wrap">
                        <div class="form-box form-box-custom">
                          {/* <div class="form-head"></div> */}
                          <form action="#0">
                            <div className="row">
                              <div className="col-md-4">
                                <label
                                  for="exampleInputEmail1"
                                  className="mb-2 custom-label6"
                                >
                                  From
                                  <div class="form-grp">
                                    <input
                                      value={date.fromDate}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setDate({
                                          ...date,
                                          fromDate: e.target.value,
                                        });
                                      }}
                                      type="date"
                                    />
                                    <div class="left-icon"></div>
                                  </div>
                                </label>
                              </div>
                              <div className="col-md-4">
                                <label
                                  for="exampleInputEmail1"
                                  className="mb-2 custom-label6"
                                >
                                  To
                                  <div class="form-grp">
                                    <input
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setDate({
                                          ...date,
                                          toDate: e.target.value,
                                        });
                                      }}
                                      value={date.toDate}
                                      type="date"
                                    />
                                    <div class="left-icon"></div>
                                  </div>
                                </label>
                              </div>
                              <div className="col-md-4 ">
                                <label className="mb-2 custom-label6">
                                  Profit & Liability
                                  <div class="form-grp">
                                    <select
                                      class="form-control dropdownBtn2  mt-1 pl-1"
                                      value={transactionType}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setTransactionType(e.target.value);
                                      }}
                                      name="state"
                                      id="maxRows"
                                    >
                                      <option>Win</option>
                                      <option>Loss </option>
                                    </select>
                                    <div class="right-lock">
                                      <i class="fa-solid fa-chevron-down"></i>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div class="">
                      <div class="row justify-content-between">
                        {/* <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                        <div class="profile-left-wrap">
                          <div class="form-box form-box-custom">
                            <div class="form-head"></div>
                            <form action="#0">
                              <div className="row">
                                <div className="col-md-4 col-12">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2 custom-label6"
                                  >
                                    From
                                    <div class="form-grp">
                                      <input
                                       
                                        type="date"
                                      />
                                      <div class="left-icon">
                                        <i
                                          class="fa fa-calendar"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </div>
                                  </label>

                                </div>
                                <div className="col-md-4 col-12">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2 custom-label6"
                                  >
                                    To
                                    <div class="form-grp">
                                      <input
                                      
                                       
                                        type="date"

                                      />
                                      <div class="left-icon">
                                        <i
                                          class="fa fa-calendar"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </div>
                                  </label>

                                </div>
                                <div className="col-md-4 " >
                                  <label className="mb-2 custom-label6">
                                    Profit & Liability
                                    <div class="form-grp" >
                                      <select class="form-control form-control-custom5  mt-1 pl-1"  name="state" id="maxRows">
                                        <option>Win</option>
                                        <option>Loss </option>
                                
                                      </select>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div> */}
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                          <div class="profile-left-wrap profile-left-wrap5">
                            <div class="description-table">
                              <table>
                                <tbody>
                                  <tr>
                                    <th>S. no.</th>
                                    <th>Date</th>
                                    <th>Odds</th>
                                    <th>Bet Amount</th>
                                    <th>Win/Loss</th>

                                    <th>Profit & Liability</th>
                                  </tr>
                                </tbody>
                                <tbody>
                                  {transactionType == "Win" ? (
                                    data?.BetSlipDtls.length != 0 ? (
                                      data?.BetSlipDtls.map((bets, index) => (
                                        <tr class="tb2" key={index}>
                                          <td>
                                            <span class="text1">
                                              {" "}
                                              {index + 1}{" "}
                                            </span>
                                          </td>
                                          <td>
                                            <span>
                                              {dateFormat(
                                                bets.Date,
                                                " dd-mm-yyyy, h:MM TT"
                                              )}
                                            </span>
                                          </td>
                                          <td>
                                            <span> {bets.Odds} </span>
                                          </td>
                                          <td class="text1">
                                            <span> {bets.Amount} </span>{" "}
                                          </td>
                                          <td>
                                            <span class="text1">
                                              {" "}
                                              {bets.Result == 0
                                                ? "Loss"
                                                : bets.Result == 1
                                                ? "Win"
                                                : ""}{" "}
                                            </span>
                                          </td>
                                          <td>
                                            <span class="text1">
                                              {`${bets.ProfitLiability}`}
                                            </span>
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <>
                                        <tr>
                                          {" "}
                                          <td>
                                            <span class="text1"></span>
                                          </td>
                                          <td>
                                            <span class="text1"></span>
                                          </td>
                                          <td>
                                            <span class="text1"></span>
                                          </td>
                                          <td>
                                            <span class="text1">
                                              {" "}
                                              No Records Found
                                            </span>
                                          </td>
                                          <td>
                                            <span class="text1"></span>
                                          </td>
                                          <td>
                                            <span class="text1"></span>
                                          </td>
                                          
                                        </tr>
                                      </>
                                    )
                                  ) : transactionType == "Loss" ? (
                                    fetchDataLossData?.BetSlipDtls != 0 ? (
                                      fetchDataLossData?.BetSlipDtls.map(
                                        (bets, index) => (
                                          <tr class="tb2" key={index}>
                                            <td>
                                              <span class="text1">
                                                {" "}
                                                {index + 1}{" "}
                                              </span>
                                            </td>
                                            <td>
                                              <span>
                                                {dateFormat(
                                                  bets.Date,
                                                  " dd-mm-yyyy, h:MM TT"
                                                )}
                                              </span>
                                            </td>
                                            <td>
                                              <span> {bets.Odds} </span>
                                            </td>
                                            <td class="text1">
                                              <span> {bets.Amount} </span>{" "}
                                            </td>
                                            <td>
                                              <span class="text1">
                                                {" "}
                                                {bets.Result == 0
                                                  ? "Loss"
                                                  : bets.Result == 1
                                                  ? "Win"
                                                  : ""}{" "}
                                              </span>
                                            </td>
                                            <td>
                                              <span class="text1">
                                                {`-${bets.ProfitLiability}`}
                                              </span>
                                            </td>
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <>
                                        <tr>
                                          <td>
                                            <span class="text1"></span>
                                          </td>
                                          <td>
                                            <span class="text1"></span>
                                          </td>
                                          <td>
                                            <span class="text1"></span>
                                          </td>
                                          <td>
                                            <span class="text1">
                                              {" "}
                                              No Records Found
                                            </span>
                                          </td>
                                          <td>
                                            <span class="text1"></span>
                                          </td>
                                          <td>
                                            <span class="text1"></span>
                                          </td>
                                        </tr>
                                      </>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
