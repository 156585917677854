import { render } from '@testing-library/react';
import { useNavigate } from 'react-router-dom';
import React from 'react'
import Swal from "sweetalert2";

const LoginPopUp = () => {
    const navigate = useNavigate()
  render (
    
        Swal.fire({
        text : "Please Login",
        timer:2000,
      }).then(function(isConfirm){
        // if(isConfirm)
        // console.log("Testing navigate")
        // navigate('/')
        window.location.href = "/"
        // history.push("/")
      })
     
  )
}

export default LoginPopUp