import React, { useEffect } from "react";
import { Link, span, useLocation } from "react-router-dom";
import { useState } from "react";
import Betslip from "./Betslip";
import horse from "./horse.png";
import Header from "../include/header2";
import LeftSideBar from "../include/LeftSideBar";
import { Carousel } from "react-responsive-carousel";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { gql, useSubscription } from "@apollo/client";

import "animate.css";
const Inside = (data) => {
  let location = useLocation();
  console.log(location);
  let [teams, setTeams] = useState(location.state.teams);

  const index = location.state.index;
  const status = location.state.teams.status;
  let count = 0;
  let count2 = 0;
  let [accordianStatus, setAccordianStatus] = useState("Close");
  let [backAndLay, setBackAndLay] = useState({
    back1Name1: location.state.teams.GetProvider[0].GetBetsApi[0].Back1Price,
    back2Name1: location.state.teams.GetProvider[0].GetBetsApi[0].Back2Price,
    lay1Name1: location.state.teams.GetProvider[0].GetBetsApi[0].Lay1Price,
    lay2Name1: location.state.teams.GetProvider[0].GetBetsApi[0].Lay2Price,

    back1Name2: location.state.teams.GetProvider[0].GetBetsApi[1].Back1Price,
    back2Name2: location.state.teams.GetProvider[0].GetBetsApi[1].Back2Price,
    lay1Name2: location.state.teams.GetProvider[0].GetBetsApi[1].Lay1Price,
    lay2Name2: location.state.teams.GetProvider[0].GetBetsApi[1].Lay2Price,
  });

  const [fixtureId, setFixtureId] = useState("");
  const back1Name1Ref = React.useRef();
  const back1Name2Ref = React.useRef();

  const [matchDtls, setMatchDtls] = useState({
    Team1: "",
    Team: "",
    Outcome: "",
    MatchName: "",
    oddVal: 0,
    ID: undefined,
    Status: 0,
    fixtureId: undefined,
    Activity: "",
  });
  const [col, setCol] = useState("col-12 col-md-10 ");
  const [dsiplay, setDisplay] = useState("col-3 d-none ");
  const myFunction = () => {
    setCol("col-md-7 col-12");
    setDisplay("col-12 col-md-3 d-show animate__animated animate__fadeInRight");
  };
  const fetchBetsQuery = gql`
  subscription fetchBets {
    BetsApi(where:  {FixtureId: {_eq : "${fixtureId}"},Status: { _eq: 1 } }, order_by: {LastUpdate: desc}, limit: 1) {
      Back1Price
      Back1PriceVolume
      Back2Price
      Back2PriceVolume
      Id
      Index
      LastUpdate
      Lay1Price
      Lay1PriceVolume
      Lay2Price
      Lay2PriceVolume
      LayPrice
      LayPriceVolume
      Name
      Price
      PriceVolume
      ProviderId
      StartPrice
      Status
    }
  }
`;
  let {
    data: betsData,
    error: betsError,
    loading: betsLoading,
  } = useSubscription(fetchBetsQuery);
  let [bets, setBets] = useState(betsData ? betsData.BetsApi : "");
  console.log(betsData ? betsData : betsError);

  useEffect(() => {
    if (betsData == undefined) {
      location.state.teams.GetProvider[0].GetBetsApi.map((bets) => {
        if (bets.Name == 1) {
          setBackAndLay({
            ...backAndLay,
            back1Name1: bets.Back1Price,
            back2Name1: bets.Back2Price,
            lay1Name1: bets.Lay1Price,
            lay2Name1: bets.Lay2Price,
          });
        } else if (bets.Name == 2) {
          setBackAndLay({
            ...backAndLay,
            back1Name2: bets.Back1Price,
            back2Name2: bets.Back2Price,
            lay1Name2: bets.Lay1Price,
            lay2Name2: bets.Lay2Price,
          });
        }
      });
    }

    if (betsData != undefined && betsData.BetsApi.length != 0) {
      console.log("Inside the if condition.");
      if (betsData.BetsApi[0].Name == 1) {
        if (betsData.BetsApi[0].Back1Price != backAndLay.back1Name1) {
          setBackAndLay({
            ...backAndLay,
            back1Name1: betsData.BetsApi[0].Back1Price,
          });
          fireEvent(fixtureId + "back1Name1");
        }
        if (betsData.BetsApi[0].Back2Price != backAndLay.back2Name1) {
          setBackAndLay({
            ...backAndLay,
            back2Name1: betsData.BetsApi[0].Back2Price,
          });
          fireEvent(fixtureId + "back2Name1");
        }
        if (betsData.BetsApi[0].Lay1Price != backAndLay.lay1Name1) {
          setBackAndLay({
            ...backAndLay,
            lay1Name1: betsData.BetsApi[0].Lay1Price,
          });
          fireEvent(fixtureId + "lay1Name1");
        }
        if (betsData.BetsApi[0].Lay2Price != backAndLay.lay2Name1) {
          setBackAndLay({
            ...backAndLay,
            lay2Name1: betsData.BetsApi[0].Lay2Price,
          });
          fireEvent(fixtureId + "lay2Name1");
        }
        // setBackAndLay({ ...backAndLay,
        //   back1Name1: betsData.BetsApi[0].Back1Price,
        //   back2Name1: betsData.BetsApi[0].Back2Price,
        //   lay1Name1: betsData.BetsApi[0].Lay1Price,
        //   lay2Name1: betsData.BetsApi[0].Lay2Price,

        // });
        // fireEvent(fixtureId + "back1Name1");

        // fireEvent(fixtureId + "back2Name1");

        // fireEvent(fixtureId + "lay1Name1");

        // fireEvent(fixtureId + "lay2Name1");
      } else if (betsData.BetsApi[0].Name == 2) {
        // setBackAndLay({
        //   ...backAndLay,
        //   back1Name2: betsData.BetsApi[0].Back1Price,
        //   back2Name2: betsData.BetsApi[0].Back2Price,
        //   lay1Name2: betsData.BetsApi[0].Lay1Price,
        //   lay2Name2: betsData.BetsApi[0].Lay2Price,
        // });
        // fireEvent(fixtureId + "back1Name2");
        // fireEvent(fixtureId + "back2Name2");
        // fireEvent(fixtureId + "lay1Name2");
        // fireEvent(fixtureId + "lay2Name2");

        if (betsData.BetsApi[0].Back1Price != backAndLay.back1Name1) {
          setBackAndLay({
            ...backAndLay,
            back1Name2: betsData.BetsApi[0].Back1Price,
          });
          fireEvent(fixtureId + "back1Name2");
        }
        if (betsData.BetsApi[0].Back2Price != backAndLay.back2Name2) {
          setBackAndLay({
            ...backAndLay,
            back2Name2: betsData.BetsApi[0].Back2Price,
          });
          fireEvent(fixtureId + "back2Name2");
        }
        if (betsData.BetsApi[0].Lay1Price != backAndLay.lay1Name2) {
          setBackAndLay({
            ...backAndLay,
            lay1Name2: betsData.BetsApi[0].Lay1Price,
          });
          fireEvent(fixtureId + "lay1Name2");
        }
        if (betsData.BetsApi[0].Lay2Price != backAndLay.lay2Name2) {
          setBackAndLay({
            ...backAndLay,
            lay2Name2: betsData.BetsApi[0].Lay2Price,
          });
          fireEvent(fixtureId + "lay2Name2");
        }

        // if (betsData.BetsApi[0].Back1Price != backAndLay.Back1Name2) {
        //   setBackAndLay({ ...backAndLay, back1Name2: betsData.BetsApi[0].Back1Price });
        //   fireEvent(fixtureId + "back1Name2");
        // }
        // if (betsData.BetsApi[0].Back2Price != backAndLay.Back2Name2) {
        //   setBackAndLay({ ...backAndLay, back2Name2: betsData.BetsApi[0].Back2Price });
        //   fireEvent(fixtureId + "back2Name2");
        // }
        // if (betsData.BetsApi[0].Lay1Price != backAndLay.Lay1Name2) {
        //   setBackAndLay({ ...backAndLay, lay1Name2: betsData.BetsApi[0].Lay1Price });
        //   fireEvent(fixtureId + "lay1Name2");
        // }
        // if (betsData.BetsApi[0].Lay2Price != backAndLay.Lay2Name2) {
        //   setBackAndLay({ ...backAndLay, lay2Name2: betsData.BetsApi[0].Lay2Price });
        //   fireEvent(fixtureId + "lay2Name2");
        // }
        console.log(back1Name2Ref.current);
      }
      console.log(backAndLay);
    }
  }, [betsData]);
  const AccordianFunc = (fixId) => {
    if (accordianStatus === "Close") {
      setAccordianStatus("Open");
      setFixtureId(fixId);
    } else if (accordianStatus === "Open") {
      setAccordianStatus("Close");
    }
  };

  function fireEvent(elementId) {
    var link = document.getElementById(elementId).parentNode;
    link.classList.add("highlighter");
    setTimeout(function () {
      link.classList.remove("highlighter");
    }, 1000);
  }
  return (
    <div>
      {/* Header Section start */}
      <Header />
      <div className="row">
        <div className="col-2">
          <LeftSideBar />
        </div>

        <div className={col}>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <div className="row corerow">
            <div className="col">
              <div className="cardcore">
                <h6>Pakistan</h6>
                <p> CRR 3.28</p>{" "}
              </div>
            </div>
            <div className="col">
              <div className="cardcore textcenter">
                <h6>148/45 : 449 All Out</h6>
                <p>Pakistan are 154 for 3 after 47.0 overs.</p>
              </div>
            </div>
            <div className="col textend">
              <div className="cardcore">
                <h6 className="text-right">India</h6>
                <p> RR.135</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-12">
              <img
                src="https://freedesignfile.com/upload/2017/08/Soccer-scoreboard-template-vectors-03.jpg"
                className="img-fluid"
              />
            </div>
            <div className="col-md-6 col-12">
              <div className="row">
                <br></br>
                <div className="scorecardlive">
                  <p className="text-center">Matches </p>
                  <br></br>
                  <br></br>
                  <ul class="steps">
                    <li class="step step--incomplete step--active">
                      <span class="count">1 % </span>
                    </li>
                    <li class="step step--incomplete step--inactive">
                      <p class="count">1st Serve Pts. Won </p>
                    </li>
                    <li class="step step--incomplete step--inactive">
                      <span class="count">3.2 % </span>
                    </li>
                  </ul>
                  <br></br>{" "}
                  <ul class="steps">
                    <li class="step step--incomplete step--active">
                      <span class="count">1.85 % </span>
                    </li>
                    <li class="step step--incomplete step--inactive">
                      <p class="count">2nd Serve Pts. Won </p>
                    </li>
                    <li class="step step--incomplete step--inactive">
                      <span class="count">1.58 % </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion" id="accordionPanelsStayOpenExample">
            <div class="accordion-item border-0">
              <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                <button
                  class="accordion-button collapsed"
                  data-bs-target={"#MATCH" + index}
                  type="button"
                  data-bs-toggle="collapse"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  <span>
                    {/* <img
            src="https://cdn-icons-png.flaticon.com/512/1099/1099680.png"
            width={20}
          /> */}
                    {/* &nbsp; India vs Pakistan */}
                    &nbsp; {teams.GetParticipants[0].Name} vs{" "}
                    {teams.GetParticipants[1].Name}
                  </span>
                  <span
                    className={
                      status === "Live" ? "text-success" : "text-warning"
                    }
                  >
                    {status}
                  </span>
                </button>
              </h2>
              <div
                id={"MATCH" + index}
                class="collapse show"
                aria-labelledby="panelsStayOpen-headingTwo"
              >
                <div class="accordion-body">
                  <table class="table">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col"></th>
                        <th scope="col" className="col-custom7">
                          Back
                        </th>
                        <th scope="col" className="col-custom7">
                          Lay
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="col-md-6">
                          <div class="body-items1">
                            <span
                              // activeClassName="active "
                              className={"activeTab"}
                              key={index}
                              state={{ data }}
                              //
                              // onClick={(e) => {
                              //   e.preventDefault();
                              //   setCricketActivity("d-none");
                              //   setInsiderActivity("active");
                              // }}
                            >
                              {/* <img
                                src="https://icons.iconarchive.com/icons/custom-icon-design/flag-2/256/India-Flag-icon.png"
                                className="tableIcon"
                                alt="icon"
                              /> */}
                              <span>{teams.GetParticipants[0].Name}</span>
                            </span>
                          </div>
                        </td>
                        <td className="col-md-3">
                          <div className="row">
                            {teams.GetProvider[0].GetBetsApi.map((bets) => {
                              count2 = 0;
                              if (bets.Name === "1" && count === 0) {
                                count = 1;
                                return (
                                  <>
                                    <div className="col-md-3 offset-md-2">
                                      <Link
                                        class="item body-items2"
                                        ref={back1Name1Ref}
                                      >
                                        <span
                                          id={fixtureId + "back1Name1"}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setMatchDtls({
                                              ...matchDtls,
                                              Team1:
                                                teams.GetParticipants[0].Name,
                                              Team: teams.GetParticipants[0].Name,
                                              MatchName: "Cricket",
                                              Outcome: "Back",
                                              oddVal: backAndLay.back1Name1,
                                              Status: teams.Status,
                                              fixtureId: teams.FixtureId,
                                              Activity: "active",
                                              ID: Number(bets.Id),
                                            });
                                            myFunction();
                                          }}
                                          disabled={
                                            backAndLay.back1Name1 == "undefined"
                                              ? true
                                              : false
                                          }
                                          class="collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseThree"
                                          aria-expanded="false"
                                          aria-controls="collapseThree"
                                        >
                                          {backAndLay.back1Name1 == "undefined"
                                            ? "--"
                                            : backAndLay.back1Name1}
                                        </span>
                                      </Link>
                                    </div>

                                    <div className="col-md-3 d-none d-sm-block">
                                      <Link
                                        class="item body-items2"
                                        id="back2Name1"
                                      >
                                        <span
                                          id={fixtureId + "back2Name1"}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setMatchDtls({
                                              ...matchDtls,
                                              Team1:
                                                teams.GetParticipants[0].Name,
                                              Team: teams.GetParticipants[0].Name,
                                              MatchName: "Cricket",
                                              Outcome: "Back",
                                              oddVal: backAndLay.back2Name1,
                                              Status: teams.Status,
                                              fixtureId: teams.FixtureId,
                                              Activity: "active",
                                              ID: Number(bets.Id),
                                            });
                                            myFunction();
                                          }}
                                          disabled={
                                            backAndLay.back2Name1 == "undefined"
                                              ? true
                                              : false
                                          }
                                          class="collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseThree"
                                          aria-expanded="false"
                                          aria-controls="collapseThree"
                                        >
                                          {backAndLay.back2Name1 == "undefined"
                                            ? "--"
                                            : backAndLay.back2Name1}{" "}
                                        </span>
                                      </Link>
                                    </div>
                                  </>
                                );
                              }
                            })}
                          </div>
                        </td>

                        <td className="col-md-3">
                          <div className="row">
                            {teams.GetProvider[0].GetBetsApi.map((bets) => {
                              count = 0;
                              if (bets.Name === "1" && count2 === 0) {
                                count2 = 1;
                                return (
                                  <>
                                    <div className="col-md-3 offset-md-2">
                                      <Link
                                        class="item body-items2"
                                        id="lay1Name1"
                                      >
                                        <span
                                          id={fixtureId + "lay1Name1"}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setMatchDtls({
                                              ...matchDtls,
                                              Team1:
                                                teams.GetParticipants[0].Name,
                                              Team: teams.GetParticipants[0].Name,
                                              MatchName: "Cricket",
                                              Outcome: "Lay",
                                              oddVal: backAndLay.lay1Name1,
                                              Status: teams.Status,
                                              fixtureId: teams.FixtureId,
                                              Activity: "active",
                                              ID: Number(bets.Id),
                                            });
                                            myFunction();
                                          }}
                                          disabled={
                                            backAndLay.lay1Name1 == "undefined"
                                              ? true
                                              : false
                                          }
                                          class="collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseThree"
                                          aria-expanded="false"
                                          aria-controls="collapseThree"
                                        >
                                          {backAndLay.lay1Name1 == "undefined"
                                            ? "--"
                                            : backAndLay.lay1Name1}{" "}
                                        </span>
                                      </Link>
                                    </div>

                                    <div className="col-md-3">
                                      <Link
                                        class="item body-items2 d-none d-sm-block"
                                        id="lay2Name1"
                                      >
                                        <span
                                          id={fixtureId + "lay2Name1"}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setMatchDtls({
                                              ...matchDtls,
                                              Team1:
                                                teams.GetParticipants[0].Name,
                                              Team: teams.GetParticipants[0].Name,
                                              MatchName: "Cricket",
                                              Outcome: "Lay",
                                              oddVal: backAndLay.lay2Name1,
                                              Status: teams.Status,
                                              fixtureId: teams.FixtureId,
                                              Activity: "active",
                                              ID: Number(bets.Id),
                                            });
                                            myFunction();
                                          }}
                                          disabled={
                                            backAndLay.lay2Name1 == "undefined"
                                              ? true
                                              : false
                                          }
                                          class="collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseThree"
                                          aria-expanded="false"
                                          aria-controls="collapseThree"
                                        >
                                          {backAndLay.lay2Name1 == "undefined"
                                            ? "--"
                                            : backAndLay.lay2Name1}{" "}
                                        </span>
                                      </Link>
                                    </div>
                                  </>
                                );
                              }
                            })}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="col-md-6">
                          <div class="body-items1">
                            <span
                              // onClick={(e) => {
                              //   e.preventDefault();
                              //   setCricketActivity("d-none");
                              //   setInsiderActivity("active");
                              // }}
                              class="item"
                              key={index}
                              state={{ data }}
                            >
                              {/* <img
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEUpZvlJjoEu6BGVowMRWvOvsusQ3o8ENj7Op9MQFSTbcwRIQ6pwy5K7qIJZYd-fSuJXI&usqp=CAU"
                                className="tableIcon"
                                alt="icon"
                              /> */}
                              <span>{teams.GetParticipants[1].Name}</span>
                            </span>
                          </div>
                        </td>
                        <td className="col-md-3 offset-md-2">
                          <div className="row">
                            {teams.GetProvider[0].GetBetsApi.map((bets) => {
                              count2 = 0;
                              if (bets.Name === "1" && count === 0) {
                                count = 1;
                                return (
                                  <>
                                    <div
                                      className="col-md-3 offset-md-2"
                                      id="back1Name2"
                                      ref={back1Name2Ref}
                                    >
                                      <Link class="item body-items2">
                                        <span
                                          id={fixtureId + "back1Name2"}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setMatchDtls({
                                              ...matchDtls,
                                              Team: teams.GetParticipants[1].Name,
                                              Team1:
                                                teams.GetParticipants[1].Name,
                                              MatchName: "Cricket",
                                              Outcome: "Back",
                                              oddVal: backAndLay.back1Name2,
                                              Status: teams.Status,
                                              fixtureId: teams.FixtureId,
                                              Activity: "active",
                                              ID: Number(bets.Id),
                                            });
                                            myFunction();
                                          }}
                                          disabled={
                                            backAndLay.back1Name2 == "undefined"
                                              ? true
                                              : false
                                          }
                                          class="collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseThree"
                                          aria-expanded="false"
                                          aria-controls="collapseThree"
                                        >
                                          {backAndLay.back1Name2 == "undefined"
                                            ? "--"
                                            : backAndLay.back1Name2}{" "}
                                        </span>
                                      </Link>
                                    </div>

                                    <div className="col-md-3">
                                      <Link
                                        class="item body-items2 d-none d-sm-block"
                                        id="back2Name2"
                                      >
                                        <span
                                          id={fixtureId + "back2Name2"}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setMatchDtls({
                                              ...matchDtls,
                                              Team: teams.GetParticipants[1].Name,
                                              Team1:
                                                teams.GetParticipants[1].Name,
                                              MatchName: "Cricket",
                                              Outcome: "Back",
                                              oddVal: backAndLay.back2Name2,
                                              Status: teams.Status,
                                              fixtureId: teams.FixtureId,
                                              Activity: "active",
                                              ID: Number(bets.Id),
                                            });
                                            myFunction();
                                          }}
                                          disabled={
                                            backAndLay.back2Name2 == "undefined"
                                              ? true
                                              : false
                                          }
                                          class="collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseThree"
                                          aria-expanded="false"
                                          aria-controls="collapseThree"
                                        >
                                          {backAndLay.back2Name2 == "undefined"
                                            ? "--"
                                            : backAndLay.back2Name2}{" "}
                                        </span>
                                      </Link>
                                    </div>
                                  </>
                                );
                              }
                            })}
                          </div>
                        </td>
                        <td className="col-md-3">
                          <div className="row">
                            {teams.GetProvider[0].GetBetsApi.map((bets) => {
                              count = 0;
                              if (bets.Name === "1" && count2 === 0) {
                                count2 = 1;
                                return (
                                  <>
                                    <div className="col-md-3 offset-md-2">
                                      <Link
                                        class="item body-items2"
                                        id="lay1Name2"
                                      >
                                        <span
                                          id={fixtureId + "lay1Name2"}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setMatchDtls({
                                              ...matchDtls,
                                              Team: teams.GetParticipants[1].Name,
                                              Team1:
                                                teams.GetParticipants[1].Name,
                                              MatchName: "Cricket",
                                              Outcome: "Back",
                                              oddVal: backAndLay.lay1Name2,
                                              Status: teams.Status,
                                              fixtureId: teams.FixtureId,
                                              Activity: "active",
                                              ID: Number(bets.Id),
                                            });
                                            myFunction();
                                          }}
                                          disabled={
                                            backAndLay.lay1Name2 == "undefined"
                                              ? true
                                              : false
                                          }
                                          class="collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseThree"
                                          aria-expanded="false"
                                          aria-controls="collapseThree"
                                        >
                                          {backAndLay.lay1Name2 == "undefined"
                                            ? "--"
                                            : backAndLay.lay1Name2}{" "}
                                        </span>
                                      </Link>
                                    </div>

                                    <div className="col-md-3">
                                      <Link
                                        class="item body-items2 d-none d-sm-block"
                                        id="lay2Name2"
                                      >
                                        <span
                                          id={fixtureId + "lay2Name2"}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setMatchDtls({
                                              ...matchDtls,
                                              Team: teams.GetParticipants[1].Name,
                                              Team1:
                                                teams.GetParticipants[1].Name,
                                              MatchName: "Cricket",
                                              Outcome: "Back",
                                              oddVal: backAndLay.lay2Name2,
                                              Status: teams.Status,
                                              fixtureId: teams.FixtureId,
                                              Activity: "active",
                                              ID: Number(bets.Id),
                                            });
                                            myFunction();
                                          }}
                                          disabled={
                                            backAndLay.lay2Name2 == "undefined"
                                              ? true
                                              : false
                                          }
                                          class="collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseThree"
                                          aria-expanded="false"
                                          aria-controls="collapseThree"
                                        >
                                          {backAndLay.lay2Name2 == "undefined"
                                            ? "--"
                                            : backAndLay.lay2Name2}{" "}
                                        </span>
                                      </Link>
                                    </div>
                                  </>
                                );
                              }
                            })}
                          </div>
                        </td>
                      </tr>
                      {/* <tr>
                                <td>
                                  <div class="body-items1">
                                    <Link class="item">
                                      <img
                                        src="https://cdn-icons-png.flaticon.com/512/263/263405.png"
                                        className="tableIcon"
                                        alt="icon"
                                      />
                                      <span>Draw</span>
                                    </Link>
                                  </div>
                                </td>
                                <td className="col-md-3">
                                  <div className="row">
                                    <div className="col-md-3 offset-md-2">
                                      <Link class="item body-items2">
                                        <span
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setMatchDtls({
                                              ...matchDtls,
                                              Team1: "India",
                                              Team2: "Pakistan",
                                              MatchName: "Cricket",
                                              Outcome: "Back",
                                              oddVal: 50,
                                                Status: teams.Status,
        fixtureId:teams.FixtureId
                                            });
                                            myFunction();
                                          }}
                                          class="collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseThree"
                                          aria-expanded="false"
                                          aria-controls="collapseThree"
                                        >
                                          50
                                        </span>
                                      </Link>
                                    </div>

                                    <div className="col-md-3">
                                      <Link class="item body-items2 d-none d-sm-block">
                                        <span
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setMatchDtls({
                                              ...matchDtls,
                                              Team1: "India",
                                              Team2: "Pakistan",
                                              MatchName: "Cricket",
                                              Outcome: "Back",
                                              oddVal: 50,
                                                Status: teams.Status,
        fixtureId:teams.FixtureId
                                            });
                                            myFunction();
                                          }}
                                          class="collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseThree"
                                          aria-expanded="false"
                                          aria-controls="collapseThree"
                                        >
                                          50
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                </td>
                                <td className="col-md-3">
                                  <div className="row">
                                    <div className="col-md-3 offset-md-2">
                                      <Link class="item body-items2">
                                        <span
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setMatchDtls({
                                              ...matchDtls,
                                              Team1: "India",
                                              Team2: "Pakistan",
                                              MatchName: "Cricket",
                                              Outcome: "Back",
                                              oddVal: 50,
                                                Status: teams.Status,
        fixtureId:teams.FixtureId
                                            });
                                            myFunction();
                                          }}
                                          class="collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseThree"
                                          aria-expanded="false"
                                          aria-controls="collapseThree"
                                        >
                                          50
                                        </span>
                                      </Link>
                                    </div>

                                    <div className="col-md-3">
                                      <Link class="item body-items2 d-none d-sm-block">
                                        <span
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setMatchDtls({
                                              ...matchDtls,
                                              Team1: "India",
                                              Team2: "Pakistan",
                                              MatchName: "Cricket",
                                              Outcome: "Back",
                                              oddVal: 50,
                                                Status: teams.Status,
        fixtureId:teams.FixtureId
                                            });
                                            myFunction();
                                          }}
                                          class="collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseThree"
                                          aria-expanded="false"
                                          aria-controls="collapseThree"
                                        >
                                          50
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                </td>
                              </tr> */}
                    </tbody>
                  </table>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={dsiplay}>
          <Betslip outcome={matchDtls} />
        </div>
      </div>

      {/* Header Section End */}
      {/* Starting of game header */}
    </div>
  );
};

export default Inside;
