import React from "react";
import { BrowserRouter } from "react-router-dom";
import MainRoute from "./Main Route/MainRoute";

// require('dotenv').config()
export default function App() {
  return (
    <>
      <MainRoute />
    </>
  );
}
