import React from "react";
import { Outlet, Link } from "react-router-dom";
import dateFormat, { masks } from "dateformat";

// import login from './Assets/Img/login.png';
import login from '../Assets/Img/login.svg';
import signup from '../Assets/Img/SignUp.svg';
import calender from '../Assets/Img/calender.svg';
export default function header() {
  return (
    <>
      {/* <div className="boxes-bg">
  <div className="boxes">
    <div className="box">
      <div />
      <div />
      <div />
      <div />
    </div>
    <div className="box">
      <div />
      <div />
      <div />
      <div />
    </div>
    <div className="box">
      <div />
      <div />
      <div />
      <div />
    </div>
    <div className="box">
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
</div> */}
      <header class="header-section header-hidden">
        <div className="container">
          <div className="row">
            <div className="col-2">
              <div class="header-wrapper ">
                <div class="logo-menu">
                  <Link to="/" class="logo">
                    <img src="assets/img/logo/Big_logo.png" alt="logo" />
                  </Link>
                  <Link to="/" class="dark-logo">
                    <img src="assets/img/logo/Big_logo.png" alt="logo" />{" "}
                  </Link>
                </div>
              </div>
              {/* <img src="assets/img/logo/refresh_icon.png" className="float1"/> */}
            </div>
          <div className="col-6 custom-date2">
              {/* <div class="ticker-wrap">
                <div class="ticker">
                  <div class="ticker__item">
                    <span className="item-collection-1">
                    Players bet on their predicted outcomes and all stakes go into a single pool.    </span>
                  </div>
                </div>
              </div> */}
              <p className="custom-date"><img src={calender}/> {dateFormat(new Date(),"dd-mm-yyyy")} |<span className="time-custom"> {dateFormat(new Date(),"h-mm TT")} </span></p>

            </div>
            <div className="col-4">
              <div className="right-menu-reature right-menu-reature-custom" >
                <div class="signup-area justify-content-end">
                {/* <button id="hide" className='getstarted' data-toggle="modal" data-target="#loginModal">login</button>
                  <button id="hide" className='getstarted' data-toggle="modal" data-target="#signup">soign</button> */}
<a href="#" data-toggle="modal" className="login-btncustom"  data-target="#loginModal"><img src={login}/></a>
<a  href="#"  data-toggle="modal" data-target="#signup" className="login-btncustom2"><img src={signup}/></a>
                  {/* <button
                    class="cmn--btn yellowcmn"
                    id="hide"
                    data-toggle="modal"
                    data-target="#loginModal"
                  >
                    <span>Log In</span>
                    <img src={login}/>
                  </button> */}
                  {/* <button
                    class="cmn--btn"
                    id="hide"
                    data-toggle="modal"
                    data-target="#signup"
                  >
                    <span>Sign Up</span>
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="header-wrapper ">
          <div class="menu-logo-adjust d-flex">
            <div class="logo-menu me-5">
              <Link to="/" class="logo">
                <img src="assets/img/logo/logo_white.png" alt="logo" />
              </Link>
              <Link to="/" class="dark-logo">
                <img src="assets/img/logo/logo_white.png" alt="logo" />{" "}
              </Link>
            </div>
            <div class="ticker-wrap">
              <div class="ticker">
                <div class="ticker__item">
                  <span className="item-collection-1">
                    We're providing here the best offer for you
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="right-menu-reature right-menu-reature-custom">
            <div class="input-box">
              <i class="uil uil-search"></i>
              <input type="text" placeholder="Search here..." />
              <button class="button">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
            </div>
            <div class="signup-area justify-content-end">
              <Link
                to="#0"
                class="btn--two btn--two-custom"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <span>Log In</span>
              </Link>
              <Link
                to="#0"
                class="cmn--btn"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal2"
              >
                <span>Sign Up</span>
              </Link>
            </div>
            <div className="profilebox ">
              <span>
                {" "}
                <div class="language profilebox1">
                  <div class="nice-select" tabindex="0">
                    <span class="current"> </span>
                    <ul class="list">
                      <li data-value="3" class="option">
                        <Link
                          to="#0"
                          class=""
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal2"
                        >
                          {" "}
                          <span>
                            <i
                              class="fa-solid fa-sack-dollar"
                              style={{
                                "font-size": "20px",
                                "margin-right": "3px",
                              }}
                            ></i>{" "}
                            Sign Up
                          </span>
                        </Link>
                      </li>
                      <li data-value="3" class="option">
                        <Link
                          to="#0"
                          class=""
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          {" "}
                          <span>
                            <i
                              class="fa-solid fa-sack-dollar"
                              style={{
                                "font-size": "20px",
                                "margin-right": "3px",
                              }}
                            ></i>{" "}
                            Log In
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div> */}
      </header>
      <a
        href="https://api.whatsapp.com/send?phone=0000&text=Hii"
        class="float"
        target="_blank"
      >
        <i class="fa fa-whatsapp my-float"></i>
      </a>
    </>
  );
}