import React from "react";
// import Header from './include/header'
// import Footer from './include/Footer'
import { BrowserRouter, HashRouter, Routes, Route, Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import LeftSideBar from './include/LeftSideBar'
import Inside from ".././Dashbaord/Inside";
// import GuestHome from "../Dashbaord/GuestHome";
import Home from ".././Dashbaord/Home";
import Cricket from ".././Dashbaord/Cricket";
import Results from "../Dashbaord/Result";
import LiveCasino from ".././Dashbaord/LiveCasino";
import SlotGames from ".././Dashbaord/SlotGames";
import Football from ".././Dashbaord/Football";
import Profile from ".././include/Profile";
import TennisInside from ".././Dashbaord/TennisInside";
import Tennis from ".././Dashbaord/Tennis";

// const Cricket = React.lazy(() => import('./Dashbaord/C../ricket'))
import FootballInside from ".././Dashbaord/FootballInside";
import PageNotFound from ".././PageNotFound";
import User_Profile from ".././Dashbaord/User_Profile";
import Setting from ".././Dashbaord/Setting";
// import Deposite_cash from ".././Dashbaord/Deposite_cash";
import Withdraw from ".././Dashbaord/Withdraw";
import Transaction from ".././Dashbaord/Transaction";
import Wallet from ".././Dashbaord/Wallet";
import Deposite_now from ".././Dashbaord/Deposite_now";
import Profile_Loss from ".././Dashbaord/PL";
import Bets from ".././Dashbaord/Bets";
import SweetAlert2 from "../Dashbaord/Sweet-Alert";
import Swal from "sweetalert2";

import { LoginAuth } from "../Authentication/Authentication";
import { useState, useEffect } from "react";

import {
  ApolloProvider,
  ApolloClient,
  useQuery,
  HttpLink,
  split,
  InMemoryCache,
} from "@apollo/client";
import jwt_decode from "jwt-decode";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getMainDefinition } from "@apollo/client/utilities";

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { ForgotPassword } from "../Dashbaord/forgotPassword";
import LoginPopUp from "../include/LoginPopUp";
export default function MainRoute() {
  var accessToken = localStorage.getItem("accessToken");

  if (localStorage.getItem("accessToken")) {
    var decoded = jwt_decode(localStorage.getItem("accessToken"));
  }


  if(localStorage.getItem("accessToken")){
    var decoded = jwt_decode(localStorage.getItem("accessToken"));
    setInterval(function () {
      if(decoded){
        if(decoded.exp < Math.floor(Date.now() / 1000)){
          
          sessionStorage.clear();
          localStorage.clear();
          // window.location.reload(false);
          window.location.href = "/"
        }
      }
     
    }, 1000);
}
const DBURLHTTPS = process.env.REACT_APP_DBURLHTTPS
const DBURLWSS = process.env.REACT_APP_DBURLWSS
console.log(DBURLWSS);
  // const navigate = useNavigate()
  const httpLink = new HttpLink({
    // uri: DBURLHTTPS,
    uri : "https://h-aura.bigbetplay.com/v1/graphql",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
  const wsLink = new GraphQLWsLink(
    createClient({
      // url: DBURLWSS,
      url:"wss://h-aura.bigbetplay.com/v1/graphql",
      connectionParams: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        // headers: {
        //   "x-hasura-admin-secret": `NewSecure555@123`,
        // },
      },
    })
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );
  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(true),
  });

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkUserToken = () => {
    const from = localStorage.getItem("from");
    // console.log(decoded);
    // const loading = localStorage.getItem("loading");
    // console.log("Insise Auth JS")
    if (accessToken) {
      setIsLoggedIn(true);
     
      // from ? navigate(from) : navigate(`/cricket`);

      // navigate(`/cricket`);
    } else {
     
      setIsLoggedIn(false);
      

    }
  };

  useEffect(() => {
    setTimeout(()=>{
      accessToken = localStorage.getItem("accessToken");

    },3000)

    // checkUserToken();
  //   const interval = setInterval(() => {
  //     if (decoded.exp * 1000 < Date.now()) {
  //       localStorage.clear("accessToken");
  //       sessionStorage.clear()
  //       setIsLoggedIn(false);
  //       // window.location.href = "/";
  //     } else {
  //       // window.location.href = "/";
  //       setIsLoggedIn(true);
  //     }
  //   }, 1000);
  //   return () => clearInterval(interval);
  
  }, [accessToken]);

  console.log(isLoggedIn);
 
  return (
    <>
      <HashRouter>
        <ApolloProvider client={client}>
          {/* <Routes>
            <Route path="/" element={<Home />} />


            </Routes> */}
          {accessToken ? (
            
            <Routes>
              <Route path="*" component={PageNotFound} />

              {/* <Route path="/"> */}
              <Route path="/" element={<Home />} />
              <Route path="/11" element={<SweetAlert2 />} />
              <Route path="/cricket" element={<Cricket />} />
              <Route path="/results" element={<Results />} />
              <Route path="/inside" element={<Inside />} />
              <Route path="/liveCasino" element={<LiveCasino />} />
              <Route path="/slotGames" element={<SlotGames />} />
              {/* <Route path="/cardGames" element={<CardGames />} /> */}
              <Route path="/tennis" element={<Tennis />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/footballInside" element={<FootballInside />} />
              <Route path="/football" element={<Football />} />
              <Route path="/tennisInside" element={<TennisInside />} />
              <Route path="/User_Profile" element={<User_Profile />} />
              <Route path="/Setting" element={<Setting />} />
              <Route path="/Deposite_now" element={<Deposite_now />} />
              <Route path="/Withdraw" element={<Withdraw />} />
              <Route path="/Transaction" element={<Transaction />} />
              <Route path="/Wallet" element={<Wallet />} />
              <Route path="/PL" element={<Profile_Loss />} />
              <Route path="/Bets" element={<Bets />} />

              {/* </Route> */}
            </Routes>
          ) :
           (
            <Routes>
              <Route path="/" element={<Home />} />
              {/* <Route path="*" element={<Home/>} /> */}
              <Route path="*" element={<LoginPopUp/>} />

              <Route path="/forgotPassword" element={<ForgotPassword/>} />





              {/* <Route path="/cricket" element={<Cricket />} /> */}
            </Routes>
          )}
        </ApolloProvider>
      </HashRouter>
    </>
  );
}
