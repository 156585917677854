import React from 'react'
import Swal from 'sweetalert2'
import { useState } from 'react'
function SweetAlert2(props) {
	const fireAlert = () => {

		Swal.fire({
			title: props.name,
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: "OK",
			cancelButtonText: "Cancel",
			icon: 'warning'
		}
		)
	}
    fireAlert()
	return (
	<>
    </>
	)
}
export default SweetAlert2;


