import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
export const ForgotPassword = () => {
  const [otp, setOtp] = useState(undefined);
  const [phone, setPhone] = useState(undefined);
  const [password, setPassword] = useState("");
  const [cnfmPassword, setCnfmPassword] = useState("");
  const [custerror, setCusterror] = useState(undefined);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const navigate= useNavigate()
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };
  const fireAlert = (title, Result) => {
    Swal.fire({
      title: title,
      showConfirmButton: true,
      // showCancelButton: true,
      confirmButtonText: "OK",
      // cancelButtonText: "Cancel",
      icon: Result,
    }).then(function (isConfirm) {
      if (isConfirm) {
        window.location.reload(true);
      }
    });
  };
  const updatePassword = async (e) => {
    e.preventDefault();
    alert(otp);
    if (password === cnfmPassword) {
      const res = await axios.post(
        `${process.env.REACT_APP_BaseUrl}/forgetPassword`,
        { otp, phone, password }
      );
      if (res.status === 200) {
        console.log(res);
        Swal.fire({
          title: res.data.message,
          showConfirmButton: true,
          // showCancelButton: true,
          confirmButtonText: "OK",
          // cancelButtonText: "Cancel",
          icon: "success",
        }).then(function (isConfirm) {
          if (isConfirm) {
            window.location.href = "/";
          }
        });
        // fireAlert(res.data, "success");
      } else if (res.status === 202) {
        Swal.fire({
          title: res.data,
          showConfirmButton: true,
          // showCancelButton: true,
          confirmButtonText: "OK",
          // cancelButtonText: "Cancel",
          icon: "error",
        }).then(function (isConfirm) {
          if (isConfirm) {
            window.location.href = "/";
          }
        });
        // fireAlert(res.data, "error");
      }
    } else {
      setCusterror("Please check password and confirm password");
    }
  };
  return (
    <>
      <div className="forgotcustomform">
        <div className="modal-dialog  modal-dialog-centered modal-dialog-centered5" role="document">
          {/* <button
        
            type="button"
            className="btn-close cross-btn"
            data-dismiss="modal"
            aria-label="Close"
          ></button> */}
          <div className="modal-content forgotpassword">
            {/* <div class="modal-header">
              <div class="head">
                <img
                  src="assets/img/logo/logo_white.png"
                  className="forgot-pass-logo"
                />

                <h5 className="mt-4">Forgot Password</h5>
              </div>
            </div> */}

            <div class="modal-body">
              <div class="register-from">
                <form action="#0" onSubmit={updatePassword}>
                  <div class="items">
                    <label className="mb-1"> Phone Number</label>

                    <div class="form-input">
                      <input
                        type="text"
                        required
                        onChange={(e) => {
                          e.preventDefault();
                          setPhone(e.target.value);
                        }}
                        id="phone"
                        placeholder="9087****90"
                        autocomplete="off"
                      />
                    </div>
                    {/* <div className='otp-position'>
                    <button className='otp-get'>send otp</button>
                    </div> */}
                    <div class="signUpNew_inputContainer_otp">
                      <button
                        className="signUpNew_inputContainer_otp-button"
                        disabled
                      >
                        {" "}
                        Get OTP
                      </button>
                    </div>
                  </div>
                  {/* </form> */}
                  {/* <form onSubmit={updatePassword}> */}
                  <div class="items">
                    <label className="mb-1">OTP</label>

                    <div class="form-input">
                      <input
                        type="number"
                        placeholder="OTP"
                        required
                        onChange={(e) => {
                          e.preventDefault();
                          setOtp(e.target.value);
                        }}
                        id="otp"
                      />
                    </div>
                  </div>
                  <div class="items">
                    <label className="mb-1">Password</label>

                    <div class="form-input">
                      <input
                        type={passwordType}
                        required
                        onChange={(e) => {
                          e.preventDefault();
                          setPassword(e.target.value);
                        }}
                        id="email33"
                        placeholder="****"
                      />
                      <span
                        onClick={togglePassword}
                        id="#password-field"
                        // class="fa fa-fw fa-eye field-icon toggle-password2 eyeVisibility"
                        className="eyeVisibility"

                        // className={passwordType==="password" ? "fas fa-eye-slash" : "fas fa-eye"}
                      >
                        {passwordType === "password" ? (
                          <i className="fas fa-eye-slash"></i>
                        ) : (
                          <i className="fas fa-eye"></i>
                        )}
                      </span>
                    </div>
                  </div>
                  <div class="items">
                    <label className="mb-2">Confirm Password</label>

                    <div class="form-input">
                      <div class="form-group">
                        <input
                          id="password-field2"
                          required
                          onChange={(e) => {
                            e.preventDefault();
                            setCnfmPassword(e.target.value);
                          }}
                          type={confirmPasswordType}
                          class="form-control"
                          placeholder="****"
                          name="password"
                        />
                        <span
                          id="#password-field2"
                          onClick={toggleConfirmPassword}
                          className="eyeVisibility"

                          // class="fa fa-fw fa-eye field-icon toggle-password2 eyeVisibility"
                        >
                          {confirmPasswordType === "password" ? (
                            <i className="fas fa-eye-slash"></i>
                          ) : (
                            <i className="fas fa-eye"></i>
                          )}
                        </span>
                        <p id="required-custom">{custerror}</p>
                      </div>
                    </div>
                  </div>
                  {/* <div class="items">
                  <label className="mb-2" >Referel Code</label>

                    <div class="form-input">
                      <input type="text" id="refCode"
                      onChange={(e) => {
                        e.preventDefault();
                        setSignUpDtls({
                          ...signUpDtls,
                          RefferelCode: e.target.value,
                        });
                      }}
                      placeholder="Agent123" />
                    </div>
                  </div> */}
                  {/* <br></br> */}
                  <div class="items items-custom">
                    <div className="row">
                      
                      <div className="col-6 ml-2">
                        <button
                          onClick={(e) => {
                            window.location.href = "/";
                          }}
                          class="cmn--btn cd-popup-close repopup"
                        >
                          <span>Back</span>
                        </button>
                      </div>
                      <div className="col-6 ml-2  ">
                        <button
                          type="submit"
                          class="cmn--btn cd-popup-close repopup"
                        >
                          <span>Update</span>
                        </button>
                      </div>
                    </div>
                    {/* <br></br> */}
                    {/* <button
                          type="submit"
                          class="cmn--btn cd-popup-close repopup"
                        >
                          <span>Update Password</span>
                        </button> */}
                  </div>
                  {/* <div class="orbar">
                    <span>OR</span>
                  </div> */}
                  {/* <br></br> */}
                  {/* <div class="items text-center">
                    <button
                      onClick={e=>{navigate("/")}}
                      type="submit"
                      class="cmn--btn cd-popup-close repopup"
                    >
                      <span>Back</span>
                    </button>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
