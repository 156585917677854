import React, { useState, useEffect, useRef } from "react";
import Header from "../include/header";
import Header2 from "../include/header2";
import Loader from "react-js-loader";
import { Carousel } from "react-responsive-carousel";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { gql, useQuery } from "@apollo/client";
import { useNavigate, useLocation, Outlet, Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import SweetAlert2 from "./Sweet-Alert";
import Net from "./neet.jpg";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import crictnew from "../Assets/Img/crickernew.svg";
import football from "../Assets/Img/football.svg";
import tennis from "../Assets/Img/tennis.svg";
import basket from "../Assets/Img/Basket.svg";
import cashino from "../Assets/Img/casino.svg";
import balling from "../Assets/Img/balling.svg";

import Bannernew from "../Assets/Img/banner2.png";
import mobile_banner from "../Assets/Img/mobile_banner.png";
import Awesome_Games from "../Assets/Img/Awesome_Games.png";
import Higher_chance from "../Assets/Img/Higher_chance.png";
import Invest from "../Assets/Img/Invest.png";
import Secure_Games from "../Assets/Img/Secure_Games.png";
import circleimg from "../Assets/Img/circle.svg";
import how_play_games from "../Assets/Img/how_play_games.png";
import BottomNavigation from "reactjs-bottom-navigation";
import "reactjs-bottom-navigation/dist/index.css";
// import Logo from '.'
export default function Home() {
  const bottomNavItems = [
    {
      icon: <i class="icon-home"></i>,
      activeIcon: <i class="icon-home"></i>,
      route: "/",
    },
    {
      icon: <i class="icon-cricket"></i>,
      activeIcon: <i class="icon-cricket"></i>,
      route: "/cricket",
    },
    {
      icon: <i class="icon-futsal"></i>,
      activeIcon: <i class="icon-futsal"></i>,
      route: "/football",
    },
    {
      icon: <i class="icon-etennis"></i>,
      activeIcon: <i class="icon-etennis"></i>,
      route: "/tennis",
    },
    {
      icon: <i class="icon-etennis"></i>,
      activeIcon: <i class="icon-etennis"></i>,
      route: "/tennis",
    },
  ];

  // const fetchslidermain_banner1 = gql`
  //   query fetchImage {
  //     SliderImages(where: { GameType: { _eq: "Home" } }) {
  //       ImageName
  //       URL
  //     }
  //   }
  // `;
  // let {
  //   data: fetchSliderData,
  //   error: fetchSliderError,
  //   loading: fetchSliderLoading,
  // } = useQuery(fetchslidermain_banner1);
  const imgurl = process.env.REACT_APP_BaseUrl;
  var resUserName = "";
  // const main_banner1 = `${imgurl}${fetchSliderData?.SliderImages[0].URL}`
  // const main_banner2 = `${imgurl}${fetchSliderData?.SliderImages[1].URL}`
  // console.log(main_banner1);
  localStorage.setItem("loading", true);
  const modalRef = useRef();
  const loginCloseRef = React.useRef(null);
  const signUpCloseRef = React.useRef(null);
  const loginRef = React.useRef(null);
  const signUpRef = React.useRef(null);
  var accessToken = localStorage.getItem("accessToken");
  if (localStorage.getItem("accessToken")) {
    var decoded = jwt_decode(localStorage.getItem("accessToken"));
  }
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.pathname;
  const [dummyAccessToken, setDummyAccessToken] = useState("");
  const [modalActive, setModalActive] = useState(false);
  const [modalActiveSignUp, setModalActiveSignUp] = useState(false);

  const [loginModal, setLoginModal] = useState("");
  const [signUpModal, setSignUpModal] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [mainBanner1, setMainBanner1] = useState("");
  const [mainBanner2, setMainBanner2] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [custerror, setCusterror] = useState("");
  const [imgURL, setImgURL] = useState("");

  const [credentials, setCredentials] = useState({
    user: "",
    pwd: "",
    status: "user",
  });
  const [signUpDtls, setSignUpDtls] = useState({
    userName: "",
    email: "",
    password: "",
    confirmPwd: "",
    phone: "",
    Status: "",
    UplineRefId: "",
    AcntType: "User",
  });
  const [passwordLoginType, setPasswordLoginType] = useState("password");

  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [walletBal, setWalletBal] = useState(0);

  const fetchWalletBalQuery = gql`
    {
      Wallet {
        UserName
        Amount
        Points
      }
    }
  `;

  let { loading, error, data } = useQuery(fetchWalletBalQuery);
  console.log(data ? data : error);
  // useEffect(()=>{
  //   if(dummyAccessToken!==""){
  //   localStorage.setItem("accessToken", accessToken);
  //   }
  // },[dummyAccessToken])
  // useEffect(() => {
  //   if (!loading && data) {
  //     setWalletBal(data.Wallet[0].Amount);
  //     toast.success(`Welcome Back ${decoded.name}`, {
  //       position: "top-center",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //   }
  // }, [loading, data]);

  //To handel password visibility
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };
  const toggleLoginPassword = () => {
    if (passwordLoginType === "password") {
      setPasswordLoginType("text");
      return;
    }
    setPasswordLoginType("password");
  };

  //To handle all the new signups
  const handleSignup = async (e) => {
    // e.preventDefault()
    // alert("Testing login");
    const SignupUrl = "/signUp";
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BaseUrl}${SignupUrl}`,
        signUpDtls
      );
      // const accessToken = response?.data?.accessToken;
      if (response.status === 200) {
        accessToken = response?.data?.accessToken;
        if (accessToken == undefined) {
        } else {
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("from", from);
          var decoded = jwt_decode(accessToken);
          console.log(decoded);

          setIsLoading(false);
          Swal.fire({
            title: `Welcome to a new world of betting ${signUpDtls.userName}`,
            confirmButtonText: "OK",
            icon: "success",
          }).then(function (isConfirm) {
            if (isConfirm) {
              navigate("/");
              // window.location.reload();
            }
          });
          // $("#exampleModal2").modal("hide");
          // navigate(`/cricket`);
          // window.location.href = "/cricket";
          // window.location.reload();
        }
      } else {
        Swal.fire({
          title: response.data,
          confirmButtonText: "OK",
          icon: "error",
        }).then(function (isConfirm) {
          if (isConfirm) {
            // Window.location.reload(true);
            navigate("/");
          }
        });
      }

      // if (accessToken) {
      //   localStorage.setItem("isLoggedIn", true);
      //   localStorage.setItem("from", from);
      //   navigate("/");
      // } else {
      //   alert("login testing");
      // }
    } catch (err) {
      console.log(err);
    }
  };
  const handleClose = () => {
    modalRef.current.hide();
  };

  //To handel all the logins
  const handleLogin = async (e) => {
    // const imgurl = "https://backend-bb.katsamsoft.com/";
    setIsLoading(true);

    e.preventDefault();
    // alert("Testing login");
    // const LoginUrl = "https://backend-bb.katsamsoft.com/login";
    const LoginUrl = "/login";
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BaseUrl}${LoginUrl}`,
        credentials
      );
      console.log(response);
      if (response.status === 200) {
        resUserName = response.data.userName;
        setIsLoading(false);
        // let element = document.querySelector(".modal-backdrop");
        // element.classList.replace("show", "new-class-name");
        window.location.reload(true);
      } else if (response.status == 201) {
        console.log(response);
        setIsLoading(false);
        Swal.fire({
          title: response.data,
          confirmButtonText: "OK",
          icon: "error",
        }).then(function (isConfirm) {
          if (isConfirm) {
            window.location.reload(true);
            // navigate("/")
          }
        });
      } else if (response.status === 202) {
        console.log(response);
        setIsLoading(false);
        Swal.fire({
          title: response.data,
          confirmButtonText: "OK",
          icon: "error",
        }).then(function (isConfirm) {
          if (isConfirm) {
            window.location.reload(true);
            // navigate("/")
          }
        });
      }
      accessToken = response?.data?.accessToken;
      console.log(accessToken);
      if (accessToken == undefined) {
      } else {
        // setTimeout(() => {
        setDummyAccessToken(accessToken);
        localStorage.setItem("accessToken", accessToken);

        // }, 3000);
        // localStorage.setItem("from", from);
        // var decoded = jwt_decode(accessToken);
        // console.log(decoded);

        setIsLoggedIn(true);

        // window.location.reload();
        //  navigate("/")
      }
    } catch (err) {
      console.log(err);
    }
  };
  const checkUserToken = () => {
    // const from = localStorage.setItem("from", from);

    if (accessToken) {
      // console.log(accessToken);
      setIsLoggedIn(true);
      // from ? navigate(from) : navigate(`/cricket`);
      // navigate("/")
    } else {
      setIsLoggedIn(false);
      // Swal.fire({
      //   text: "Please Login",
      //   timer: 2000,
      // });
      // navigate("/");
    }
  };
  const fetchImage = async () => {
    const res = await axios.post(
      `${process.env.REACT_APP_BaseUrl}/fetchImageUrl`,
      { gameType: "Home" },
      { mode: "cors" }
    );
    console.log(`${process.env.REACT_APP_LiveURL}${res.data[0].URL}`);
    setMainBanner1(`${process.env.REACT_APP_LiveURL}${res.data[0].URL}`);
    setMainBanner2(`${process.env.REACT_APP_LiveURL}${res.data[1].URL}`);
    setIsLoading(false);
  };
  // useEffect(()=>{

  // },[isLoggedIn])
  useEffect(() => {
    // setIsLoading(true);
    checkUserToken();

    fetchImage();
  }, [isLoggedIn]);

  if (isLoading || loading)
    return <img src="assets/img/logo/game.png" className="loaderimg" />;

  return (
    <>
      {/* (isLoggedIn ? <Header2 /> :<Header />) */}

      {accessToken ? (
        <>
          <Header2 token={data?.Wallet[0]?.Amount} />{" "}
        </>
      ) : (
        <Header />
      )}
      <ToastContainer />

      <div class="body-middle body-middle1 mobile-none">
        <OwlCarousel
          loop={true}
          className="owl-theme"
          items={1}
          autoplayTimeout={3000}
          margin={8}
          autoplay={1000}
          navigation={true}
          nav={true}
          dots={true}
        >
          <div class="item p-10">
            {/* <img
                     src="https://backend-bb.katsamsoft.com/img/HomeSlider1.jpg"
                className="img-fluid"
              /> */}

            <img src={Bannernew} className="img-fluid" />
          </div>{" "}
          {/* <div class="item">
              <img src={mainBanner2} className="img-fluid" />
            </div> */}
        </OwlCarousel>
      </div>

      <div class="body-middle body-middle1 d-md-none d-sm-block ">
        <OwlCarousel
          loop={true}
          className="owl-theme"
          items={1}
          autoplayTimeout={3000}
          margin={8}
          autoplay={1000}
          navigation={true}
          nav={true}
          dots={true}
        >
          <div class="item p-10">
            {/* <img
                     src="https://backend-bb.katsamsoft.com/img/HomeSlider1.jpg"
                className="img-fluid"
              /> */}

            <img src={mobile_banner} className="img-fluid" />
          </div>{" "}
          {/* <div class="item">
              <img src={mainBanner2} className="img-fluid" />
            </div> */}
        </OwlCarousel>
      </div>
      {/* custom box  */}
      <div class="banner-feature">
        <div class="container-fluid">
          <div class="banner-feature-wrapper">
            {/* <a href="live.html" class="banner-feature-items">
                <span class="banner-feauter-thumb">
                  <img src="assets/img/banner-freature/live.svg" alt="feature" />
                </span>
                <span class="banner-feature-contentt">Live</span>
              </a> */}
            {/* <a href="#" class="banner-feature-items">
                <span class="banner-feauter-thumb">
                  <img
                    src="assets/img/banner-freature/parcents.svg"
                    alt="feature"
                  />
                </span>
                <span class="banner-feature-contentt">Margin</span>
              </a> */}
            <Link
              // to={
              //   isLoggedIn
              //     ? "/cricket"
              //     : Swal.fire({
              //         text: `Please login to view conte nt.`,
              //         timer: 2000,
              //       })
              // }
              to="/cricket"
              class="banner-feature-items"
            >
              <span class="banner-feauter-thumb">
                <img src={crictnew} alt="feature" />
              </span>
              <span class="banner-feature-contentt">Cricket</span>
            </Link>
            <Link
              // to={
              //   isLoggedIn
              //     ? "/football"
              //     : Swal.fire({
              //         text: `Please login to view content.`,
              //         timer: 2000,
              //       })
              // }
              to="/football"
              class="banner-feature-items"
            >
              <span class="banner-feauter-thumb">
                <img src={football} alt="feature" />
              </span>
              <span class="banner-feature-contentt">Football</span>
            </Link>
            {/* <Link to={isLoggedIn ? "/tennis" :  Swal.fire({
    text: `Please login to view content.`,
    timer:2000
  })} class="banner-feature-items">
                <span class="banner-feauter-thumb">
                  <img src="assets/img/banner-freature/table.svg" alt="feature" />
                </span>
                <span class="banner-feature-contentt">Tennis</span>
              </Link> */}
            <Link
              // to={
              //   isLoggedIn
              //     ? "/tennis"
              //     : Swal.fire({
              //         text: `Please login to view content.`,
              //         timer: 2000,
              //       })
              // }
              to="/tennis"
              class="banner-feature-items"
            >
              <span class="banner-feauter-thumb">
                <img src={tennis} alt="feature" />
              </span>
              <span class="banner-feature-contentt">Tennis</span>
            </Link>

            <Link
              // to={
              //   isLoggedIn
              //     ? "/cricket"
              //     : Swal.fire({
              //         text: `Please login to view content.`,
              //         timer: 2000,
              //       })
              // }
              to="/cricket"
              class="banner-feature-items"
            >
              <span class="banner-feauter-thumb">
                <img src={balling} alt="feature" />
              </span>
              <span class="banner-feature-contentt">Bowling</span>
            </Link>
            <Link
              // to={
              //   isLoggedIn
              //     ? "/football"
              //     : Swal.fire({
              //         text: `Please login to view content.`,
              //         timer: 2000,
              //       })
              // }
              to="/football"
              class="banner-feature-items"
            >
              <span class="banner-feauter-thumb">
                <img src={cashino} alt="feature" />
              </span>
              <span class="banner-feature-contentt">Casino</span>
            </Link>
            {/* <Link to={isLoggedIn ? "/tennis" :  Swal.fire({
    text: `Please login to view content.`,
    timer:2000
  })} class="banner-feature-items">
                <span class="banner-feauter-thumb">
                  <img src="assets/img/banner-freature/table.svg" alt="feature" />
                </span>
                <span class="banner-feature-contentt">Tennis</span>
              </Link> */}
            <Link
              // to={
              //   isLoggedIn
              //     ? "/tennis"
              //     : Swal.fire({
              //         text: `Please login to view content.`,
              //         timer: 2000,
              //       })
              // }
              to="/tennis"
              class="banner-feature-items"
            >
              <span class="banner-feauter-thumb">
                <img src={basket} alt="feature" />
              </span>
              <span class="banner-feature-contentt">Basketball</span>
            </Link>
          </div>
        </div>
      </div>

      <section class="more-features">
        <div class="overlay pt-70 pb-70">
          <div class="container containerhalf">
            <div class="row align-items-center">
              <div class="col-lg-5">
                <br></br> <br></br>
                <div class="section-header">
                  <h4 class="title">
                    Why Play On <br></br>Big Bet
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vivamus magna odio, varius quis purus vel, rhoncus facilisis
                    augue. Curabitur commodo interdum libero in sagittis.<p></p>{" "}
                    Curabitur dictum libero non tortor vestibulum posuere
                    malesuada dapibus ex. Etiam non augue ut augue cursus
                    gravida
                  </p>
                </div>
              </div>

              <div className="col-lg-1"></div>
              <div className="col-lg-6">
                <div className="row">
                  <div class="col-12 col-md-6">
                    <div className="cardbox">
                      <img src={Secure_Games} />
                      <h3>Secure Games </h3>
                      <p>
                        Games available in most casinos are commonly called
                        casino games. In a casino game. you will found options.{" "}
                      </p>
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <div className="cardbox">
                      <img src={Awesome_Games} />
                      <h3>Awesome Game State </h3>
                      <p>
                        Games available in most casinos are commonly called
                        casino games. In a casino game. you will found options.{" "}
                      </p>
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <div className="cardbox">
                      <img src={Secure_Games} />
                      <h3>Awesome Game State </h3>
                      <p>
                        Games available in most casinos are commonly called
                        casino games. In a casino game. you will found options.{" "}
                      </p>
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <div className="cardbox">
                      <img src={Invest} />
                      <h3>Higher Wining Chance </h3>
                      <p>
                        Games available in most casinos are commonly called
                        casino games. In a casino game. you will found options.{" "}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-md-6"></div>
                </div>
                <div className="col-12"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* custom box  */}

      <section className="overlay-custom">
        <div className="container">
          <div className="row">
            <div className="centersection">
              <h3>How to Play Games </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                <br></br> magna odio, varius quis purus vel.
              </p>
            </div>
            <img src={how_play_games} />
          </div>
        </div>
      </section>
      <section class="faqs-section">
        <div class="code-custom5">
          <div class="container">
            {/* <div class="row d-flex justify-content-center">
                <div class="col-lg-7">
                  <div class="section-header text-center">
                    <h2 class="title">300% BONUS ON YOUR FIRST DEPOSIT.</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                      do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                    <br></br>
                    <br></br>
                    <a href="#" class="btn">
                      Explore
                
                    </a>
                  </div>
                </div>
              </div> */}
            <div class="row faq-bg d-flex justify-content-center">
              <h3>
                Frequently Asked<br></br> Questions{" "}
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus{" "}
                <br></br>magna odio, varius quis purus vel.{" "}
              </p>
              <div class="col-xl-6 col-12 col-md-6">
                <div class="faq-box mt-60 mb-50">
                  <div class="accordion" id="accordionFaqs">
                    <div class="accordion-item">
                      <h5 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-button accordion-button-custom collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          How fast do I get paid once I win a bet?
                        </button>
                      </h5>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionFaqs"
                      >
                        <div class="accordion-body">
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h5 class="accordion-header" id="headingTwo">
                        <button
                          class="accordion-button accordion-button-custom collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          How fast do I get paid once I win a bet?
                        </button>
                      </h5>
                      <div
                        id="collapseTwo"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionFaqs"
                      >
                        <div class="accordion-body">
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h5 class="accordion-header" id="headingThree">
                        <button
                          class="accordion-button accordion-button-custom collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          What are the commissions I have to pay?
                        </button>
                      </h5>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionFaqs"
                      >
                        <div class="accordion-body">
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h5 class="accordion-header" id="headingFour">
                        <button
                          class="accordion-button accordion-button-custom collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Can I set the odds for any bet I want?
                        </button>
                      </h5>
                      <div
                        id="collapseFour"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionFaqs"
                      >
                        <div class="accordion-body">
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-6  col-12  col-md-6">
                <div class="faq-box mt-60 mb-50">
                  <div class="accordion" id="accordionFaqs">
                    <div class="accordion-item">
                      <h5 class="accordion-header" id="heading7">
                        <button
                          class="accordion-button accordion-button-custom collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse7"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          How fast do I get paid once I win a bet?
                        </button>
                      </h5>
                      <div
                        id="collapse7"
                        class="accordion-collapse collapse"
                        aria-labelledby="heading7"
                        data-bs-parent="#accordionFaqs"
                      >
                        <div class="accordion-body">
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h5 class="accordion-header" id="heading8">
                        <button
                          class="accordion-button accordion-button-custom collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse8"
                          aria-expanded="false"
                          aria-controls="collapse8"
                        >
                          What are the commissions I have to pay?
                        </button>
                      </h5>
                      <div
                        id="collapse8"
                        class="accordion-collapse collapse"
                        aria-labelledby="heading8"
                        data-bs-parent="#accordionFaqs"
                      >
                        <div class="accordion-body">
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h5 class="accordion-header" id="heading9">
                        <button
                          class="accordion-button accordion-button-custom collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse9"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Can I set the odds for any bet I want?
                        </button>
                      </h5>
                      <div
                        id="collapse9"
                        class="accordion-collapse collapse"
                        aria-labelledby="heading9"
                        data-bs-parent="#accordionFaqs"
                      >
                        <div class="accordion-body">
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h5 class="accordion-header" id="heading10">
                        <button
                          class="accordion-button accordion-button-custom collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse10"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          What kind of Escrow do you provide?
                        </button>
                      </h5>
                      <div
                        id="collapse10"
                        class="accordion-collapse collapse"
                        aria-labelledby="heading10"
                        data-bs-parent="#accordionFaqs"
                      >
                        <div class="accordion-body">
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="playbnt">
        <a class="play-btn" href="#"></a>
      </section>
      <section>
        <footer class="footer-section">
          <div class="container">
            <div class="footer-content ">
              <div class="row">
                <div class="col-xl-4 col-lg-4 mb-50">
                  <div class="footer-widget">
                    <div class="footer-widget-heading">
                      <h3>Bet-Big</h3>
                    </div>
                    <ul>
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form of a document or a typeface without relying on
                      meaningful content.
                    </ul>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 offset-lg-1 mb-30">
                  <div class="footer-widget">
                    <div class="footer-widget-heading">
                      <h3>Games</h3>
                    </div>
                    <ul>
                      <li>
                        <a href="#">Cricket</a>
                      </li>
                      <li>
                        <a href="#">About us</a>
                      </li>

                      <li>
                        <a href="#">Football</a>
                      </li>
                      <li>
                        <a href="#">Blog</a>
                      </li>

                      <li>
                        <a href="#">Tennis</a>
                      </li>
                      <li>
                        <a href="#">News</a>
                      </li>

                      <li>
                        <a href="#">Casino</a>
                      </li>
                      <li>
                        <a href="#">Sign In</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-4 mb-50">
                  <div class="footer-widget">
                    <div class="footer-widget-heading">
                      <h3>Subscribe</h3>
                    </div>
                    <div class="footer-text mb-25">
                      <p>
                        Don’t miss to subscribe to our new feeds, kindly fill
                        the form below.
                      </p>
                    </div>
                    <div class="subscribe-form">
                      <form action="#">
                        <input type="text" placeholder="Email Address" />
                        <button>
                          <i class="fab fa-telegram-plane"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="copyright-area">
            <div class="container">
              <div class="row">
                <div class="col-xl-6 col-lg-6 text-lg-left">
                  <div class="copyright-text">
                    <p>Copyright &copy; 2022, All Right Reserved </p>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                  <div class="footer-menu">
                    <ul>
                      <li>
                        <a href="#">Home</a>
                      </li>
                      <li>
                        <a href="#">Terms</a>
                      </li>
                      <li>
                        <a href="#">Privacy</a>
                      </li>
                      <li>
                        <a href="#">Policy</a>
                      </li>
                      <li>
                        <a href="#">Contact</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </section>
      {/* end section */}
      {/* modal */}

      {/* <button
          id="hide"
          className="getstarted"
          data-toggle="modal"
          data-target="#loginModal"
        >
          Get Started
        </button> */}

      <div
        className={`modal mylogin ${!isLoggedIn ? "show" : ""}`}
        id="loginModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              ref={loginCloseRef}
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
            <div class="modal-header">
              <div class="head">
                <h5>Sign into your Account</h5>
                <p>
                  Don't have an account?{" "}
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      signUpRef.current.click();
                      loginCloseRef.current.click();
                    }}
                    class="text-base"
                  >
                    Sign up
                  </a>{" "}
                  <a
                    className="signuptext"
                    ref={signUpRef}
                    data-toggle="modal"
                    data-target="#signup"
                    hidden
                  ></a>{" "}
                </p>
              </div>
            </div>
            <div className="modal-body">
              <div class="register-from">
                <form action="#0" onSubmit={handleLogin}>
                  <div class="items">
                    <label className="mb-1">User Name</label>
                    <div class="form-input">
                      <input
                        onChange={(e) => {
                          e.preventDefault();
                          setCredentials({
                            ...credentials,
                            user: e.target.value,
                          });
                        }}
                        type="text"
                        autocomplete="off"
                        id="email3"
                        placeholder="Jhon"
                      />
                    </div>
                  </div>
                  <div class="items">
                    <label className="mb-1">Password</label>

                    <div class="form-input">
                      <div class="form-group">
                        <input
                          onChange={(e) => {
                            e.preventDefault();
                            setCredentials({
                              ...credentials,
                              pwd: e.target.value,
                            });
                          }}
                          id="password-field"
                          type={passwordLoginType}
                          class="form-control"
                          autocomplete="off"
                          placeholder="****"
                          name="password"
                        />
                        <span
                          id="#password-field"
                          onClick={toggleLoginPassword}
                          className="eyeVisibility"

                          // class="fa fa-fw fa-eye field-icon toggle-password"
                        >
                          {" "}
                          {passwordLoginType === "password" ? (
                            <i className="fas fa-eye-slash"></i>
                          ) : (
                            <i className="fas fa-eye"></i>
                          )}
                        </span>
                      </div>
                    </div>
                    <p id="required-custom">{custerror}</p>
                  </div>

                  <div class="items">
                    <div class="remember d-flex align-items-center justify-content-between">
                      <div class="form-check">
                        {/* <label
                            class="form-check-label"
                            for="flexCheckDefault22"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault22"
                            />
                            Remember me
                          </label> */}
                      </div>
                      <Link to="/forgotPassword" class="forget">
                        Forget password?
                      </Link>
                    </div>
                  </div>
                  <div class="items text-center">
                    <button
                      type="submit"
                      class="cmn--btn cd-popup-close repopup"
                    >
                      <span>Login</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal mylogin ${!isLoggedIn ? "show" : ""}`}
        id="signup"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              ref={signUpCloseRef}
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
            <div class="modal-header">
              <div class="head">
                <h5>Create your new Account</h5>
                <p>
                  Already have an Account?{" "}
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      loginRef.current.click();
                      signUpCloseRef.current.click();
                    }}
                    class="text-base"
                  >
                    Log In
                  </a>{" "}
                  <a
                    className="signuptext"
                    ref={loginRef}
                    data-toggle="modal"
                    data-target="#loginModal"
                    hidden
                  ></a>
                </p>
              </div>
            </div>

            <div class="modal-body">
              <div class="register-from">
                <form action="#0" onSubmit={handleSignup}>
                  <div class="items">
                    <label className="mb-1">User Name</label>

                    <div class="form-input">
                      <input
                        type="text"
                        required
                        onChange={(e) => {
                          e.preventDefault();
                          setSignUpDtls({
                            ...signUpDtls,
                            userName: e.target.value,
                            Status: "user",
                          });
                        }}
                        // id="uname"
                        placeholder="Jhon"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="items">
                    <label className="mb-1">Phone</label>

                    <div class="form-input">
                      <input
                        type="number"
                        autocomplete="off"
                        pattern="[^0-9][+-]?[0-9]{1,10}[^0-9]"
                        minLength={"10"}
                        maxLength={"10"}
                        required
                        onChange={(e) => {
                          e.preventDefault();
                          setSignUpDtls({
                            ...signUpDtls,
                            phone: e.target.value,
                          });
                        }}
                        // id="uname"
                        placeholder="9087****90"
                      />
                    </div>
                  </div>
                  <div class="items">
                    <label className="mb-1">Password</label>

                    <div class="form-input">
                      <input
                        type={passwordType}
                        required
                        onChange={(e) => {
                          e.preventDefault();
                          setSignUpDtls({
                            ...signUpDtls,
                            password: e.target.value,
                          });
                        }}
                        autocomplete="off"
                        // id="email33"
                        placeholder="****"
                      />
                      <span
                        onClick={togglePassword}
                        id="#password-field2"
                        className="eyeVisibility"
                        // class="fa fa-fw fa-eye field-icon toggle-password2 eyeVisibility"
                        // className={
                        //   passwordType === "password"
                        //     ? "fas fa-eye-slash"
                        //     : "fas fa-eye"
                        // }
                      >
                        {passwordType === "password" ? (
                          <i className="fas fa-eye-slash"></i>
                        ) : (
                          <i className="fas fa-eye"></i>
                        )}
                      </span>
                    </div>
                  </div>
                  <div class="items">
                    <label className="mb-2">Confirm Password</label>

                    <div class="form-input">
                      <div class="form-group">
                        <input
                          // id="password-field2"
                          required
                          type={confirmPasswordType}
                          class="form-control"
                          placeholder="****"
                          onChange={(e) => {
                            e.preventDefault();
                            setSignUpDtls({
                              ...signUpDtls,
                              confirmPwd: e.target.value,
                              UplineRefId: 1,
                            });
                          }}
                          autocomplete="off"

                          // name="password"
                        />
                        <span
                          id="#password-field2"
                          onClick={toggleConfirmPassword}
                          className="eyeVisibility"
                          // class="fa fa-fw fa-eye field-icon toggle-password2 eyeVisibility"
                          // className={
                          //   confirmPasswordType === "password"
                          //     ? "fas fa-eye-slash"
                          //     : "fas fa-eye"
                          // }
                        >
                          {confirmPasswordType === "password" ? (
                            <i className="fas fa-eye-slash"></i>
                          ) : (
                            <i className="fas fa-eye"></i>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <div class="items">
                    <label className="mb-2" >Referel Code</label>
  
                      <div class="form-input">
                        <input type="text" id="refCode"
                        onChange={(e) => {
                          e.preventDefault();
                          setSignUpDtls({
                            ...signUpDtls,
                            RefferelCode: e.target.value,
                          });
                        }}
                        placeholder="Agent123" />
                      </div>
                    </div> */}
                  <br></br>
                  <div class="items text-center">
                    <button
                      type="submit"
                      class="cmn--btn cd-popup-close repopup"
                    >
                      <span>register</span>
                    </button>
                  </div>
                  {/* <div class="orbar">
                      <span>OR</span>
                    </div> */}
                  {/* <br></br> */}
                  {/* <div class="items text-center">
                      <button
                        type="submit"
                        class="cmn--btn cd-popup-close repopup"
                      >
                        <span>register</span>
                      </button>
                    </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomNavigation
        items={bottomNavItems}
        defaultSelected={0}
        onItemClick={(item) => navigate(`${item.route}`)}
      />
    </>
  );
}
