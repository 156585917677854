import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import Header2 from "../include/header2";
import LeftSideBar from "../include/LeftSideBar";
import Lfooter from "../Lfooter";
import Loader from "react-js-loader";
import dateFormat, { masks } from "dateformat";

export default function Transaction() {
  // const [count, setCount] = useState(1);
  var count = 0;
  const [date, setDate] = useState({
    fromDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    ).toISOString(),
    // fromDate: "2023-01-02",
    toDate: new Date().toISOString(),
    numberOfData: 5,
  });
  const [transactionDtls, setTransactionDtls] = useState([]);
  const [transactionType, setTransactionType] = useState("All");
  const [previousPage, setPreviousPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [fullData, setFullData] = useState(" active");
  const [filteredDate, setFilteredDate] = useState("d-none");
  let pageCountVal = [];
  let [pageCountVal2, setPageCountVal2] = useState({
    depositBank: [],
    depositUPI: [],
    depositIMPS: [],
    withdrawBank: [],
    withdrawUPI: [],
    withdrawIMPS: [],
  });
  const [paginationDate, setPaginationDate] = useState("d-none");
  // const [countData, setCountData] = useState(21)
  // const [fetchAllData,setFetchAllData] = useState({})
  const fetchAllDepositeQuery = gql`
    query fetchAllData {
      depositeBank(limit: 5, order_by: { Date: desc }) {
        Amount
        BankName
        Date
        ID
        Status
        UTRNum
        UserName
      }
      depositeUPI(limit: 5, order_by: { Date: desc }) {
        Amount
        Date
        ID
        ProviderName
        Status
        TransactionId
        UPIId
        UserName
      }
      depositeBank_aggregate {
        aggregate {
          count
        }
      }
      depositeUPI_aggregate {
        aggregate {
          count
        }
      }
    }
  `;
  const filterDateQuery = gql`query filterDate{depositeBank(where: {Date: {_gte: "${date.fromDate}", _lte: "${date.toDate}"}}, limit: ${date.numberOfData},order_by: {Date: desc}){Amount BankName Date ID Status UTRNum UserName}depositeUPI(where: {Date: {_gte: "${date.fromDate}", _lte: "${date.toDate}"}}, limit: ${date.numberOfData},order_by: {Date: desc}){Amount ProviderName Date ID Status TransactionId UserName}depositeBank_aggregate {aggregate {count}}depositeUPI_aggregate {aggregate {count}}}`;
  const paginationQuery = gql`{depositeBank( where: {Date: {_gte: "${
    date.fromDate
  }", _lte: "${date.toDate}"}},limit: ${
    date.numberOfData
  }, order_by: {Date: desc}, offset: ${
    pageNumber * date.numberOfData
  },) {Amount BankName Date ID Status UTRNum UserName}depositeUPI( where: {Date: {_gte: "${
    date.fromDate
  }", _lte: "${date.toDate}"}},limit: ${
    date.numberOfData
  }, order_by: {Date: desc}, offset: ${
    pageNumber * date.numberOfData
  }) {Amount Date ID ProviderName Status TransactionId UPIId UserName}depositeBank_aggregate(where: {Date: {_gte: "${
    date.fromDate
  }", _lte: "${
    date.toDate
  }"}}) {aggregate {count}}depositeUPI_aggregate(where: {Date: {_gte: "${
    date.fromDate
  }", _lte: "${date.toDate}"}}) {aggregate {count}}withdrawBank(limit: ${
    date.numberOfData
  }, offset: ${
    pageNumber * date.numberOfData
  }, order_by: {Date: desc}, where: {Date: {_gte: "${date.fromDate}", _lte: "${
    date.toDate
  }"}}) {AcntNum Amount BankName Date IFSCCode Id Nickname Status UserName}withdrawBank_aggregate(where: {Date: {_gte: "${
    date.fromDate
  }", _lte: "${date.toDate}"}}) {aggregate {count}}withdrawUPI(limit: ${
    date.numberOfData
  }, offset: ${
    pageNumber * date.numberOfData
  }, order_by: {Date: desc}, where: {Date: {_gte: "${date.fromDate}", _lte: "${
    date.toDate
  }"}}) {Amount Date Id ProviderName Status UPIId UserName}withdrawUPI_aggregate(where: {Date: {_gte: "${
    date.fromDate
  }", _lte: "${
    date.toDate
  }"}}) {aggregate {count}}depositeIMPS(where: {Date: {_gte: "${
    date.fromDate
  }", _lte: "${date.toDate}"}}, limit: ${
    date.numberOfData
  }, order_by: {Date: desc}, offset: ${
    pageNumber * date.numberOfData
  }) {Amount BankName Date ID Status UTRNum UserName}depositeIMPS_aggregate(where: {Date: {_gte: "${
    date.fromDate
  }", _lte: "${date.toDate}"}}) {aggregate {count}}withdrawIMPS(limit: ${
    date.numberOfData
  }, offset: ${
    pageNumber * date.numberOfData
  }, order_by: {Date: desc}, where: {Date: {_gte: "${date.fromDate}", _lte: "${
    date.toDate
  }"}}) {AcntNum Amount BankName Date IFSCCode Id Nickname Status UserName}withdrawIMPS_aggregate(where: {Date: {_gte: "${
    date.fromDate
  }", _lte: "${
    date.toDate
  }"}}) {aggregate {count}}BetSlipDtls( order_by: {Date: desc},where: {Date: {_gte: "${
    date.fromDate
  }", _lte: "${date.toDate}"}}, limit: ${date.numberOfData}, offset: ${
    date.numberOfData * pageNumber
  }) {Amount Date ID MatchName Odds Outcome Status UserId Team1 Team2   UserDetails {userName}}BetSlipDtls_aggregate(order_by: {Date: desc}, where: {Date: {_gte: "${
    date.fromDate
  }", _lte: "${date.toDate}"}}) {aggregate {count}}}`;
  const fetchBetsTransaction = gql`query fetchBets{BetSlipDtls( order_by: {Date: desc},where: {Date: {_gte: "${
    date.fromDate
  }", _lte: "${date.toDate}"}}, limit: ${date.numberOfData}, offset: ${
    date.numberOfData * pageNumber
  }) {Amount Date ID MatchName Odds Outcome Status UserId Team1 Team2}BetSlipDtls_aggregate(order_by: {Date: desc}, where: {Date: {_gte: "${
    date.fromDate
  }", _lte: "${date.toDate}"}}) {aggregate {count}}}`;
  const totalRecords = gql`
    {
      depositeBank_aggregate {
        aggregate {
          count
        }
      }
      depositeUPI_aggregate {
        aggregate {
          count
        }
      }
    }
  `;
  // const autofillQuery = gql`{{UserBankAcnts(where: {UserName: {_eq: "Sayan"}}){AcntNum BankName IFSCCode NickName UserName}}}`
  let { loading, error, data } = useQuery(paginationQuery);
  // let {loading:autofillLoading,error:autofillError,data:autofillData} = useQuery(autofillQuery)
  // let { loading: dataCountLoading, error: dataCountError, data: dataCountData } = useQuery(totalRecords)
  let [
    dateFilter,
    { loading: filteredLoading, error: filteredError, data: filteredData },
  ] = useLazyQuery(paginationQuery);
  let [
    paginationFilter,
    {
      loading: paginationLoading,
      error: paginationError,
      data: paginationData,
    },
  ] = useLazyQuery(paginationQuery);
  console.log(data ? data : error);
  // const updateDateValue = (e) => {
  //   e.preventDefault()
  //   console.log(e);
  //   setDate({...date,fromDate:e.target.value})
  //   setDate({...date,toDate:e.target.value})
  //  console.log(date);
  // }
  const updateValue = (e) => {
    // for (let i = 0; i < (filteredData?.depositeUPI_aggregate?.aggregate?.count + filteredData?.depositeBank_aggregate?.aggregate?.count) / 2 / date.numberOfData; i++) {
    //   pageCountVal2.push(i);
    // }
    // console.log(pageCountVal2);
    setFullData("d-none");
    setFilteredDate("active");
    // setPaginationDate("d-none")
  };
  useEffect(() => {
    if (!loading && data) {
      setTransactionDtls([
        data.depositeBank,
        data.depositeUPI,
        data.withdrawBank,
        data.withdrawUPI,
        data.depositeIMPS,
        data.withdrawIMPS,
        data.BetSlipDtls,
      ]);
    }
  }, [data, loading, transactionType]);
  const nextPage = () => {
    setPageNumber(pageNumber + 1);
  };
  const jumpOn = (e) => {
    console.log(e);
    setPageNumber(e);
  };
  const prevPage = () => {
    setPageNumber(pageNumber - 1);
  };

  if (error) return `Error! ${error.message}`;

  if (paginationError) return `Error! ${paginationError.message}`;

  // const [data,setData] = useState({})
  // const filterDate = ()=>{}
  // var loading,error,data;
  // setDummyCall(data)

  // const dateFilter = (e) => {
  //   console.log(data);
  //   setFullData("d-none")
  //   setFilteredDate("active")
  //   setDummyCall(data)
  //   data = null
  //   console.log(dummyCall);
  // }

  if (filteredError) return `Error! ${filteredError.message}`;

  // for (let i = 0; i < (data?.depositeUPI_aggregate?.aggregate?.count + data?.depositeBank_aggregate?.aggregate?.count) / 2 / date.numberOfData; i++) {
  //   pageCountVal.push(i);
  // }

  if (transactionType === "Bank Deposit") {
    for (
      let i = 0;
      i < data?.depositeBank_aggregate?.aggregate?.count / date.numberOfData;
      i++
    )
      pageCountVal.push(i);
  } else if (transactionType === "UPI Deposit") {
    for (
      let i = 0;
      i < data?.depositeUPI_aggregate?.aggregate?.count / date.numberOfData;
      i++
    )
      pageCountVal.push(i);
  } else if (transactionType === "IMPS Deposit") {
    for (
      let i = 0;
      i < data?.depositeIMPS_aggregate?.aggregate?.count / date.numberOfData;
      i++
    )
      pageCountVal.push(i);
  } else if (transactionType === "Bank Withdraw") {
    for (
      let i = 0;
      i < data?.withdrawBank_aggregate?.aggregate?.count / date.numberOfData;
      i++
    )
      pageCountVal.push(i);
  } else if (transactionType === "UPI Withdraw") {
    for (
      let i = 0;
      i < data?.withdrawUPI_aggregate?.aggregate?.count / date.numberOfData;
      i++
    )
      pageCountVal.push(i);
  } else if (transactionType === "IMPS Withdraw") {
    for (
      let i = 0;
      i < data?.withdrawIMPS_aggregate?.aggregate?.count / date.numberOfData;
      i++
    )
      pageCountVal.push(i);
  } else if (transactionType === "All") {
    for (
      let i = 0;
      i < data?.BetSlipDtls_aggregate?.aggregate?.count / date.numberOfData;
      i++
    )
      pageCountVal.push(i);
  }
  if (paginationLoading || loading || filteredLoading)
    return (
     <img src="assets/img/logo/game.png"  className="loaderimg"/>
    );
  // console.log(JSON.stringify(data));
  return (
    <div>
      {/* Header Section start */}
      <Header2 />
     <div className="container">
      <div className="row">
        <div className="col-2">
          <LeftSideBar />
        </div>

        <div className="col-md-10 col-12">
          {/* <br></br> */}
          <div class="body-middle10">
            {/* <!--Breadcumnd--> */}

            {/* <!--Breadcumnd--> */}

            {/* <!--profile-->  */}
            <div class="profile-section ">
              <div class="container">
                <div class="tab-content " id="pro-wrap">
                  <div>
                    <div class="row justify-content-between">
                      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                        <div class="profile-left-wrap ">
                          <div class="form-box form-box-custom">
                            {/* <div class="form-head"></div> */}
                            <form action="#0">
                              <div className="row">
                                <div className="col-md-4 col-12">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2 custom-label6"
                                  >
                                    From
                                    <div class="form-grp">
                                      <input
                                        value={date.fromDate}
                                        onChange={(e) => {
                                          e.preventDefault();
                                          setDate({
                                            ...date,
                                            fromDate: e.target.value,
                                          });
                                        }}
                                        type="date"
                                      />
                                      <div class="left-icon">
                                        <i
                                          class="fa fa-calendar"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                                <div className="col-md-4 col-12">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2 custom-label6"
                                  >
                                    To
                                    <div class="form-grp">
                                      <input
                                        onChange={(e) => {
                                          e.preventDefault();
                                          setDate({
                                            ...date,
                                            toDate: e.target.value,
                                          });
                                        }}
                                        value={date.toDate}
                                        type="date"
                                      />
                                      <div class="left-icon">
                                        <i
                                          class="fa fa-calendar"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                                <div className="col-md-4 ">
                                  <label className="mb-2 custom-label6">
                                    Transaction Type
                                    <div class="form-grp">
                                      <select
                                        class="form-control dropdownBtn2  mt-1 pl-1"
                                        value={transactionType}
                                        onChange={(e) => {
                                          e.preventDefault();
                                          setTransactionType(e.target.value);
                                          setPageNumber(0);
                                        }}
                                        name="state"
                                        id="maxRows"
                                      >
                                        <option>All</option>
                                        <option>Bank Deposit</option>
                                        <option>UPI Deposit</option>
                                        <option>IMPS Deposit</option>
                                        <option>Bank Withdraw</option>
                                        <option>UPI Withdraw</option>
                                        <option>IMPS Withdraw</option>
                                      </select>
                                      <div class="right-lock">
                                       <i class="fa-solid fa-chevron-down"></i>
                                    </div> 
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                        <div class="profile-left-wrap profile-left-wrap5">
                          <div class={`description-table ${fullData}`}>
                            <table>
                              <tbody>
                                <tr class="">
                                  <th>S. no.</th>
                                  <th>Date</th>
                                  <th
                                    className={
                                      transactionType === "All"
                                        ? "d-none"
                                        : "show"
                                    }
                                  >
                                    Bank/UPI Details
                                  </th>
                                  <th>Amount</th>
                                  <th
                                    className={
                                      transactionType === "All"
                                        ? "d-none"
                                        : "show"
                                    }
                                  >
                                    User
                                  </th>
                                  <th
                                    className={
                                      transactionType === "All"
                                        ? "d-none"
                                        : "show"
                                    }
                                  >
                                    Deposit/Withdrawl
                                  </th>
                                  <th>Status</th>
                                </tr>
                                {transactionType === "Bank Deposit" ? (
                                  data.depositeBank.length!=0 ? (
                                    data.depositeBank.map((ele, index) => (
                                      <tr class="tb1" key={index}>
                                        <td>
                                          <span class="text1">{index + 1}</span>
                                        </td>
                                        <td>
                                        <span>{dateFormat(ele.Date," dd-mm-yyyy, h:MM TT")}</span>
                                        </td>
                                        <td>{ele.BankName}</td>
                                        <td>
                                          <span class="text1">
                                            {" "}
                                            {ele.Amount}{" "}
                                          </span>
                                        </td>
                                        <td>
                                          <span class="text1">
                                            {ele.UserName}
                                          </span>
                                        </td>
                                        <td>
                                          <span class="text1">
                                            Bank Deposit
                                          </span>
                                        </td>
                                        <td>
                                          {ele.Status === 2 ? (
                                            <span class="text1">Pending</span>
                                          ) : ele.Status === 1 ? (
                                            <span class="text1">Success</span>
                                          ) : (
                                            <span class="text1">Failed</span>
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
<>
                                        <tr> <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"> No Records Found</span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                         <td>
                                          <span class="text1"></span>
                                        </td></tr>
                                     </>






                                  )
                                ) : transactionType === "UPI Deposit" ? (
                                  data.depositeUPI.length!=0 ? (
                                    data.depositeUPI.map((ele, index) => (
                                      <tr class="tb1" key={index}>
                                        <td>
                                          <span class="text1">{index + 1}</span>
                                        </td>
                                        <td>
                                        <span>{dateFormat(ele.Date," dd-mm-yyyy, h:MM TT")}</span>
                                        </td>
                                        <td>{ele.ProviderName}</td>
                                        <td>
                                          <span class="text1">
                                            {" "}
                                            {ele.Amount}{" "}
                                          </span>
                                        </td>
                                        <td>
                                          <span class="text1">
                                            {ele.UserName}
                                          </span>
                                        </td>
                                        <td>
                                          <span class="text1">UPI Deposit</span>
                                        </td>
                                        <td>
                                          {ele.Status === 2 ? (
                                            <span class="text1">Pending</span>
                                          ) : ele.Status === 1 ? (
                                            <span class="text1">Success</span>
                                          ) : (
                                            <span class="text1">Failed</span>
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
<>
                                        <tr> <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"> No Records Found</span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                         <td>
                                          <span class="text1"></span>
                                        </td></tr>
                                     </>






                                  )
                                ) : transactionType === "IMPS Deposit" ? (
                                  data.depositeIMPS.length!=0 ? (
                                    data.depositeIMPS?.map((ele, index) => (
                                      <tr class="tb1" key={index}>
                                        <td>
                                          <span class="text1">{index + 1}</span>
                                        </td>
                                        <td>
                                        <span>{dateFormat(ele.Date," dd-mm-yyyy, h:MM TT")}</span>
                                        </td>
                                        <td>{ele.BankName}</td>
                                        <td>
                                          <span class="text1">
                                            {" "}
                                            {ele.Amount}{" "}
                                          </span>
                                        </td>
                                        <td>
                                          <span class="text1">
                                            {ele.UserName}
                                          </span>
                                        </td>
                                        <td>
                                          <span class="text1">
                                            IMPS Deposit
                                          </span>
                                        </td>
                                        <td>
                                          {ele.Status === 2 ? (
                                            <span class="text1">Pending</span>
                                          ) : ele.Status === 1 ? (
                                            <span class="text1">Success</span>
                                          ) : (
                                            <span class="text1">Failed</span>
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
<>
                                        <tr> <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"> No Records Found</span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                         <td>
                                          <span class="text1"></span>
                                        </td></tr>
                                     </>






                                  )
                                ) : transactionType === "All" ? (
                                  transactionDtls!=0 ? (
                                    transactionDtls.map((res, index) =>
                                      res.map((ele) =>
                                        ele ? (
                                          <>
                                            <tr class="tb1" key={count}>
                                              <td>
                                                <span class="text1">
                                                  {count + 1}
                                                </span>
                                              </td>
                                              <td>
                                              <span>{dateFormat(ele.Date," dd-mm-yyyy, h:MM TT")}</span>
                                              </td>
                                              {/* <td>{ele.MatchName}</td> */}
                                              <td>
                                                <span class="text1">
                                                  {" "}
                                                  {ele.__typename ===
                                                    "depositeBank" ||
                                                  ele.__typename ===
                                                    "depositeUPI" ||
                                                  ele.__typename ===
                                                    "depositeIMPS"
                                                    ? ele.Amount
                                                    : ele.__typename ===
                                                        "withdrawBank" ||
                                                      ele.__typename ===
                                                        "withdrawUPI" ||
                                                      ele.__typename ===
                                                        "withdrawIMPS"
                                                    ? -ele.Amount
                                                    : ele.Status === "Open"
                                                    ? -ele.Amount
                                                    : ele.Amount}{" "}
                                                </span>
                                              </td>
                                              {/* <td>
                                                <span class="text1">
                                                  {ele.UserDtls
                                                    ? ele.UserDtls[0].userName
                                                    : ele.UserName}
                                                </span>
                                              </td> */}
                                              {/* <td>
                                                <span class="text1">IMPS Deposit</span>
                                              </td> */}
                                              <td>
                                                {ele.Status === "Open" ? (
                                                  <span class="text1">
                                                    Open
                                                  </span>
                                                ) : ele.Status === "Settled" ? (
                                                  <span class="text1">
                                                    Settled
                                                  </span>
                                                ) : ele.Status === 1 ? (
                                                  <span class="text1">
                                                    Success
                                                  </span>
                                                ) : ele.Status === 2 ? (
                                                  <span class="text1">
                                                    Pending
                                                  </span>
                                                ) : ele.Status === 0 ? (
                                                  <span class="text1">
                                                    Failed
                                                  </span>
                                                ) : (
                                                  ""
                                                )}
                                              </td>
                                              {/* <p className="d-none" >{setCount(prev=>prev+1)}</p> */}
                                              <p className="d-none">
                                                {count++}
                                              </p>
                                            </tr>
                                          </>
                                        ) : (
                                          ""
                                        )
                                      )
                                    )
                                  ) : (
<>
                                        <tr> <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"> No Records Found</span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                         <td>
                                          <span class="text1"></span>
                                        </td></tr>
                                     </>






                                  )
                                ) : transactionType === "Bank Withdraw" ? (
                                  data.withdrawBank.length!=0 ? (
                                    data.withdrawBank.map((ele, index) => (
                                      <tr class="tb1" key={index}>
                                        <td>
                                          <span class="text1">{index + 1}</span>
                                        </td>
                                        <td>
                                        <span>{dateFormat(ele.Date," dd-mm-yyyy, h:MM TT")}</span>
                                        </td>
                                        <td>{ele.BankName}</td>
                                        <td>
                                          <span class="text1">
                                            {" "}
                                            {ele.Amount}{" "}
                                          </span>
                                        </td>
                                        <td>
                                          <span class="text1">
                                            {ele.UserName}
                                          </span>
                                        </td>
                                        <td>
                                          <span class="text1">
                                            Bank Withdraw
                                          </span>
                                        </td>
                                        <td>
                                          {ele.Status === 2 ? (
                                            <span class="text1">Pending</span>
                                          ) : ele.Status === 1 ? (
                                            <span class="text1">Success</span>
                                          ) : (
                                            <span class="text1">Failed</span>
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
<>
                                        <tr> <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"> No Records Found</span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                         <td>
                                          <span class="text1"></span>
                                        </td></tr>
                                     </>






                                  )
                                ) : transactionType === "UPI Withdraw" ? (
                                  data.withdrawUPI.length!=0 ? (
                                    data.withdrawUPI.map((ele, index) => (
                                      <tr class="tb1" key={index}>
                                        <td>
                                          <span class="text1">{index + 1}</span>
                                        </td>
                                        <td>
                                        <span>{dateFormat(ele.Date," dd-mm-yyyy, h:MM TT")}</span>
                                        </td>
                                        <td>{ele.ProviderName}</td>
                                        <td>
                                          <span class="text1">
                                            {" "}
                                            {ele.Amount}{" "}
                                          </span>
                                        </td>
                                        <td>
                                          <span class="text1">
                                            {ele.UserName}
                                          </span>
                                        </td>
                                        <td>
                                          <span class="text1">
                                            UPI Withdraw
                                          </span>
                                        </td>
                                        <td>
                                          {ele.Status === 2 ? (
                                            <span class="text1">Pending</span>
                                          ) : ele.Status === 1 ? (
                                            <span class="text1">Success</span>
                                          ) : (
                                            <span class="text1">Failed</span>
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
<>
                                        <tr> <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"> No Records Found</span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                         <td>
                                          <span class="text1"></span>
                                        </td></tr>
                                     </>






                                  )
                                ) : transactionType === "IMPS Withdraw" ? (
                                  data.withdrawIMPS.length!=0 ? (
                                    data.withdrawIMPS.map((ele, index) => (
                                      <tr class="tb1" key={index}>
                                        <td>
                                          <span class="text1">{index + 1}</span>
                                        </td>
                                        <td>
                                        <span>{dateFormat(ele.Date," dd-mm-yyyy, h:MM TT")}</span>
                                        </td>
                                        <td>{ele.BankName}</td>
                                        <td>
                                          <span class="text1">
                                            {" "}
                                            {ele.Amount}{" "}
                                          </span>
                                        </td>
                                        <td>
                                          <span class="text1">
                                            {ele.UserName}
                                          </span>
                                        </td>
                                        <td>
                                          <span class="text1">
                                            IMPS Withdraw
                                          </span>
                                        </td>
                                        <td>
                                          {ele.Status === 2 ? (
                                            <span class="text1">Pending</span>
                                          ) : ele.Status === 1 ? (
                                            <span class="text1">Success</span>
                                          ) : (
                                            <span class="text1">Failed</span>
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
<>
                                        <tr> <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"> No Records Found</span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                        <td>
                                          <span class="text1"></span>
                                        </td>
                                         <td>
                                          <span class="text1"></span>
                                        </td></tr>
                                     </>






                                  )
                                ) : (
                                  <span></span>
                                )}
                              </tbody>
                            </table>

                           
                          </div>
                          <div className="row mb-5">
                              <div className="col-md-4 col-12">
                                <label
                                  for="exampleInputEmail1"
                                  className="mb-2 mt-3"
                                >
                                  Content Per Page
                                </label>
                                <select
                                  class="form-control dropdownBtn"
                                  value={date.numberOfData}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    setDate({
                                      ...date,
                                      numberOfData: e.target.value,
                                    });
                                  }}
                                  name="state"
                                  id="maxRows"
                                >
                                  <option>5</option>
                                  <option>10</option>
                                  <option>15</option>
                                  <option>20</option>
                                  <option>50</option>
                                  <option>70</option>
                                  <option>100</option>
                                </select>
                              </div>
                              <div class="pagination-container col-md-8 col-12">
                                <nav>
                                  <ul class="pagination">
                                    <li data-page="prev" className="btnSpacing">
                                      <button
                                        class="paginationBtn"
                                        disabled={pageNumber <= 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          prevPage();
                                        }}
                                      >
                                        <span> Prev </span>
                                      </button>
                                    </li>
                                    <li data-page="prev" className="btnSpacing">
                                      {pageCountVal.map((ele) => (
                                        <button
                                          class={`paginationBtn ${pageNumber===ele ? "pagination-active" : ""}`}
                                          key={ele}
                                          value={ele + 1}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            jumpOn(ele);
                                          }}
                                        >
                                          <span> {ele + 1} </span>
                                        </button>
                                      ))}
                                    </li>
                                    <li
                                      data-page="next"
                                      className="btnSpacing"
                                      id="prev"
                                    >
                                      <button
                                        class="paginationBtn"
                                        disabled={
                                          pageNumber >= pageCountVal.length - 1
                                        }
                                        onClick={(e) => {
                                          e.preventDefault();
                                          nextPage();
                                        }}
                                      >
                                        <span> Next</span>
                                      </button>
                                    </li>
                                  </ul>
                                </nav>
                              </div>
                            </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
       
    </div>
  );
}
