import React, { useState, useEffect } from "react";
import { span, Link } from "react-router-dom";
import { gql, useQuery, useMutation, useSubscription } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import Loader from "react-js-loader";

import Swal from "sweetalert2";
import axios from "axios";
import jwt_decode from "jwt-decode";

const Betslip = (prop) => {
   console.log(prop);
  // const label = { inputProps: { 'aria-label': 'Switch demo' } };
  var decoded = jwt_decode(localStorage.getItem("accessToken"));
   //console.log(decoded);
  let [fixtureId, setFixtureId] = useState(prop.outcome.fixtureId);
  const [betsID, setBetsID] = useState(0);
  const [userId, setUserId] = useState(0);
  const [allOddsStatus, setAllOddsStatus] = useState(true);
  const [outcome, setOutcome] = useState(prop.outcome.Outcome);
  const [team, setTeam] = useState(prop.outcome.Team);
  const [matchName, setMatchName] = useState(prop.outcome.MatchName);
  const [myBets, setMyBets] = useState("d-none");
  const [betSlip, setBetSlip] = useState("show active");
  // const [oddsDummy, setOddsDummy] = useState(prop.outcome.Outcome);
  var dummyOdds = prop.outcome.Outcome
  const [odds, setOdds] = useState(prop.outcome.Outcome);
  const [stake, setStake] = useState(0);
  const [openBets, setOpenBets] = useState({});
  const [profit, setProfit] = useState(0);
  const [alerts, setAlerts] = useState("");
  const [Status, setStatus] = useState(0);
  const [custerror, setCusterror] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [placeBetVal, setPlaceBetVal] = useState(true);
  // var placeBetVal = true
  const [fetchButtonStakes, setFetchButtonStakes] = useState({
    val1: 0,
    val2: 0,
    val3: 0,
    val4: 0,
    val5: 0,
    val6: 0,
  });
  const [viewStakes, setViewStakes] = useState({ MinStake: 0, MaxStake: 0 });
  const [backAndLay, setBackAndLay] = useState({
    back1Name1: 0,
    back2Name1: 0,
    lay1Name1: 0,
    lay2Name1: 0,
    back1Name2: 0,
    back2Name2: 0,
    lay1Name2: 0,
    lay2Name2: 0,
  });
  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const navigate = useNavigate();
  const fetchStakesQuery = gql`
    query fetchDefaultStakes {
      DefaultStakes(order_by: { Id: asc }) {
        Value
        Type
        Id
      }
    }
  `;
  const fetchMaxOdds = gql`
    query fetchMaxValues {
      BetsApi(where: { FixtureId: { _eq: "${prop.outcome.fixtureId}" } }) {
        MaxOdd
        MaxStake
        MinOdd
        MinStake
      }
    }
  `;
  const fetchMatchQuery = gql`
    {
      BetSlipDtls(where: { Status: { _eq: "Open" } }) {
        Amount
        Date
        ID
        MatchName
        Odds
        Outcome
        Status
        UserId
      }
    }
  `;
  const fetchWalletBalQuery = gql`
    {
      Wallet {
        UserName
        Amount
        Points
      }
    }
  `;
  const fetchStakesButtonValQuery = gql`
    query FetchStakesButton {
      StakesButton {
        Date
        UserId
        Value1
        Value2
        Value3
        Value4
        Value5
        Value6
      }
    }
  `;
  const betQuery = gql`mutation {insert_BetSlipDtls(objects: {Amount: ${stake}, Odds: ${prop.outcome.oddVal}, Outcome: "${prop.outcome.Outcome}",MatchName:"${prop.outcome.MatchName}",Team1:"${prop.outcome.Team}", Status: "Open", UserId: 3}) {returning {Amount}}}`;
  const fetchBetsQuery = gql`
  subscription fetchBets {
    BetsApi(where:  {FixtureId: {_eq : "${prop.outcome.fixtureId}"},Status: { _eq: 1 }, Name: {_in: ["1","2"]}}, order_by: {LastUpdate: desc}, limit: 1) {
      Back1Price
      Back1PriceVolume
      Back2Price
      Back2PriceVolume
      Id
      Index
      LastUpdate
      Lay1Price
      Lay1PriceVolume
      Lay2Price
      Lay2PriceVolume
      LayPrice
      LayPriceVolume
      Name
      Price
      PriceVolume
      ProviderId
      StartPrice
      Status
    }
  }
  `;
  let {
    data: betsData,
    error: betsError,
    loading: betsLoading,
  } = useSubscription(fetchBetsQuery);
  let {
    data: fetchStakesButtonValData,
    loading: fetchStakesButtonValLoading,
    error: fetchStakesButtonValError,
  } = useQuery(fetchStakesButtonValQuery);

  let {
    data: fetchStakesData,
    loading: fetchStakesLoading,
    error: fetchStakesError,
  } = useQuery(fetchStakesQuery);

  const [insertBet, { loading: betLoading, error: betError, data: betData }] =
    useMutation(betQuery, {
      refetchQueries: [{ query: fetchWalletBalQuery, query: fetchMatchQuery }],
    });
  let {
    data: fetchMaxOddsData,
    error: fetchMaxOddsError,
    Loading: fetchMaxOddsLoading,
  } = useQuery(fetchMaxOdds);
  let { loading, error, data } = useQuery(fetchWalletBalQuery);
  let {
    loading: matchLoading,
    error: matchError,
    data: matchData,
  } = useQuery(fetchMatchQuery);

   //console.log(data ? data : data);

   //console.log(fetchMaxOddsData ? fetchMaxOddsData : fetchMaxOddsError);

  useEffect(() => {
    // Betslip()
  }, [profit]);
  useEffect(() => {
    if (!fetchStakesButtonValLoading && fetchStakesButtonValData) {
      setFetchButtonStakes({
        ...fetchButtonStakes,
        val1: fetchStakesButtonValData.StakesButton[0].Value1
          ? fetchStakesButtonValData.StakesButton[0].Value1
          : 0,
        val2: fetchStakesButtonValData.StakesButton[0].Value2
          ? fetchStakesButtonValData.StakesButton[0].Value2
          : 0,
        val3: fetchStakesButtonValData.StakesButton[0].Value3
          ? fetchStakesButtonValData.StakesButton[0].Value3
          : 0,
        val4: fetchStakesButtonValData.StakesButton[0].Value4
          ? fetchStakesButtonValData.StakesButton[0].Value4
          : 0,
        val5: fetchStakesButtonValData.StakesButton[0].Value5
          ? fetchStakesButtonValData.StakesButton[0].Value5
          : 0,
        val6: fetchStakesButtonValData.StakesButton[0].Value6
          ? fetchStakesButtonValData.StakesButton[0].Value6
          : 0,
      });
    }
  }, [fetchStakesButtonValLoading, fetchStakesButtonValData]);
  const fireAlert = (title, Result) => {
    Swal.fire({
      text: title,
      showConfirmButton: true,
      // showCancelButton: true,
      confirmButtonText: "OK",
      // cancelButtonText: "Cancel",
      icon: Result,
    }).then(function (isConfirm) {
      if (isConfirm) {
        window.location.reload(true);
      }
    });
  };

  useEffect(() => {
    if (!fetchStakesLoading && fetchStakesData) {
      setViewStakes({
        ...viewStakes,
        MinStake: fetchStakesData.DefaultStakes[2].Value,
        MaxStake: fetchStakesData.DefaultStakes[3].Value,
      });
    }
  }, [fetchStakesLoading, fetchStakesData]);

  useEffect(() => {
    if (!betsLoading && betsData) {
       console.log(betsData);
      updateOddsVal()
      // if (allOddsStatus) {
      
      // }
      // else if (!allOddsStatus) {
      //   if (betsData.BetsApi?.Name == 1) {
      //     if (prop.outcome.betType === "back1Name1") {
      //       if (betsData.BetsApi.Back1Price !== prop.outcome.oddVal) {
      //         setOddsDummy(betsData.BetsApi.Back1Price);
      //         setPlaceBetVal(false);
      //         alert("back1Name1");
      //       }
      //     }
      //     if (prop.outcome.betType === "lay1Name1") {
      //       if (betsData.BetsApi.Lay1Price !== prop.outcome.oddVal) {
      //         setOddsDummy(betsData.BetsApi.Lay1Price);
      //         setPlaceBetVal(false);
      //         alert("lay1Name1");
      //       }
      //     }
      //     if (prop.outcome.betType === "back2Name1") {
      //       if (betsData.BetsApi.Back2Price !== prop.outcome.oddVal) {
      //         setOddsDummy(betsData.BetsApi.Back2Price);
      //         setPlaceBetVal(false);
      //         alert("back2Name1");
      //       }
      //     }
      //     if (prop.outcome.betType === "lay2Name1") {
      //       if (betsData.BetsApi.Lay2Price !== prop.outcome.oddVal) {
      //         setOddsDummy(betsData.BetsApi.Lay2Price);
      //         setPlaceBetVal(false);
      //         alert("lay2Name1");
      //       }
      //     }
      //   } else if (betsData.BetsApi.Name == 2) {
      //     if (prop.outcome.betType === "back1Name2") {
      //       if (betsData.BetsApi.Back1Price !== prop.outcome.oddVal) {
      //         setOddsDummy(betsData.BetsApi.Back1Price);
      //         setPlaceBetVal(false);
      //         alert("back1Name2");
      //       }
      //     }
      //     if (prop.outcome.betType === "lay1Name2") {
      //       if (betsData.BetsApi.Lay1Price !== prop.outcome.oddVal) {
      //         setOddsDummy(betsData.BetsApi.Lay1Price);
      //         setPlaceBetVal(false);
      //         alert("lay1Name2");
      //       }
      //     }
      //     if (prop.outcome.betType === "back2Name2") {
      //       if (betsData.BetsApi.Back2Price !== prop.outcome.oddVal) {
      //         setOddsDummy(betsData.BetsApi.Back2Price);
      //         setPlaceBetVal(false);
      //         alert("back2Name2");
      //       }
      //     }
      //     if (prop.outcome.betType === "lay2Name2") {
      //       if (betsData.BetsApi.Lay2Price !== prop.outcome.oddVal) {
      //         setOddsDummy(betsData.BetsApi.Lay2Price);
      //         setPlaceBetVal(false);
      //         alert("lay2Name2");
      //       }
      //     }
      //   }
      // }
    }
  }, [betsLoading, betsData]);
  const updateOddsVal = ()=>{
    if (betsData.BetsApi[0]?.Name == "1") {

      if (prop.outcome.betType === "back1Name1") {
        if (betsData.BetsApi[0].Back1Price !== prop.outcome.oddVal) {
          updateOdds(betsData.BetsApi[0].Back1Price);
        }
      }
      if (prop.outcome.betType === "lay1Name1") {
        if (betsData.BetsApi[0].Lay1Price !== prop.outcome.oddVal) {
          updateOdds(betsData.BetsApi[0].Lay1Price);
        }
      }
      if (prop.outcome.betType === "back2Name1") {
        if (betsData.BetsApi[0].Back2Price !== prop.outcome.oddVal) {
          updateOdds(betsData.BetsApi[0].Back2Price);
        }
      }
      if (prop.outcome.betType === "lay2Name1") {
        if (betsData.BetsApi[0].Lay2Price !== prop.outcome.oddVal) {
          updateOdds(betsData.BetsApi[0].Lay2Price);
        }
      }
    } else if (betsData.BetsApi[0]?.Name == "2") {

      if (prop.outcome.betType === "back1Name2") {
        if (betsData.BetsApi[0].Back1Price !== prop.outcome.oddVal) {
          updateOdds(betsData.BetsApi[0].Back1Price);
        }
      }
      if (prop.outcome.betType === "lay1Name2") {
        if (betsData.BetsApi[0].Lay1Price !== prop.outcome.oddVal) {
          updateOdds(betsData.BetsApi[0].Lay1Price);
        }
      }
      if (prop.outcome.betType === "back2Name2") {
        if (betsData.BetsApi[0].Back2Price !== prop.outcome.oddVal) {
          updateOdds(betsData.BetsApi[0].Back2Price);
          console.log(`Back price Testing odds${odds} betsapi[0].Back2Price ${betsData.BetsApi[0].Back2Price}`)

        }
      }
      if (prop.outcome.betType === "lay2Name2") {
        if (betsData.BetsApi[0].Lay2Price !== prop.outcome.oddVal) {
          updateOdds(betsData.BetsApi[0].Lay2Price);
        }
      }
    }
  }
  const updateOdds = (odds) => {
    // alert(`${odds}`)
    setOdds(odds);
    dummyOdds=odds
    setPlaceBetVal(false);
  };
  const handelBets = async () => {
    console.log(placeBetVal);
    if (placeBetVal) {
      if (
        fetchMaxOddsData.BetsApi[0].MaxStake >= stake &&
        stake >= fetchMaxOddsData.BetsApi[0].MinStake
      ) {
        var accessToken = localStorage.getItem("accessToken");
        const res = await axios.post(
          `${process.env.REACT_APP_BaseUrl}/placeBets`,
          {
            stake,
            odds,
            Status,
            outcome,
            matchName,
            team,
            betsID,
            fixtureId,
          },
          { mode: "cors", headers: headers }
        );
         //console.log(res);
        if (res.status === 200) {
          setIsLoading(false);
          fireAlert(res.data, "Success");
        } else {
          setIsLoading(false);
          fireAlert(res.data, "Error");
        }
      } else {
        // alert("Please give Stake value in range.")
        setIsLoading(false);

        setCusterror("Please give Stake value in range");
      }
    } else {
      setIsLoading(false);
      Swal.fire({
        text: `Since the Odds been updated to ${odds} 
         Please accept all odds to place bets`,
        showConfirmButton: true,
        isConfirmed: true,
        confirmButtonText: "ACCEPT ODDS",
        denyButtonText: "Cancel",
        showCancelButton: true,
        icon: "warning",
        // timer: 3000,
      }).then(async function (result) {
        if (result.isConfirmed) {
          if (
            fetchMaxOddsData.BetsApi[0].MaxStake >= stake &&
            stake >= fetchMaxOddsData.BetsApi[0].MinStake
          ) {
             accessToken = localStorage.getItem("accessToken");
            const res = await axios.post(
              `${process.env.REACT_APP_BaseUrl}/placeBets`,
              {
                stake,
                odds,
                Status,
                outcome,
                matchName,
                team,
                betsID,
                fixtureId,
              },
              { mode: "cors", headers: headers }
            );
             //console.log(res);
            if (res.status === 200) {
              setIsLoading(false);
              fireAlert(res.data, "Success");
            } else {
              setIsLoading(false);
              fireAlert(res.data, "Error");
            }
          } else {
            // alert("Please give Stake value in range.")
            setIsLoading(false);

            setCusterror("Please give Stake value in range");
          }
        }
        
      });
    }
  };
  const toggleChange = () => {
    if (!placeBetVal) {
      setAllOddsStatus(true);
    }
  };
  if (
    loading ||
    fetchMaxOddsLoading ||
    betLoading ||
    matchLoading ||
    fetchStakesButtonValLoading ||
    isLoading
  )
    return <img src="assets/img/logo/game.png" className="loaderimg" />;

  return (
    <div>
      <div class="right-site-menu">
        <div class="right-box">
          <div class="right-wrapper-area">
            {/* <div class="blance-items">
              <div class="left-blance">
                <span>
                  <img
                    src="assets/img/header/right-icon/wallet.svg"
                    alt="icon"
                  />
                </span>
                <span class="text-blabce">Balance</span>
              </div>
              <span class="blance">
                {data?.Wallet[0]?.Points * data?.Wallet[0]?.Amount}
              </span>
            </div> */}
            {/* <div class="close-items">
              <div class="close-head">
                <span>Latvia vs Moldova</span>
              </div>
            </div> */}
            <div class="combo-box">
              {/* <div className="close-btn77">
            <button
            type="button"
            onClick={e=>{e.preventDefault();window.location.reload(true)}}
            className="btn-close "
            data-dismiss="modal"
            id="close-btn-custom"
            aria-label="Close"
          >
            <span className="btn-plus">X</span>
          </button>
          </div>   */}
              <ul class="nav nav-custom55">
                <li class="nav-item">
                  <a
                    class="nav-link link-secondary active"
                    id="combo-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#coombo"
                    href="#"
                    onClick={(e) => {
                      setBetSlip("show active");
                      setMyBets("d-none");
                    }}
                  >
                    {/* <span class="bed1">
                      <img
                        src="assets/img/header/right-icon/bed.svg"
                        alt="icon"
                      />
                    </span> */}
                    <span class="text-bets"> &nbsp;Bet Slip</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link link-secondary"
                    id="bets-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#bbets"
                    href="#"
                    onClick={(e) => {
                      setMyBets("show active");
                      setBetSlip("d-none");
                    }}
                  >
                    {/* <span>
                      <img
                        src="assets/img/header/right-icon/bets.svg"
                        alt="icon"
                      />
                    </span> */}
                    <span class="text-bets"> My Bets</span>
                  </a>
                </li>
                <li class = "nav-item">
                  <button
                    type="button"
                    
                    class="btn-close cross-btn cross-btn5"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </li>
              </ul>

              <div class="tab-content" id="tabContentboxes">
                <div
                  class={`tab-pane fade  ${betSlip}`}
                  id="coombo"
                  role="tabpanel"
                >
                  <div class="combo-wrapper">
                    <div class="combo-wrapper">
                      <div class="close-box">
                        <div class="close-items1">
                          <div class="close-head">
                            <div className="depositAccordion">
                              <span className="col-md-7">
                                {prop.outcome.Team1}
                              </span>
                              <span className="col-md-5">
                                Balance:{" "}
                                {
                                  // data?.Wallet[0]?.Points *
                                  data?.Wallet[0]?.Amount
                                }
                              </span>
                            </div>
                          </div>
                          <div class="match-fixing">
                            <div class="match-items">
                              <div class="match-items-left">
                                <div class="ammount-items">
                                  <form>
                                    <div className="row my-2">
                                      <div className="col-md-6 col-6">
                                        <input
                                          type="number"
                                          value={prop.outcome.oddVal}
                                          className="btnbetbig"
                                          // onChange={(e) => {
                                          //   e.preventDefault();
                                          //   setOdds(e.target.value);
                                          // }}
                                          placeholder="Odds"
                                          disabled
                                        />
                                      </div>
                                      <div className="col-md-6 col-6">
                                        <label>{prop.outcome.Outcome}</label>
                                      </div>

                                      <div className="col-md-6 col-7">
                                        <label>Edit you stakes</label>
                                      </div>
                                      <div className="col-md-6 col-5">
                                        <Link
                                          to="/setting"
                                          className="edit-stake"
                                        >
                                          Edit Stakes
                                        </Link>
                                      </div>
                                    </div>

                                    <input
                                      type="number"
                                      className="btnbetbig"
                                      value={stake == 0 ? "" : stake}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setStake(Number(e.target.value));
                                        setProfit(
                                          prop.outcome.oddVal * stake - stake
                                        );
                                        setStatus(prop.outcome.Status);
                                        setOutcome(prop.outcome.Outcome);
                                        setTeam(prop.outcome.Team1);
                                        setMatchName(prop.outcome.MatchName);
                                        setBetsID(prop.outcome.ID);
                                        setOdds(Number(prop.outcome.oddVal));
                                        setUserId(Number(decoded.sub));
                                        setFixtureId(prop.outcome.fixtureId);
                                      }}
                                      placeholder="Enter Your Stake"
                                    />
                                    <p id="required-custom">{custerror}</p>
                                    <div className="buttonCard">
                                      <button
                                        type="button"
                                        // placeholder={fetchButtonStakes.val1}
                                        value={fetchButtonStakes.val1}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setStake(
                                            stake + Number(e.target.value)
                                          );
                                          setProfit(
                                            () =>
                                              prop.outcome.oddVal * stake -
                                              stake
                                          );
                                          setStatus(prop.outcome.Status);
                                          setOutcome(prop.outcome.Outcome);
                                          setTeam(prop.outcome.Team1);
                                          setMatchName(prop.outcome.MatchName);
                                          setBetsID(prop.outcome.ID);
                                          setOdds(Number(prop.outcome.oddVal));
                                          setUserId(Number(decoded.sub));
                                          setFixtureId(prop.outcome.fixtureId);
                                        }}
                                        class="item body-items3 btn-custom6"
                                      >
                                         {fetchButtonStakes.val1}
                                      </button>

                                      <button
                                        type="button"
                                        // placeholder={fetchButtonStakes.val2}
                                        value={fetchButtonStakes.val2}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setStake(
                                            stake + Number(e.target.value)
                                          );
                                          setStatus(prop.outcome.Status);
                                          setOutcome(prop.outcome.Outcome);
                                          setTeam(prop.outcome.Team1);
                                          setMatchName(prop.outcome.MatchName);
                                          setBetsID(prop.outcome.ID);
                                          setOdds(Number(prop.outcome.oddVal));
                                          setUserId(Number(decoded.sub));
                                          setFixtureId(prop.outcome.fixtureId);
                                        }}
                                        class="item body-items3 btn-custom6"
                                      >
                                         {fetchButtonStakes.val2}{" "}
                                      </button>

                                      <button
                                        type="button"
                                        placeholder="300"
                                        value={fetchButtonStakes.val3}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setStake(
                                            stake + Number(e.target.value)
                                          );
                                          setStatus(prop.outcome.Status);
                                          setOutcome(prop.outcome.Outcome);
                                          setTeam(prop.outcome.Team1);
                                          setMatchName(prop.outcome.MatchName);
                                          setBetsID(prop.outcome.ID);
                                          setOdds(Number(prop.outcome.oddVal));
                                          setUserId(Number(decoded.sub));
                                          setFixtureId(prop.outcome.fixtureId);
                                        }}
                                        class="item body-items3 btn-custom6"
                                      >
                                        {" "}
                                        {fetchButtonStakes.val3}{" "}
                                      </button>

                                      <button
                                        type="button"
                                        placeholder="300"
                                        value={fetchButtonStakes.val4}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setStake(
                                            stake + Number(e.target.value)
                                          );
                                          setStatus(prop.outcome.Status);
                                          setOutcome(prop.outcome.Outcome);
                                          setTeam(prop.outcome.Team1);
                                          setMatchName(prop.outcome.MatchName);
                                          setBetsID(prop.outcome.ID);
                                          setOdds(Number(prop.outcome.oddVal));
                                          setUserId(Number(decoded.sub));
                                          setFixtureId(prop.outcome.fixtureId);
                                        }}
                                        class="item body-items3 btn-custom6"
                                      >
                                         {fetchButtonStakes.val4}{" "}
                                      </button>
                                    </div>
                                    <div className="buttonCard">
                                      <button
                                        type="button"
                                        placeholder="400"
                                        value={Number(
                                          fetchMaxOddsData?.BetsApi[0]?.MinStake
                                        )}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setStake(
                                            stake + Number(e.target.value)
                                          );
                                          setStatus(prop.outcome.Status);
                                          setOutcome(prop.outcome.Outcome);
                                          setTeam(prop.outcome.Team1);
                                          setMatchName(prop.outcome.MatchName);
                                          setBetsID(prop.outcome.ID);
                                          setOdds(Number(prop.outcome.oddVal));
                                          setUserId(Number(decoded.sub));
                                          setFixtureId(prop.outcome.fixtureId);
                                        }}
                                        class="item body-items3 btn-custom6"
                                      >
                                        Min Stake:{" "}
                                        {fetchMaxOddsData?.BetsApi[0]
                                          ?.MinStake != null
                                          ? fetchMaxOddsData?.BetsApi[0]
                                              ?.MinStake
                                          : viewStakes.MinStake}
                                      </button>

                                      {/* <input
                                        type="button"
                                        placeholder="500"
                                        value={500}
                                        onClick={(e) => {
                                          e.preventDefault();
                                        setStake(Number(e.target.value));
                                                                                }}
                                        class="item body-items3 "
                                      /> */}

                                      <button
                                        type="button"
                                        placeholder="600"
                                        value={Number(
                                          fetchMaxOddsData?.BetsApi[0]?.MaxStake
                                        )}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setStake(
                                            stake + Number(e.target.value)
                                          );
                                          setStatus(prop.outcome.Status);
                                          setOutcome(prop.outcome.Outcome);
                                          setTeam(prop.outcome.Team1);
                                          setMatchName(prop.outcome.MatchName);
                                          setBetsID(prop.outcome.ID);
                                          setOdds(Number(prop.outcome.oddVal));
                                          setUserId(Number(decoded.sub));
                                          setFixtureId(prop.outcome.fixtureId);
                                        }}
                                        class="item body-items3 btn-custom6"
                                      >
                                        Max Stake :
                                        {fetchMaxOddsData?.BetsApi[0]
                                          ?.MaxStake != null
                                          ? fetchMaxOddsData?.BetsApi[0]
                                              ?.MaxStake
                                          : viewStakes.MaxStake}
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="combo-switch">
                          <span>Accept all odds changes</span>
                          {/* <label class="switch-com">
                          <input type="checkbox"  disabled checked class="checkbox" hidden />
                          <span class="checkbox-label">
                            <span class="ball"></span>
                          </span>
                        </label> */}
                        </div>
                        <div>
                          {/* <span>Profit : {profit} </span> */}
                          <span class="vl" ></span>
                          <span className="profitbtn">
                            {prop.outcome.Outcome === "Back"
                              ? "Profit"
                              : prop.outcome.Outcome === "Lay"
                              ? "Liability"
                              : ""}{" "}
                            : {(prop.outcome.oddVal * stake - stake).toFixed(2)}
                          </span>
                          {/* <span className="profitbtn">
                          Liability : {(prop.outcome.oddVal * stake - stake).toFixed(2)}
                        </span> */}
                        </div>
                      </div>

                      {/* <br></br> */}
                      {/* <div class="ammount-items">
                                                <form action="#">
                                                    <input type="number" placeholder="Bet Amount" />
                                                    <button type="submit">
                                                        Max
                                                    </button>
                                                </form>
                                            </div> */}
                      <div class="row combo-footer">
                        <div className="col-md-6  col-6 buttonCard">
                          <button
                            type="button"
                            // placeholder="Clear"
                            value="Clear"
                            onClick={(e) => {
                              e.preventDefault();
                              setStake(0);
                              setOdds(0);
                              setProfit(0);
                            }}
                            class="item body-items3 btn-custom6 btn-custom3"
                          >
                            Clear
                          </button>
                        </div>
                        <div className="col-md-6  col-6 buttonCard">
                          <button
                            type="submit"
                            value="Place Bet"
                            onClick={async (e) => {
                              e.preventDefault();

                              setStake(0);
                              setOdds(0);
                              setIsLoading(true);

                              handelBets();
                            }}
                            class="item body-items3 btn-custom6 btn-custom10"
                          >
                            Place Bet
                          </button>
                        </div>
                      </div>
                      <br></br>
                    </div>
                  </div>
                </div>
                <div
                  class={`tab-pane fade ${myBets}`}
                  id="bbets"
                  role="tabpanel"
                >
                  <div>
                    <div class="countries-tab pb-60">
                      <div class="accordion" id="countries">
                        <div class="accordion-item">
                          <div class="match-fixing">
                            <div class="accordion-header" id="countriestab2">
                              <button
                                style={{ marginTop: "17px" }}
                                class="accordion-button collapsed collapsed-custom "
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#premiumSports"
                                aria-expanded="false"
                                aria-controls="premiumSports"
                              >
                                {" "}
                                <span> Premium Sports Book</span>
                              </button>
                              <div
                                id="premiumSports"
                                class="accordion-collapse collapse"
                                data-bs-parent="#premiumSports"
                              >
                                {" "}
                                <div class="accordion-body">
                                  <p>No records found</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="match-fixing">
                            <div class="accordion-header" id="countriestab2">
                              <button
                                style={{ marginTop: "17px" }}
                                class="accordion-button collapsed collapsed-custom"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#premiumSports1"
                                aria-expanded="false"
                                aria-controls="premiumSports1"
                              >
                                {" "}
                                <span> Matched Bets</span>
                              </button>
                              <div
                                id="premiumSports1"
                                class="accordion-collapse collapse"
                                data-bs-parent="#premiumSports1"
                              >
                                {" "}
                                <div class="accordion-body">
                                  <p>No records found</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="match-fixing">
                            <div class="accordion-header" id="countriestab2">
                              <button
                                style={{ marginTop: "17px" }}
                                class="accordion-button collapsed collapsed-custom"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#premiumSports2"
                                aria-expanded="false"
                                aria-controls="premiumSports2"
                              >
                                {" "}
                                <span> Unmatched Bets</span>
                              </button>
                              <div
                                id="premiumSports2"
                                class="accordion-collapse collapse"
                                data-bs-parent="#premiumSports2"
                              >
                                {" "}
                                <div class="accordion-body">
                                  <p>No records found</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Betslip;
