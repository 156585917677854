import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { gql, useQuery, useMutation } from "@apollo/client";
import LeftSideBar from "../include/LeftSideBar";
import Loader from "react-js-loader";

import jwt_decode from "jwt-decode";

import Lfooter from "../Lfooter";
import Header2 from "../include/header2";
import Swal from "sweetalert2";
export default function Withdraw() {
  var decoded = jwt_decode(localStorage.getItem("accessToken"));
  const [bank, setBank] = useState("bankshow active");
  const [none,setNone] = useState("");
  const [bankbtn, setBankbtn] = useState("btnact");
  const [upibtn, setUpibtn] = useState("btnact1");
  const [upi, setUpi] = useState("d-none");
  const [upiAccordian, setUpiAccordian] = useState("");
  const [bankAccordian, setBankAccordian] = useState("show");

  const [bankright, setBankright] = useState("bankshow1 active1");
  const [bankbtnright, setBankbtnright] = useState("btnact");
  const [upibtnright, setUpibtnright] = useState("btnact1right");
  const [upiright, setUpiright] = useState("d-none");
  const [dropdownBank,setDropdownBank] = useState(undefined)
  const [updateRadioBank,setUpdateRadioBank] = useState("selecotr-item")
  const [updateRadioUPI,setUpdateRadioUPI] = useState("selecotr-item-inactive")
  const navigate = useNavigate();
  const [acntData,setAcntData] = useState(undefined)
  const [withdrawBank, setWithdrawBank] = useState({
    Amount: 0,
    BankName: "",
    AcntNum: "",
    UserName: "",
    NickName: "",
    IFSCCode: "",
    radio: "",
  });
  const [withdrawUPI, setWithdrawUPI] = useState({
    Amount: 0,
    ProviderName: "",
    UserName: "",
    radio: "",
  });
  const [userBankDtls, setUserBankDtls] = useState({
    BankName: "",
    NickName: "",
    AcntNum: "",
    IFSCCode: "",
    radio: "",
    UserName: "",
    Amount: 0,
  });
  const [userUPIDtls, setUserUPIDtls] = useState({
    ProviderName: "",
    UPIId: "",
    radio: "",
  });
  const fetchAcntsQuery = gql`query fetchAcnts {
    UserBankAcnts(limit: 5, order_by: {createdAt: desc}) {
      AcntNum
      BankName
      IFSCCode
      NickName
    }
    UserUPIAcnts(limit: 5, order_by: {createdAt: desc}) {
      ProviderName
      UPIId
    }
  }
  
  `
  const autofillQuery = gql`{UserBankAcnts(where: {UserName: {_eq: "${decoded.name}"}}){AcntNum BankName IFSCCode NickName UserName Id}}`;
  let {
    loading: autofillLoading,
    error: autofillError,
    data: autofillData,
  } = useQuery(autofillQuery);
  let {data:fetchAcntsData,loading:fetchAcntsLoading,error:fetchAcntsError} = useQuery(fetchAcntsQuery)
  console.log(autofillData ? autofillData : autofillError);

  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };

  const withdrawAmount = async (e) => {
    try {
      e.preventDefault();
      console.log(withdrawUPI);

      if (withdrawBank.radio === "bank") {
        const responseBank = await axios.post(
          `${process.env.REACT_APP_BaseUrl}/withdrawl`,
          withdrawBank,
          {
            mode: "cors",

            headers: headers,
          }
        );
        if (responseBank.status === 200) {
          Swal.fire({
            title: "Your withdrawl will be removed shortly from your wallet.",
            confirmButtonText: "OK",
            icon: "success",
          }).then(function (isConfirm) {
            if (isConfirm) {
              navigate("/#/Transaction");
            }
          });
        } else {
          Swal.fire({
            title: responseBank.data,
            confirmButtonText: "OK",
            icon: "success",
          }).then(function (isConfirm) {
            if (isConfirm) {
              navigate("/#/Transaction");
            }
          });
        }
      } else if (withdrawUPI.radio === "upi") {
        const responseUPI = await axios.post(
          `${process.env.REACT_APP_BaseUrl}/withdrawl`,
          withdrawUPI,
          {
            mode: "cors",

            headers: headers,
          }
        );
        if (responseUPI.status === 200) {
          Swal.fire({
            title: "Your withdrawl will be removed shortly from your wallet.",
            confirmButtonText: "OK",
            icon: "success",
          }).then(function (isConfirm) {
            if (isConfirm) {
              window.location.reload(true);
            }
          });
        } else {
          Swal.fire({
            title: responseUPI.data,
            confirmButtonText: "OK",
            icon: "success",
          }).then(function (isConfirm) {
            if (isConfirm) {
              window.location.reload(true);
            }
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addBankAcnt = async (e) => {
    try {
      e.preventDefault();
      console.log(userUPIDtls);

      if (userBankDtls.radio === "bank") {
        const responseBank = await axios.post(
          `${process.env.REACT_APP_BaseUrl}/addBankAcnt`,
          userBankDtls,
          { headers: headers }
        );
        if (responseBank.status === 200) {
          setNone("d-none")

          Swal.fire({
            title: "Your account will be added shortly.",
            confirmButtonText: "OK",
            icon: "success",
          }).then(function (isConfirm) {
            if (isConfirm) {
              window.location.reload(true);
            }
          }); // navigate('/Transaction');
        } else if (responseBank.status === 201) {
          Swal.fire({
            title: responseBank.data,
            confirmButtonText: "OK",
            icon: "success",
          }).then(function (isConfirm) {
            if (isConfirm) {
              window.location.reload(true);
            }
          }); 
        }
      } else if (userUPIDtls.radio === "upi") {
        console.log(userUPIDtls);
        console.log(headers);

        const responseUPI = await axios.post(
          `${process.env.REACT_APP_BaseUrl}/addUPIAcnt`,
          userUPIDtls,
          { headers: headers }
        );
        if (responseUPI.status === 200) {
          setNone("d-none")
          Swal.fire({
            title: "Your account will be added shortly.",
            confirmButtonText: "OK",
            icon: "success",
          }).then(function (isConfirm) {
            if (isConfirm) {
              window.location.reload(true);
            }
          }); // navigate('/Transaction');
        } else if (responseUPI.status === 201) {
          Swal.fire({
            title: responseUPI.data,
            confirmButtonText: "OK",
            icon: "success",
          }).then(function (isConfirm) {
            if (isConfirm) {
              window.location.reload(true);
            }
          }); 
                }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const updateAutofillVal = (e) => {
    dropdownBank?.UserBankAcnts.map((ele) => {
      if (ele.NickName === e.target.value) {
        setWithdrawBank({
          ...withdrawBank,
          BankName: ele.BankName,
          IFSCCode: ele.IFSCCode,
          AcntNum: ele.AcntNum,
          UserName: ele.UserName,
          NickName: ele.NickName,
        });
      }
    });
  };

  console.log(autofillData ? autofillData : autofillError);
  const handleChange = (event) => {
    if (event.target.value === "upi") {
      setUpi("show");
      setBank("d-none");
      setUpibtn("btnact");
      setBankbtn("btnact1");
      setUpiright("d-block");
      setUpiAccordian("show");
      setBankright("d-none");
      setBankAccordian("d-none");
      setUpdateRadioBank("selecotr-item-inactive");
      setUpdateRadioUPI("selecotr-item");
    } else {
      setUpi("d-none");
      setBank("show");
      setBankbtn("btnact");
      setUpibtn("btnact1");
      setUpiright("d-none");
      setBankright("d-block");
      setBankAccordian("show");
      setUpiAccordian("d-none");
      setUpdateRadioBank("selecotr-item");
      setUpdateRadioUPI("selecotr-item-inactive")
    }
  };
  useEffect(() => {
if(!autofillLoading && autofillData){
    autofillData?.UserBankAcnts.map((ele) => {
      setWithdrawBank({
        ...withdrawBank,
        BankName: ele.BankName,
        IFSCCode: ele.IFSCCode,
        AcntNum: ele.AcntNum,
        UserName: ele.UserName,
        NickName: ele.NickName,
      });
    });
  }

   
    // autofillData?.UserUPIAcnts.map((ele) => {
    //   setWithdrawUPI({
    //     ...withdrawUPI,
    //     ProviderName: ele.ProviderName,
    //   });
    // });
  }, [autofillData,autofillLoading]);
  useEffect(()=>{
    if( !fetchAcntsLoading && fetchAcntsData)  {
      setAcntData(fetchAcntsData.data)
    }
  },[fetchAcntsLoading,fetchAcntsData])
  useEffect(()=>{
    if(!autofillLoading && autofillData){
      setDropdownBank(autofillData)
    }
  },[autofillData,autofillLoading])
  if(fetchAcntsLoading || autofillLoading){
    <Loader
    type="spinner-circle"
    className="loader-custom"
    bgColor={"#fff"}
    color={"#fff"}
    title={"Loading"}
    size={70}
  />
  }
  return (
    <div>
      {/* Header Section start */}
      <Header2 />
      <div className="container">
      <div className="row">
        <div className="col-12 col-md-2">
          <LeftSideBar />
        </div>

        <div className="col-md-10 col-12">
          <br></br>
          <div class="body-middle">
            {/* <!--Breadcumnd--> */}

            {/* <!--Breadcumnd--> */}

            {/* <!--profile-->  */}
            <div class="profile-section pb-60">
              <div class="container p-0">
                <div class="tab-content " id="pro-wrap">
                  <br />
                  <div class="">
                    <div class="row">
                      <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-7">
                        <div class="container">
                          <div class="selector">
                            <div class={updateRadioBank}>
                              <input
                                type="radio"

                                checked={bank === "bank"}
                                value="bank"
                                id="radio1"
                                name="selector"
                                onClick={handleChange}
                              />
                              <label
                                for="radio1"
                                class={`selector-item_label ${bankbtn}`}
                              >
                                {" "}
                                <i
                                  className="fa fa-bank"
                                  style={{
                                    "font-size": "16px",
                                    "padding-right": "10px",
                                  }}
                                ></i>{" "}
                                Banks
                                {/* Bank */}
                              </label>
                            </div>
                            <div class={updateRadioUPI}>
                              <input
                                checked={bank === "upi"}
                                type="radio"
                                id="radio2"
                                value="upi"
                                onClick={handleChange}
                              />

                              <label
                                for="radio2"
                                class={`selector-item_label ${upibtn}`}
                              >
                                <i
                                  className="fa fa-bank"
                                  style={{
                                    "font-size": "16px",
                                    "padding-right": "10px",
                                  }}
                                ></i>{" "}
                                UPI ID
                              </label>
                            </div>
                          </div>
                        </div>
<br></br>
                        <div className={`profile-left-wrap ${bank}`}>
                          <div class="form-box form-box-custom">
                            {/* <div class="form-head"></div> */}
                            <form action="#0" onSubmit={withdrawAmount}>
                              <div className="row">
                                <div className="col-md-6 mt-4">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2"
                                  >
                                    Account Name*
                                  </label>
                                  <div class="form-grp">
                                    {/* <input
                                      onChange={(e) => setWithdrawBank({ ...withdrawBank, NickName: e.target.value, radio: "bank" })}
                                      type="text"
                                      placeholder=" Account Name"
                                    />
                                    <div class="left-icon">
                                      <i
                                        class="fas fa-user"
                                        style={{
                                          "font-size": "16px",
                                          marginRight: "10px",
                                        }}
                                      ></i>
                                    </div>
                                    <div class="right-lock">
                                       <i class="fa-solid fa-lock"></i>
                                    </div> */}
                                    <select
                                      class="form-control dropdownBtn2"
                                      onChange={updateAutofillVal}
                                      name="state"
                                      id="maxRows"
                                    >
                                      {/* <option value={autofillData?.data?.UserBankAcnts[0]?.NickName}></option> */}

                                      {autofillLoading
                                        ? ""
                                        : dropdownBank?.UserBankAcnts.map(
                                            (ele) => (
                                              <option
                                                value={ele.NickName}
                                                key={ele.Id}
                                              >
                                                {ele.NickName}
                                              </option>
                                            )
                                          )}
                                    </select>
                                    <div class="right-lock">
                                       <i class="fa-solid fa-chevron-down"></i>
                                    </div> 
                                  </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2"
                                  >
                                    Account Number*
                                  </label>
                                  <div class="form-grp">
                                    <input
                                      type="text"
                                      required
                                      value={
                                        withdrawBank.AcntNum != ""
                                          ? withdrawBank.AcntNum
                                          : ""
                                      }
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setWithdrawBank({
                                          ...withdrawBank,
                                          AcntNum: e.target.value,
                                          UserName: decoded.name,
                                        });
                                      }}
                                      placeholder="12345678"
                                    />
                                    <div class="left-icon">
                                      <i
                                        class="fa solid fa-book"
                                        style={{
                                          marginRight: "10px",
                                          "font-size": "16px",
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="col-md-6 mt-4">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2"
                                  >
                                    Account UserName*
                                  </label>
                                  <div class="form-grp">
                                    <input
                                      type="text"
                                      value={withdrawBank.UserName!=""? withdrawBank.UserName: "" }
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setWithdrawBank({
                                          ...withdrawBank,
                                          UserName: e.target.value,
                                        });
                                      }}
                                      placeholder="User Name"
                                    />
                                    <div class="left-icon">
                                      <i
                                        class="fa solid fa-book"
                                        style={{
                                          marginRight: "10px",
                                          "font-size": "16px",
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                </div> */}
                                <div className="col-md-6 mt-4">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2"
                                  >
                                    Bank Name*
                                  </label>
                                  <div class="form-grp">
                                    <input
                                      type="text"
                                      required
                                      value={
                                        withdrawBank.BankName != ""
                                          ? withdrawBank.BankName
                                          : ""
                                      }
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setWithdrawBank({
                                          ...withdrawBank,
                                          BankName: e.target.value,
                                        });
                                      }}
                                      placeholder="Bank Name"
                                    />
                                    <div class="left-icon">
                                      <i
                                        class="fa solid fa-book"
                                        style={{
                                          marginRight: "10px",
                                          "font-size": "16px",
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2"
                                  >
                                    Amount*
                                  </label>
                                  <div class="form-grp">
                                    <input
                                      type="number"
                                      required
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setWithdrawBank({
                                          ...withdrawBank,
                                          Amount: e.target.value,
                                          radio: "bank",
                                        });
                                      }}
                                      placeholder="Amount"
                                    />
                                    <div class="left-icon">
                                      <i
                                        class="fa solid fa-book"
                                        style={{
                                          marginRight: "10px",
                                          "font-size": "16px",
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2"
                                  >
                                    IFSC Code*
                                  </label>
                                  <div class="form-grp">
                                    <input
                                      type="text"
                                      required
                                      value={
                                        withdrawBank.IFSCCode != ""
                                          ? withdrawBank.IFSCCode
                                          : ""
                                      }
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setWithdrawBank({
                                          ...withdrawBank,
                                          IFSCCode: e.target.value,
                                        });
                                      }}
                                      placeholder="Name"
                                    />
                                    <div class="left-icon">
                                      <i
                                        class="fas fa-user"
                                        style={{
                                          "font-size": "16px",
                                          marginRight: "10px",
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-grp form-grp1 mt-4">
                                  <button type="submit" class="cmn--btn">
                                    <span>Submit</span>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div class={`profile-left-wrap ${upi}`}>
                          <div class="form-box form-box-custom">
                            {/* <div class="form-head"></div> */}
                            <form action="#0" onSubmit={withdrawAmount}>
                              <div className="row">
                                <div className="col-md-6 mt-4">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2"
                                  >
                                    My Name
                                  </label>
                                  <div class="form-grp">
                                    <input
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setWithdrawUPI({
                                          ...withdrawUPI,
                                          UserName: e.target.value,
                                          radio: "upi",
                                        });
                                      }}
                                      type="text"
                                      required
                                      placeholder=" Jhon Doe"
                                    />
                                    <div class="left-icon">
                                      <i
                                        class="fas fa-user"
                                        style={{
                                          "font-size": "16px",
                                          marginRight: "10px",
                                        }}
                                      ></i>
                                    </div>
                                    {/* <div class="right-lock">
                                       <i class="fa-solid fa-lock"></i>
                                    </div> */}
                                  </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2"
                                  >
                                    My UPI ID
                                  </label>
                                  <div class="form-grp">
                                    <input
                                      type="text"
                                      required
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setWithdrawUPI({
                                          ...withdrawUPI,
                                          UPIId: e.target.value,
                                        });
                                      }}
                                      placeholder="12345678"
                                    />
                                    <div class="left-icon">
                                      <i
                                        class="fa solid fa-book"
                                        style={{
                                          marginRight: "10px",
                                          "font-size": "16px",
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2"
                                  >
                                    Amount
                                  </label>
                                  <div class="form-grp">
                                    <input
                                      type="number"
                                      required
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setWithdrawUPI({
                                          ...withdrawUPI,
                                          Amount: e.target.value,
                                        });
                                      }}
                                      placeholder="10,000"
                                    />
                                    <div class="left-icon">
                                      <i class="fa fa-inr"></i>
                                    </div>
                                    {/* <div class="right-lock">
                                       <i class="fa-solid fa-lock"></i>
                                    </div> */}
                                  </div>
                                </div>

                                <div className="col-md-6 mt-4">
                                  <label
                                    for="exampleInputEmail1"
                                    className="mb-2"
                                  >
                                    My Provider Name
                                  </label>
                                  <div class="form-grp ">
                                    <input
                                    required
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setWithdrawUPI({
                                          ...withdrawUPI,
                                          ProviderName: e.target.value,
                                        });
                                      }}
                                      type="text"
                                      placeholder="45Dt56GH"
                                    />
                                    <div class="left-icon">
                                      <i class="fa solid fa-book"></i>
                                    </div>
                                    {/* <div class="right-lock">
                                       <i class="fa-solid fa-lock"></i>
                                    </div> */}
                                    {/* <a href="#0" class="repeat">
                                       <i class="fas fa-repeat"></i>
                                    </a> */}
                                  </div>
                                </div>

                                <div class="form-grp mt-4">
                                  <button type="submit" class="cmn--btn">
                                    <span>Submit</span>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                      <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5">
                        <div class="profile-left-wrap profile-left-wrap-custom">
                          <div class="">
                            <div class="countries-tab">
                              <div class="accordion custom-accordian3" id="countries">
                                <div class="accordion-item">
                                  <div class="match-fixing">
                                    <div
                                      class="accordion-header"
                                      id="countriestab1"
                                    >
                                      {" "}
                                      {/* UPI accordian Section starts */}
                                      <div className={upi}>
                                        <button
                                          style={{ marginTop: "17px" }}
                                          class="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#upiID1"
                                          aria-expanded="false"
                                          aria-controls="upiID1"
                                        >
                                          {" "}
                                          <span>
                                            {" "}
                                            <i
                                              className="fa fa-bank"
                                              style={{ marginRight: "10px" }}
                                            ></i>{" "}
                                            UPI ID
                                          </span>
                                        </button>
                                      </div>
                                      <div
                                        id="upiID1"
                                        class={`accordion-collapse collapse ${upiAccordian}`}
                                        data-bs-parent="#upiID1"
                                      >
                                        <div class="accordion-body">
                                        <table class="table scoretable">
                                          <thead>
                                            <tr>
                                              <th scope="col"><h6>Provider Name</h6></th>
                                              <th scope="col"><h6>UPI Address </h6></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {fetchAcntsData?.UserUPIAcnts.map(
                                              (acnt, index) => (
                                                <tr key={index}  >
                                                  <td> {acnt.ProviderName} </td>
                                                  <td> {acnt.UPIId} </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                        </div>
                                      </div>
                                      {/* UPI accordian Section ends */}
                                      {/* Bank accordian Section starts */}
                                      <div className={bank}>
                                        <button
                                          style={{ marginTop: "17px" }}
                                          class="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#bankID1"
                                          aria-expanded="false"
                                          aria-controls="bankID1"
                                        >
                                          {" "}
                                          <span>
                                            {" "}
                                            <i
                                              className="fa fa-bank"
                                              style={{ marginRight: "10px" }}
                                            ></i>{" "}
                                            BANK ACCOUNTS
                                          </span>
                                        </button>
                                      </div>
                                      <div
                                        id="bankID1"
                                        class={`accordion-collapse collapse ${bankAccordian}`}
                                        data-bs-parent="#bankID1"
                                      >
                                        {" "}
                                        <div class="accordion-body">
                                        <table class="table scoretable">
                                            <thead>
                                              <tr>
                                                <th scope="col" class="col-th-name">Bank Name </th>
                                                <th scope="col" class="col-th-name">
                                                  Account Number
                                                </th>
                                                <th scope="col" class="col-th-name">IFSC Code</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {fetchAcntsData?.UserBankAcnts.map(
                                                (acnt, index) => (
                                                  <tr key={index}>
                                                    <td> {acnt.BankName} </td>
                                                    <td> {acnt.AcntNum} </td>
                                                    <td> {acnt.IFSCCode} </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div class="form-grp mt-2">
                                        <button
                                          type="submit"
                                          class={`cmn--btn ${bank}`}
                                          data-bs-toggle="modal"
                                          data-bs-target="#exampleModal"
                                        >
                                          <i
                                            class="fa fa-plus"
                                            aria-hidden="true"
                                          ></i>
                                          <span className="bank-btn">Add Bank Account</span>
                                        </button>
                                      </div>
                                      <div class="form-grp mt-2">
                                        <button
                                          type="submit"
                                          class={`cmn--btn ${upi}`}
                                          data-bs-toggle="modal"
                                          data-bs-target="#exampleModal2"
                                        >
                                          <i
                                            class="fa fa-plus"
                                            aria-hidden="true"
                                          ></i>
                                          <span className="bank-btn">Add UPI Account</span>
                                        </button>
                                      </div>
                                      <div
                                        class={`modal mylogin signup-popup fade ${none}`}
                                        id="exampleModal"
                                        tabindex="-1"
                                        aria-hidden="true"
                                      >
                                        <div class="modal-dialog modal-dialog-centered">
                                          <div class="modal-content">
                                            <div class="modal-header">
                                              <div class="head">
                                                <h5>Add your bank account</h5>
                                              </div>
                                            </div>
                                            <button
                                              type="button"
                                              class="btn-close cross-btn"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                            <div class="modal-body">
                                              <div class="register-from">
                                                <form
                                                  action="#0"
                                                  onSubmit={addBankAcnt}
                                                >
                                                  <div class="items">
                                                    <div class="form-input">
                                                      <label
                                                        for="email3"
                                                        class="form-label"
                                                      >
                                                        Bank Name
                                                      </label>
                                                      <input
                                                        type="text"
                                                        required
                                                        id="email3"
                                                        value={
                                                          userBankDtls.BankName
                                                        }
                                                        onChange={(e) => {
                                                          e.preventDefault();
                                                          setUserBankDtls({
                                                            ...userBankDtls,
                                                            BankName:
                                                              e.target.value,
                                                            UserName:
                                                              decoded.name,
                                                          });
                                                        }}
                                                        placeholder="Your Bank Name"
                                                      />
                                                    </div>
                                                  </div>
                                                  {/* <div class="items">
                                                    <div class="form-input">
                                                      <label
                                                        for="email3"
                                                        class="form-label"
                                                      >
                                                        User Name
                                                      </label>
                                                      <input
                                                        type="text"
                                                        id="email3"
                                                        value={
                                                          userBankDtls.UserName
                                                        }
                                                        onChange={(e) => {
                                                          e.preventDefault();
                                                          setUserBankDtls({
                                                            ...userBankDtls,
                                                            UserName:
                                                              e.target.value,
                                                          });
                                                        }}
                                                        placeholder="Your User Name"
                                                      />
                                                    </div>
                                                  </div> */}
                                                  <div class="items">
                                                    <div class="form-input">
                                                      <label
                                                        for="email3"
                                                        class="form-label"
                                                      >
                                                        Account Number
                                                      </label>
                                                      <input
                                                        type="text"
                                                        required
                                                        id="email3"
                                                        value={
                                                          userBankDtls.AcntNum
                                                        }
                                                        onChange={(e) => {
                                                          e.preventDefault();
                                                          setUserBankDtls({
                                                            ...userBankDtls,
                                                            AcntNum:
                                                              e.target.value,
                                                            radio: "bank",
                                                          });
                                                        }}
                                                        placeholder="Your Account Number"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div class="items">
                                                    <div class="form-input">
                                                      <label
                                                        for="email3"
                                                        class="form-label"
                                                      >
                                                        Nickname
                                                      </label>
                                                      <input
                                                        type="text"
                                                        required
                                                        id="email3"
                                                        value={
                                                          userBankDtls.NickName
                                                        }
                                                        onChange={(e) => {
                                                          e.preventDefault();
                                                          setUserBankDtls({
                                                            ...userBankDtls,
                                                            NickName:
                                                              e.target.value,
                                                          });
                                                        }}
                                                        placeholder="Your Account Nickname"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div class="items">
                                                    <div class="form-input">
                                                      <label
                                                        for="email3"
                                                        class="form-label"
                                                      >
                                                        IFSC Code
                                                      </label>
                                                      <input
                                                        type="text"
                                                        required
                                                        id="email3"
                                                        value={
                                                          userBankDtls.IFSCCode
                                                        }
                                                        onChange={(e) => {
                                                          e.preventDefault();
                                                          setUserBankDtls({
                                                            ...userBankDtls,
                                                            IFSCCode:
                                                              e.target.value,
                                                          });
                                                        }}
                                                        placeholder="Your Bank IFSC Code"
                                                      />
                                                    </div>
                                                  </div>

                                                  <div class="items text-center">
                                                    <button
                                                      type="submit"
                                                      class="cmn--btn cd-popup-close repopup custom-bank"
                                                    >
                                                      <span >
                                                        Add Bank Account
                                                      </span>
                                                    </button>
                                                  </div>
                                                </form>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class={`modal mylogin signup-popup fade ${none}`}
                                        id="exampleModal2"
                                        tabindex="-1"
                                        aria-hidden="true"
                                      >
                                        <div class="modal-dialog modal-dialog-centered">
                                          <div class="modal-content">
                                            <div class="modal-header">
                                              <div class="head">
                                                <h5>Add your UPI account</h5>
                                              </div>
                                            </div>
                                            <button
                                              id="closeModalUpi"
                                              type="button"
                                              class="btn-close cross-btn"
                                              data-bs-dismiss="modal"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                            <div class="modal-body">
                                              <div class="register-from">
                                                <form
                                                  action="#0"
                                                  onSubmit={addBankAcnt}
                                                >
                                                  <div class="items">
                                                    <div class="form-input">
                                                      <label
                                                        for="email3"
                                                        class="form-label"
                                                      >
                                                        Provider Name
                                                      </label>
                                                      <input
                                                        type="text"
                                                        required
                                                        id="email3"
                                                        value={
                                                          userUPIDtls.ProviderName
                                                        }
                                                        onChange={(e) => {
                                                          e.preventDefault();
                                                          setUserUPIDtls({
                                                            ...userUPIDtls,
                                                            ProviderName:
                                                              e.target.value,
                                                            radio: "upi",
                                                          });
                                                        }}
                                                        placeholder="Your UPI Provider Name"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div class="items">
                                                    <div class="form-input">
                                                      <label
                                                        for="email3"
                                                        class="form-label"
                                                      >
                                                        UPI ID
                                                      </label>
                                                      <input
                                                        type="text"
                                                        required
                                                        id="email3"
                                                        value={
                                                          userUPIDtls.AcntNum
                                                        }
                                                        onChange={(e) => {
                                                          e.preventDefault();
                                                          setUserUPIDtls({
                                                            ...userUPIDtls,
                                                            UPIId:
                                                              e.target.value,
                                                          });
                                                        }}
                                                        placeholder="Your UPIID"
                                                      />
                                                    </div>
                                                  </div>

                                                  <div class="items text-center">
                                                    <button
                                                      type="submit"
                                                      class="cmn--btn cd-popup-close repopup"
                                                    >
                                                      <span>
                                                        Add UPI Account
                                                      </span>
                                                    </button>
                                                  </div>
                                                </form>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* Bank accordian Section ends */}
                                    </div>
                                    
                                  </div>
                                </div>
                              </div>
                              
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
       
    </div>
  );
}
